import { call, query } from "./calls";

const status = "case(status,'PENDING', 1, 'CANCELLED', 3, 'APPROVED', 2) as statusOrder"
const orderBy= "order by statusOrder, expiration DESC"

const getCorporateStorage = () => localStorage.getItem('currentSession') && JSON.parse(localStorage.getItem('currentSession'));

export const CorporateHandShake = {
    listRecipient: () => query(`select *,${status} from CorporateHandShake where recipientId=${getCorporateStorage().id} ${orderBy}`),
    listRequester: () => query(`select *, ${status} from CorporateHandShake ${orderBy}`),
    create: (body) => call('/CorporateHandShake', 'POST', body),
    update: (id, body) => call(`/CorporateHandShake/${id}`, 'PUT', body),
    remove: (id) => call(`/CorporateHandShake/${id}`, 'DELETE')
}