import {Api} from "./api";

export const getCorporateVariables = () => {
  const json = localStorage.getItem('corporateVariables');
  try {
    if (json) {
      return JSON.parse(json);
    }
  } catch (jsonErr) {
    console.error('Could not parse corporate variables from local storage');
  }
  return {};
};

export const loadCorporateVariables = () => {
  Api.EnvironmentVariables.get().then(vars => {
    localStorage.setItem('corporateVariables', JSON.stringify(vars.values || {}));
  }).catch(err => console.error('Could not get corporate variables', err));
};
