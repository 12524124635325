import { query, call } from "./calls";

export const DynamicStoreMetadata = {
  create: (obj) => call('/DynamicStoreMetadata', "POST", obj),
  update: (obj) => call(`/DynamicStoreMetadata/${obj.id}`, "PUT", obj),
  get: () => query("SELECT * FROM DynamicStoreMetadata order by resourceName"),
  search: (q) => query(`select resourceName, resourceName as label from DynamicStoreMetadata where resourceName like '${q}'`),
  searchByName: (q) => query(`select resourceName, resourceName as label from DynamicStoreMetadata where resourceName = '${q}'`),
  getDynamicStoreMetadata:(name) => query(`select id as artifactId, if(isNotNull(name), name, resourceName) as artifactName from DynamicStoreMetadata where name like '${name}' or resourceName like '${name}' limit 100`),
};
