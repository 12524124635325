import { Api } from '.';
import {call, query} from './calls';

export const Sharing = {
    create: (sharing) => call("/Sharing", "POST", sharing),
    update: (id,sharing) => call(`/Sharing/${id}`, "PUT", sharing),
    updateByQuery: (sharing) => call(`/Sharing`, "PUT", sharing),
    deleteByQuery: (sharing) => call(`/Sharing`, "DELETE", sharing),
    getByResourceIdJoinAccount: (resourceId) => query(`select ShareAccounts.name as name, Sharing.grants as grants, Sharing.shareId as shareId, Sharing.shareKind as shareKind from Sharing JOIN ShareAccounts ON Sharing.shareId = ShareAccounts.id WHERE shareKind='ACCOUNT' AND Sharing.resourceId=${resourceId}`)
};
export const ShareAccounts = {
    list:() => query("SELECT id as value, name as label,toString('ACCOUNT') as kind FROM ShareAccounts ORDER BY name"),
}
export const ResourceSharingGrants = {
    get: (id) => call(`/ResourceSharingGrants/${id}`),
};

export const getGrants = (resourceId) => {
    return Api.ResourceSharingGrants.get(resourceId)
      .then(res => {
          let updateGrant = false;
          if(res.grants.filter((grant) => grant.name === "UPDATE" ).length > 0) {
            updateGrant = true;
          }
          let deleteGrant = false;
          if(res.grants.filter((grant) => grant.name === "DELETE" ).length > 0) {
            deleteGrant = true;
          }
          let shareGrant = false;
          if(res.grants.filter((grant) => grant.name === "SHARE" ).length > 0) {
            shareGrant = true;
          }
          let readGrant = false;
          if(res.grants.filter((grant) => grant.name === "READ" ).length > 0) {
            readGrant = true;
          }
          let executionGrant = false;
          if(res.grants.filter((grant) => grant.name === "EXECUTE" ).length > 0) {
            executionGrant = true;
          }

          return {
            update: updateGrant,
            delete: deleteGrant,
            share: shareGrant,
            read: readGrant,
            execute: executionGrant,
          };
        });
  };
