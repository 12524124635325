import { PermissionManager } from "./permissions";
import { call, query } from "./calls";

const select = "Select owner, parent, resourceIdLinked, name, icon, resourceNameLinked, description, optionalParams, id, type, linkId, " +
"completeName, File.publicAccess as publicAccess, File.url as url, File.fileId as fileId from Link";

export const Link = {
    get: (id) => call(`/Link/${id}`, "GET"),
    getByResourceLinkedId: (resourceLinkedId) => query(`select * from Link where resourceIdLinked = ${resourceLinkedId}`),
    listByFolderId: (folderId) => query(`${select} where Link.id in (select links from (select (select children.Link as links from Folder where id = ${folderId}) as l1, get(l1, 0) as l2, get(l2, 'links') as links from '{}' as data) as data) and resourceNameLinked in ('${PermissionManager.types['menu_apps_bpm'] ? 'ProcessLayout':''}','${PermissionManager.types['menu_apps_dashboard'] ? 'Dashboard':''}','File')`),
    listByFolderIdWithFilter: (filter, folderId) => query(`${select} where Link.id in (select links from (select (select children.Link as links from Folder where id = ${folderId}) as l1, get(l1, 0) as l2, get(l2, 'links') as links from '{}' as data) as data) and resourceNameLinked = '${filter}' and resourceNameLinked in ('${PermissionManager.types['menu_apps_bpm'] ? 'ProcessLayout':''}','${PermissionManager.types['menu_apps_dashboard'] ? 'Dashboard':''}','File')`),
    list: (list) => query(`${select} where Link.id in (${list.toString()}) and resourceNameLinked in ('${PermissionManager.types['menu_apps_bpm'] ? 'ProcessLayout':''}','${PermissionManager.types['menu_apps_dashboard'] ? 'Dashboard':''}','File')`),
    listWithFilter: (filter, list) => query(`${select} where Link.id in (${list}) and resourceNameLinked = '${filter}' and resourceNameLinked in ('${PermissionManager.types['menu_apps_bpm'] ? 'ProcessLayout':''}','${PermissionManager.types['menu_apps_dashboard'] ? 'Dashboard':''}','File')`),
    delete: (id) => call(`/Link/${id}`, "DELETE"),
    update: (link) => call(`/Link/${link.id}`,'PUT',link),
    create: (link) => call('/Link',"POST", link),
    getShared: () => call(`/SharedLinks`),
    getSharedWithFilter:(filter) => query(`select * from SharedLinks where resourceNameLinked = '${filter}'`),
    updateName: (id, resourceName, name) => call(`/${resourceName}/${id}`, "PUT", name),
    getLinks:() => query(`select id as artifactId, name as artifactName from Link where type = 'shortcut' limit 100`),
};
