import { call, query } from "./calls";

export const Form = {
    create: (form) => call('/Form',"POST",form),
    remove: (id) => call (`/Form/${id}`, "DELETE"),
    get: (id) => call(`/Form/${id}`),
    getId: (taskId) => query(`select id from Form where taskId = '${taskId}'`),
    search: (q) => query(`select id, name, triggerId from Form where isNotNull(name) and name != '' and name like '${q}' order by name`),// TODO: prepared query [q]),
    list: () => query("select id, name, triggerId from Form where isNotNull(name) and name != '' order by name"),
    getFormFlow: (triggerId) => query(`select * from process.execution where triggerId = ${triggerId}`).then(process => {
      if (!process || !process[0]) return;
      let flow = process[0]._processTracking.flows[0];
      flow.status = process[0]._processTracking.status;
      let cache = process[0]._processTracking.processModel.cache;
      flow.steps.forEach( (step)  => step.task = cache[step.elementId].description);
      return flow;
    }),
    getModalSize: (id) => query(`select modalSize from Form where id = ${id}`)
}
