import React, {useEffect, useState} from "react";
import {Api} from "../../api";
import {resolveValue} from "../../forms/conditions";
import UIRenderer from "./UIRenderer";

/**
 *
 * @param data an array of objects
 * @param row true to render the items horizontally instead of vertically
 * @param resolvers an array of objects with { condition: string, form: [form object], html: string }
 * @param resolverFunction a function like (data) => html string
 * @param maxSize size in pixels, width for row and height for column
 * @constructor
 *
 * Iterates through data.
 * If a resolverFunction is specified, it is called with each item to obtain an html string.
 * If no resolverFunction is specified, iterates through resolvers for the first that returns a successful condition.
 * Then uses the html string if specified, or the form object to obtain its html from the backend.
 * Resolver conditions should be written in script format with variables in braces like {var}.
 * An empty condition or an asterisk is taken as the default case and should be last in the array.
 */
const ArrayUIRenderer = ({ data, row = false, resolvers, resolverFunction, maxSize }) => {
  const [htmlStringsCache, setHtmlStringsCache] = useState({});

  const fetchHtml = (formId) => {
    Api.Form.get(formId).then(form => {
      if (form && form.html) {
        setHtmlStringsCache(cache => ({ ...cache, [formId]: form.html }));
      }
    });
  }

  useEffect(() => {
    if (!resolvers) return;

    resolvers.forEach(r => {
      if (!r.html && r.form && r.form.id) {
        fetchHtml(r.form.id);
      }
    })
  }, [resolvers]);

  const resolveHtml = item => {
    let html;
    if (resolverFunction) html = resolverFunction(item);
    else if (resolvers) {
      for (let i = 0; i < resolvers.length; i++) {
        const r = resolvers[i];
        if (r.condition !== undefined && (r.condition.trim().length === 0 || r.condition.trim() === '*' || resolveValue(item, r.condition))) {
          html = r.html || (r.form && htmlStringsCache[r.form.id]);
          break;
        }
      }
    }
    return html;
  }

  const style = { overflowY: 'scroll' };
  const size = maxSize ? `${maxSize}px` : '100%';
  if (row) style.maxWidth = size;
  else style.maxHeight = size;

  return (
    <div className={`array-ui-renderer d-flex justify-content-start ${row ? 'flex-row' : 'flex-column'}`} style={style}>
      {data && data.map((d, index) => {
        const html = resolveHtml(d);
        if (html) {
          return <UIRenderer key={index} model={d} html={html} />;
        }
        return null;
      })}
    </div>
  );
};

export default ArrayUIRenderer;
