import {t} from '@lingui/macro';

const arrayUiRenderer = editor => {
  editor.BlockManager.add('array-ui-renderer', {
    label: `<div class="gjs-block-label">${t`HTML List`}</div>`,
    attributes: { class: 'fas fa-list' },
    category: 'Dynamic Form',
    content: `<div data-gjs-type="array-ui-renderer" data-custom-component="array-ui-renderer"></div>`
  });

  const comps = editor.DomComponents;
  comps.addType('array-ui-renderer', {
    model: {
      defaults: {
        traits: [
          { name: 'data-model-variable', label: t`Model variable` },
          { name: 'data-max-size', label: t`Max size (px)`, type: 'number' },
          { name: 'data-row', label: t`Render as row`, type: 'checkbox' }
        ]
      },
      init() {
        this.on('change:attributes:data-max-size', this.handleKeyChange);
        this.on('change:attributes:data-row', this.handleKeyChange);
      },
      handleKeyChange() {
        const view = this.getView();
        view && view.render();
      }
    },
    view: {
      onRender: function({ el, model }) {
        const maxSize = model.getAttributes()['data-max-size'];
        const row = model.getAttributes()['data-row'];

        el.className = `d-flex pb-5 ${row ? 'flex-row' : 'flex-column'}`;
        if (row) el.style.width = (maxSize && maxSize + 'px') || '100%';
        else el.style.height = (maxSize || '200') + 'px';
      }
    }
  });

  editor.on('component:add', model => {
    const parent = model.parent();
    if (parent.get('type') === 'array-ui-renderer') {
      parent.view && parent.view.render();
    }
  });
  editor.on('component:remove', model => {
    const parent = model.parent();
    if (parent && parent.get('type') === 'array-ui-renderer' && parent.get('components').length === 0) {
      parent.view && parent.view.render();
    }
  })
};

export default arrayUiRenderer;
