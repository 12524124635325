import React, {memo, useCallback, useEffect, useState} from 'react'
import {DndProvider} from "react-dnd";
import {HTML5Backend, NativeTypes} from "react-dnd-html5-backend";
import { Trans } from '@lingui/macro';
import {TargetBox} from "../commun";
import {FileList} from "../dragFile/FileList";
import '../commun/DragFile.css';
import './style.css';
const { FILE } = NativeTypes;

const FileChooser = ({displayFileList,onChange,acceptFileType=[FILE], maxFileSize: maxFileSizeProp,disabled,small,alertFileRequired}) => {
 
  const [droppedFiles, setDroppedFiles] = useState([]);
  const [limitSizeExceeded,setLimitSizeExceeded] = useState(false);

  let maxFileSize = 50;
  const session = localStorage.getItem('currentSession') && JSON.parse(localStorage.getItem('currentSession'))

  if(session && session.maxFileSize)
    maxFileSize = session.maxFileSize;

  if (maxFileSizeProp && maxFileSizeProp < maxFileSize) {
    maxFileSize = maxFileSizeProp;
  }

  useEffect(()=>{
    if(droppedFiles.length > 0){
      if(droppedFiles[0].size > (maxFileSize * 1024 * 1024)){
        setLimitSizeExceeded(true)
      }else{
        onChange(droppedFiles)
        setLimitSizeExceeded(false)
      }
    }
  },[droppedFiles])

  const handleFileDrop = useCallback((item, monitor) => {
    if (monitor) {
      setDroppedFiles(monitor.getItem().files);
    }
  },[]);

  const onManualSelectHandler = useCallback((files)=>{setDroppedFiles(files)},[])

  return   <DndProvider backend={HTML5Backend}>
      <TargetBox accepts={acceptFileType}
                 onDrop={handleFileDrop}
                 onManualSelect={onManualSelectHandler}
                 disabled={disabled}
                 small={small}
      />
    {
      displayFileList &&  <FileList files={droppedFiles}/>
    }
    {
      limitSizeExceeded && <div className="alert alert-danger mt-3" role="alert">
        <Trans>The file has exceeded the size limit:{maxFileSize} MB</Trans>
        
      </div>
    }
    {alertFileRequired && 
      <div className="alert alert-danger mt-3" role="alert">
        <Trans>The file is required</Trans>       
      </div>
    }
  </DndProvider>
}
export default memo(FileChooser)