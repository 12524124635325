import {getAuthHeaders} from "./calls";
import {BASE_URL} from "./config";
import {saveAs} from "file-saver";
import { t } from "@lingui/macro";

const download = (fileResourceId, fileName) => {
  const path = `${fileResourceId}`;
  const url = `${BASE_URL}/storage/${path}`;
  fetch(url, {
    method: "GET",
    headers: getAuthHeaders()
  }).then(async res => ({
    contentType: res.headers.get('content-type'),
    blob: await res.blob()
  })).then(resObj => {
    const newBlob = new Blob([resObj.blob], { type: resObj.contentType });
    saveAs(newBlob, fileName);
  })
  .catch(err => {
    alert(t`Error downloading file` + ': ' + err.message);
  });
}

const update = (id, action) => {
  let auth = getAuthHeaders();
  let formData = new FormData();
  formData.append("action", action);
  return fetch(`${BASE_URL}/storage/${id}`, {
    method: 'PUT',
    body: formData, headers: auth
  });
}

export const storage = {
  get: (fileResourceId, fileName) => download(fileResourceId, fileName),
  makePublic: (id) => update(id, "makePublic"),
  makePrivate: (id) => update(id, "makePrivate")
}
