export const externalFormats = {
  date: "dd/MM/yyyy",
  datetime: "dd/MM/yyyy HH:mm",
  time: "HH:mm"
}

export const internalFormats = {
  date: "YYYY-MM-DD",
  datetime: "YYYY-MM-DD HH:mm:ss",
  time: 'HH:mm'
}