import { query } from "../calls";

export const MilestoneLog = {
    getById: (milestoneId) => query(`select *, get(get(it, 0), 'title') as issueTitle from (select operation, date, issueId, to, accountId, AccountList.name as accountName,
    (select title from Issue where id = $issueId) as it,
    size(it) as lengthIt,
    (select id, title from Milestone where id in $to) as milestone from MilestoneLog
    left join AccountList on MilestoneLog.accountId = AccountList.id
    where milestoneId=${milestoneId}
    ORDER BY date DESC) as data where lengthIt > 0`)
}
