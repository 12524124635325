import React, {memo} from "react";
import Checkbox from "react-custom-checkbox";
import {Row,Col,Button} from 'reactstrap';

const ItemResource = ({layerTitle,selected,active,color,onSelectItem,onSelectResourceToAddFeature,onSelectDisplayZones}) =>{
  return <div className={"list-group-item list-group-item-action p-0 pr-1" + (active === true?'active':'')}>
    <Row>
      <Col className="d-flex align-items-center" sm={2}>
        <Button size='sm' color='link' onClick={onSelectDisplayZones}>
          <i className="fas fa-angle-right"/>
        </Button>
      </Col>
      <Col className="d-flex align-items-center" sm={8}>
        <h5 className="mb-1 text-truncate">{layerTitle}</h5>
      </Col>
      <Col className='p-0' sm={2} >
        <Button size='sm' color='link' onClick={onSelectResourceToAddFeature}>
          {active?<i className="fas fa-minus" style={{color: `#${color}`}}/>:<i className="fas fa-plus" style={{color: `#${color}`}}/>}
        </Button>
        <div className='ml-1'>
          <Checkbox
            checked={selected}
            borderColor={color}
            icon={<div style={{ backgroundColor: color, borderRadius: 5, padding: 5 }} />}
            style={{ overflow: "hidden" }}
            size={20}
            onChange={onSelectItem}
          />
        </div>
      </Col>
    </Row>
  </div>
}
export default memo(ItemResource)
