import React from 'react'
import { ErrorMessage } from 'formik';

export const ErrorMessageCustom = ({ name, touched }) => {
  if (touched && touched[name]) {
    return <ErrorMessage key={name} className="form-group text-danger" component="div" name={name} />
  } else {
    return null
  }
}
