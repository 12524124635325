import React from "react";
import StylishAsyncCreatable from "../stylish-select/StylishAsyncCreatable";

const PortaledAsyncCreatable = (props) => {
  const newStyles = {...props.styles, menuPortal: base => ({ ...base, zIndex: 9999 }) }
  return (<StylishAsyncCreatable
    {...props}
    menuPortalTarget={document.body}
    styles={{...newStyles}}
  />)
}
export default PortaledAsyncCreatable
