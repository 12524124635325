import { AUTH, BASE_URL, PROCESS_ID } from "./config";
import queryString from "query-string";
import CryptoJS from 'crypto-js';

const getSession = () => localStorage.getItem('token');

export const getAuthHeaders = () => {
  let session = getSession();
  return ({ Authorization: `Bearer ${session != null ? session : ""}` });
};

export const call = (path: string, method: string = "GET", body: any = undefined,
   query: ({ [p: string]: unknown } | undefined) = undefined,
   raw: boolean = false, context: string = "/edna", contentType: string = "application/json") => {
   let url = `${BASE_URL}${context}${path}`;
   if (query) url += `?${queryString.stringify(query)}`;

   let auth: any = getAuthHeaders();
   if (contentType) {
     auth = {...auth, 'Content-Type': contentType};
   }

   let encryptRequest = contentType === 'application/stk-query';
   return fetch(url, {
      method,
      body: (encryptRequest && body) ? getEncrypted(JSON.stringify(body)) : (body ? (body instanceof FormData ? body : JSON.stringify(body)) : undefined),
      headers: auth
   }).then(res => {
      if (!res.ok) {
         if (res.status == 401) { //Unauthorized
            window.location.replace(
               `/auth${window.location.pathname}`
            );
         } else {
            return res.json().then(json => {
               throw new Error(extractError(json));
            });
         }
      } else {
         if (raw) {
            const headers = {} as any;
            // @ts-ignore
            for (let h of res.headers.keys()) {
               headers[h] = res.headers.get(h);
            }
            return res.json().then(body => Promise.resolve({
               headers, body
            }));
         } else if (res.status === 204) {
            return res;
         } else {
            return res.json();
         }
      }
   });
};

export const preparedQuery = (query: string, params: [any]) => call('', "POST", {
   _query: {
      value: query,
      params
   }
});

const getEncrypted = (toEncrypt: any) => {
   let token = getSession();
   if (token) {
      let key = CryptoJS.enc.Latin1.parse(token.substr(0, 32));
      let iv = CryptoJS.enc.Latin1.parse(token.substr(0, 16));
      let encrypted = CryptoJS.AES.encrypt(
         toEncrypt.toString(),
         key,
         {
            iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7
         }
      );
      return encrypted.toString();
   }
}

export const query = (query: string, raw: boolean = false, encrypt: boolean = false) => {
   if (encrypt && getSession()) {
      let queryEncrypted = getEncrypted(query);
      return call('', "GET", undefined, { "q": queryEncrypted }, raw, '/edna', 'application/stk-query');
   }
   return call('', "GET", undefined, { "q": query }, raw);
};

export const queryPost = (request: any, raw: boolean = false) => {
  return call('', 'POST', request, undefined, raw);
}

export const extractError = (json: any) => {
   if (json.tags) {
      let messages = "";
      for (var tag in json.tags) {
         messages += json.tags[tag] + "\n";
      }
      return messages;
   } else {
      return JSON.stringify(json);
   }
};
