import { call, query } from "./calls";

export const Crud = {
    get: (id) => call(`/Crud/${id}`),
    put: (crud) => call(`/Crud/${crud.id}`, "PUT", crud),
    search: (q) => query(`select id, name, visibleName, resourceName, slug from Crud where isNotNull(name) and name != '' and name like '${q}' order by name`),
    getBySlug: (slug) => query(`select * from Crud where slug = '${slug}' limit ,1`),
    getByResourceName: (resName) => query(`select * from Crud where resourceName = '${resName}' limit ,1`),
    list: () => query("select id, name, visibleName, resourceName, slug from Crud where isNotNull(name) and name != '' order by name"),
    remove: (id) => call(`/Crud/${id}`, "DELETE")
};
