import React, {useCallback} from "react";
import ArrayEditor from "../../components/array-editor/ArrayEditor";

const ArrayEditorWrapper = ({ values, setFieldValue, name, maxHeight, required, disabled, valueType }) => {
  const onChange = useCallback(value => setFieldValue(name, value), [setFieldValue, name]);
  const value = values[name];

  return (
    <ArrayEditor value={value} onChange={onChange} maxHeight={maxHeight}
                 required={required} disabled={disabled} valueType={valueType} />
  );
};

export default ArrayEditorWrapper;
