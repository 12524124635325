import VoidElements from "../parser/dom/elements/VoidElements";
import {buildField} from "./inputHelpers";
import {zoneResourceExtractor} from "./zoneEditor";
import { mapLayerExtractor } from "./mapView";

const inputMetadata = (node, { updateFields }) => {
  const { attribs } = node;
  if (attribs.name) {
    let field = buildField(node)
    if(attribs['data-oncheck-event']) field.onCheck = attribs['data-oncheck-event'];
    updateFields(field);
  }
};

const customMetadata = (node, { updateFields }) => {
  const field = buildField(node, true);
  field.name = node.attribs['data-name'];
  updateFields(field);
};

const selectMetadata = (node, { updateFields }) => {
  const { attribs } = node;
  if (attribs.name) {
    const field = buildField(node);
    if(attribs['no-default-value'] === undefined){
      const options = node.children.filter((n) => n.type === "tag" && n.name === "option" && n.attribs.value !== undefined);
        if (options.length > 0) {
          const firstOption = options[0];
          field.defaultValue = firstOption.attribs.value;
        }
    }
    updateFields(field);
  }
};

const dataQuery = (node, { updateQueries }) => {
  const escapedQueryText = node.attribs['data-query-text'];
  const doc = new DOMParser().parseFromString(escapedQueryText, "text/html");
  const queryText = doc.documentElement.textContent;
  
  updateQueries({
    name: node.attribs['data-name'],
    queryText: queryText,
    updatesFormValues: node.attribs['data-updates-form-values'] !== undefined,
    condition: node.attribs['data-condition'],
    onEvents: node.attribs['data-on-events']
  });
};

const dataMapping = (node, { updateMappings }) => {
  updateMappings({
    key: node.attribs['data-key'],
    expression: node.attribs['data-expression'],
    updatesFormValues: node.attribs['data-updates-form-values'] !== undefined,
    individualKeys: node.attribs['data-individual-keys'] !== undefined,
    condition: node.attribs['data-condition'],
    onEvents: node.attribs['data-on-events'],
    propagateEvent: node.attribs['data-propagate-event'] !== undefined,
  });
  return null;
};

const metadataLayers = {
  input: inputMetadata,
  textarea: inputMetadata,
  select: selectMetadata,
  'selection-list': customMetadata,
  'array-editor': customMetadata,
  'object-array-editor': customMetadata,
  'text-editor': customMetadata,
  'data-query': dataQuery,
  'data-mapping': dataMapping,
  'zone-resource': zoneResourceExtractor,
  'map-layer': mapLayerExtractor,
};

const extractMetadata = (nodes, context) => {
  nodes.forEach(n => {
    if (n.type === 'tag') {
      if (VoidElements.indexOf(n.name) === -1 && n.children) {
        extractMetadata(n.children, context);
      }

      // find appropriate layer
      for (const layerName in metadataLayers) {
        const extractor = metadataLayers[layerName];
        if (Array.isArray(extractor)) {
          const extractorObj = extractor.find(l => l.applies(n));
          if (extractorObj) {
            extractorObj.extract(n, context);
            return;
          }
        } else if (layerName === n.name) {
          extractor(n, context);
          return;
        }
      }

      const { attribs } = n;
      const customComponent = attribs['data-custom-component'];
      if (customComponent) {
        const customExtractor = metadataLayers[customComponent];
        if (customExtractor) {
          console.log('extracting for custom', customComponent);
          customExtractor(n, context);
        }
      }
    }
  });
};

export default extractMetadata;
