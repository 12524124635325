import React from 'react'
import {DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from "reactstrap";
import {useHistory} from "react-router";
import "./DropdownMobile.css";
import { Trans } from '@lingui/macro';

const DropdownMobile = () => {
  let history = useHistory();

  const optionList = [
    { title: "Query Runner", link: "/query", icon: "fas fa-tag" },
    { title: "Issue Tracker", link: "/issueTracker", icon: "fas fa-list-alt" },
  ];

  return (
    <UncontrolledDropdown className="dropdown-mobile">
      <DropdownToggle tag="a" className="">
        <i className="fa fa-bars"></i>
      </DropdownToggle>
      <DropdownMenu right className="dropdown-menu">
        <DropdownItem onClick={() => {history.push(`/admin`)}} className={"menu"}>
          <i className="fas fa-users"></i>
          <span className=""><Trans>Administration</Trans></span>
        </DropdownItem>
        <DropdownItem divider/>
        {optionList.map((value, index) =>(
          <DropdownItem key={"dropdown-mobile-" + index} onClick={() => {history.push(value.link)}} className={"menu"}>
            <i className={value.icon}></i>

            <span className="">{value.title}</span>
          </DropdownItem>
        ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  )
};

export default DropdownMobile;
