import React, {useCallback, useEffect, useReducer, useState} from 'react';
import {Button, Container, Modal, ModalBody, ModalFooter, Input, Alert} from "reactstrap";
import ModalHeader from "reactstrap/lib/ModalHeader";
import { t, Trans } from '@lingui/macro';
import {Api} from "../../api";
import PortaledCreatable from "../../components/PortaledCreatable/PortaledCreatable";

const ElementState = {
  NEW: 'new',
  UNCHANGED: 'unchanged',
  UPDATED: 'updated',
  DELETED: 'deleted'
}

const createInitialElementsFromValues = values => {
  const valueElements = [];
  if (values) {
    Object.keys(values).forEach(key => {
      valueElements.push({
        name: key,
        value: values[key],
        state: ElementState.UNCHANGED,
        editing: false
      });
    });
  }
  return valueElements;
};

const elementReducer = (elements, action) => {
  const newElements = [...elements];

  const { object: element, index, initialValue } = (action.payload || {});

  switch (action.type) {
    case 'init':
      return initialValue;
    case 'edit':
      element.editing = true;
      newElements[index] = element;
      break;
    case 'cancel':
      element.editing = false;
      newElements[index] = element;
      break;
    case 'save':
      element.editing = false;
      if (element.state === ElementState.UNCHANGED) {
        element.state = ElementState.UPDATED;
      }
      const oldElem = newElements[index];
      if (oldElem.name)
        element.oldName = oldElem.name;

      newElements[index] = element;
      break;
    case 'delete':
      if (element.state === ElementState.NEW) {
        newElements.splice(index, 1);
      } else {
        element.state = ElementState.DELETED;
        if (element.oldName) {
          element.name = element.oldName; // restore name to delete the correct element
        }
        newElements[index] = element;
      }
      break;
    case 'add':
      newElements.push({
        state: ElementState.NEW,
        editing: true,
        name: '',
        value: ''
      });
      break;
    default:
      return elements;
  }

  return newElements;
};

const buildAction = (type, obj, index) => ({
  type,
  payload: { object: obj, index }
});

const EnvironmentVariablesModal = ({ onDone }) => {
  const [loadingError, setLoadingError] = useState();

  const [elements, updateElements] = useReducer(elementReducer, [], arg => []);

  useEffect(() => {
    setLoadingError(undefined);
    Api.EnvironmentVariables.get()
      .then(result => updateElements({
          type: 'init',
          payload: {
            initialValue: createInitialElementsFromValues(result.values)
          }
      }))
      .catch(err => setLoadingError(err.message));
  }, []);

  const onAdd = useCallback(() => updateElements({ type: 'add' }), []);

  const onSave = useCallback(() => {
    const values = {};
    elements.forEach(e => {
      const { name, value } = e;
      values[name] = e.state === ElementState.DELETED ? null : value;
    });
    setLoadingError(undefined);
    Api.EnvironmentVariables.create(values).then(onDone).catch(err => setLoadingError(err.message));
  }, [elements]);

  return (
    <Modal isOpen={true} size="lg">
      <ModalHeader><Trans>Environment Variables</Trans>
        <div className="close-button" onClick={onDone}>
          <i className="fas fa-times" title={t`Close`}/>
        </div>
      </ModalHeader>
      <div className="modal-divider" />
      <ModalBody>
        <Container>
          <table className="w-100">
            <thead>
            <tr>
              <th style={{ width: '40%' }}>Name</th>
              <th style={{ width: '40%' }}>Value</th>
            </tr>
            </thead>
            <tbody>
            {elements.filter(e => e.state !== ElementState.DELETED).length > 0 && elements.map((e, i) => {
              if (e.state !== ElementState.DELETED) {
                return e.editing
                  ? <VariableEditRow element={e} index={i} dispatch={updateElements} elements={elements}/>
                  : <VariableRow element={e} index={i} dispatch={updateElements}/>;
              } else return null;
            })}
            {elements.filter(e => e.state !== ElementState.DELETED).length === 0 &&
              <tr>
                <td colSpan={2}><Trans>No variables defined</Trans></td>
              </tr>
            }
            <tr>
              <td className="py-3">
                <Button color="light" onClick={onAdd}><i className="fas fa-plus mr-2" /><Trans>Add variable</Trans></Button>
              </td>
            </tr>
            </tbody>
          </table>
          {loadingError && <Alert color="danger">{loadingError}</Alert>}
        </Container>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={onSave}><Trans>Save</Trans></Button>
        <Button color="secondary" onClick={onDone}><Trans>Cancel</Trans></Button>
      </ModalFooter>
    </Modal>
  );
}

const PREDEFINED_OPTIONS = Api.EnvironmentVariables.PREDEFINED_VARIABLES.map(v => ({ label: v, value: v }));

const VariableEditRow = ({ elements, element, index, dispatch }) => {
  const initialName = (element.name && ({ label: element.name, value: element.name })) || undefined;
  const [name, setName] = useState(initialName);
  const [value, setValue] = useState(element.value || '');

  const onNameSelect = useCallback((newValue, { action }) => setName(newValue), []);
  const onValueChange = useCallback(e => setValue(e.target.value), []);

  const save = useCallback(() => {
    if (name) dispatch(buildAction('save', { ...element, name: name.value, value }, index))
  }, [dispatch, element, name, value, index]);
  const cancel = useCallback(() => dispatch(buildAction('cancel', element, index)), [dispatch, element, index]);

  const [predefinedOptions, setPredefinedOptions] = useState(PREDEFINED_OPTIONS);
  // filter options with existing elements
  useEffect(() => {
    setPredefinedOptions(PREDEFINED_OPTIONS.filter(o => !elements.some(el => el.name === o.value)));
  }, [elements]);

  return (
    <tr>
      <td>
        <PortaledCreatable options={predefinedOptions} value={name} onChange={onNameSelect} />
      </td>
      <td><Input type="text" value={value} onChange={onValueChange} /></td>
      <td>
        <Button color="link" onClick={save}><i className="fas fa-check" title={t`Save`}/></Button>
        <Button color="link" onClick={cancel}><i className="fas fa-times" title={t`Cancel`}/></Button>
      </td>
    </tr>
  );
};

const VariableRow = ({ element, index, dispatch }) => {
  const edit = useCallback(() => dispatch(buildAction('edit', element, index)), [dispatch, element, index]);
  const deleteHandler = useCallback(() => {
    if (window.confirm(t`Do you want to delete this variable?`)) {
      dispatch(buildAction('delete', element, index));
    }
  }, [dispatch, element, index]);
  return (
    <tr>
      <td>{element.name}</td>
      <td>{element.value}</td>
      <td>
        <Button color="link" onClick={edit}><i className="fas fa-edit" title={t`Edit`}/></Button>
        <Button color="link" onClick={deleteHandler}><i className="fas fa-trash-alt" title={t`Delete`}/></Button>
      </td>
    </tr>
  );
};

export default EnvironmentVariablesModal;
