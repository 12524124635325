import React, { useEffect, useState } from "react";
import htmlparser2 from "htmlparser2";
import transformNodes from "../../forms/layers/transformNodes";
import { resolveValue } from "../../forms/conditions";
import uiRendererLayers from "../../forms/layers/uiRendererLayers";

const UIRenderer = ({ html, model, onEvent }) => {
  const [nodes, setNodes] = useState([]);
  useEffect(() => {
    if (html) {
      setNodes(htmlparser2.parseDOM(html));
    } else {
      setNodes([]);
    }
  }, [html]);

  const onInternalEvent = (name, data) => {
    onEvent && onEvent(name, data);
  }

  return (
    <div className="dynamic-form ui-renderer">
      {transformNodes(nodes, uiRendererLayers, {
        resolveValue, model, onInternalEvent
      })}
    </div>
  );
};

export default UIRenderer;
