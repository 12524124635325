import { call, query } from "./calls";

export const Project = {
    get: (id) => call(`Project/${id}`),/*  */
    list: (q) => query(`SELECT projectGitlabId AS value, projectName AS label FROM Project WHERE name LIKE '${q}' AND isNotNull(projectGitlabId) ORDER BY name`),
    getBacklogId: () => query("SELECT * FROM Project WHERE projectName='Backlog'"),
    search: (q) => query(`SELECT * FROM Project WHERE projectName like '${q}' ORDER BY projectName`),
    getProjectsById: (projectsId) => query(`SELECT * FROM Project WHERE id IN (${projectsId})`),
    getGitlabProjectById: (gitlabProjectId) => query(`SELECT name, id FROM gitlabProject where id=${gitlabProjectId}`),
    getAllIdName: () => query(`SELECT id as value, projectName as label FROM Project ORDER BY projectName`),
    listIdName:(id) => query(`SELECT id as value, name  FROM projectName WHERE id = '${id}' `),
    create: (projectName , type) => call("/Project", "POST", {'projectName':projectName,  'type': type }),
    getProjectsByGitlabProjectId: (gitlabProjectId) => query(`SELECT * FROM Project WHERE projectGitlabId = '${gitlabProjectId}' `),
};