import React, {useCallback, useEffect, useMemo, useState} from "react";
import PortaledSelect from "../components/PortaledSelect/PortaledSelected";
import PortaledAsyncSelect from "../components/PortaledAsyncSelect/PortaledAsyncSelect";
import PortaledCreatable from "../components/PortaledCreatable/PortaledCreatable";
import PortaledAsyncCreatable from "../components/PortaledAsyncCreatable/PortaledAsyncCreatable";
import { Api } from "../api";
import {createNewOption} from '../admin/accounts/FilterFunctions';

const DynamicSelect = ({ field, form, variableName, valueKey, labelKey, model, query, dependencies, preload, children, 
  required, disabled, creatable, onSelectEvents, onInternalEvent, isValid = true }) => {

  const [options, setOptions] = useState([]);
  const [objectValue, setObjectValue] = useState();

  useEffect(() => {
    if (query && query !== '') {
      return;
    }

    if (model && variableName) {
      const value = variableName.split('.').reduce((a, b) => a[b], model);
      setOptions(value || []);
    } else if (children && children.length > 0) {
      setOptions(children.filter(o => o.type === 'option').map(o => ({
        label: o.props.children,
        value: o.props.value
      })));
    } else {
      setOptions([]);
    }
  }, [model, variableName, query]);

  const loadOptions = useCallback((inputValue, callback) => {
    if (!model) {
      callback([]);
      return;
    }

    const q = query.replace(/{_text}/g, inputValue)
        .replace(/{([\w.]*)}/g, (_m, key) => {
            const value = key.split('.').reduce((a, b) => a[b], model);
            return value !== undefined && value !== null ? value : '';
        });
    Api.query(q).then(res => {
      const opts = res || [];
      setOptions(opts)
      callback(opts);
    });
  }, [model, query]);

  const onChange = useCallback((option, actionMeta) => {
    if (actionMeta.action === "select-option" || actionMeta.action === "create-option") {
      let value = option[valueKey || 'value'];
      form.setFieldValue(field.name, value);
      if(onSelectEvents) onInternalEvent(onSelectEvents, value);
    }
  }, [form, field, valueKey, onInternalEvent, onSelectEvents]);
  
  const calculateDisabled = useCallback(() => {
    if (disabled) return true;
    if (!dependencies || dependencies.trim() === '') return false;
    const unmet = dependencies.split(",").find(d => {
      const value = d.trim().split('.').reduce((a, b) => a[b], form.values);
      return !(value && String(value).trim().length > 0);
    });
    return unmet !== undefined;
  }, [form, dependencies, disabled]);

  // updates objectValue with an option from query result
  useEffect(() => {
    if (!field.value) {
      setObjectValue(null);
    } else if (options) {
      const found = options.find(v => v[valueKey || 'value'] === field.value);
      console.log('updating dynselect value', options, found, field.value, valueKey);
      setObjectValue(found || {[valueKey || 'value']: field.value, [labelKey || 'label']: field.value});
    }
  }, [field.value, options, valueKey, labelKey]);

  const createOption = (label) => ({
    label,
    value: label
  });

  const value = useMemo(() => {
    let selectValue;
    if (!creatable) {
      selectValue = options ? options.find(o => o[valueKey || 'value'] === field.value) : null;
    } else {
      selectValue = createOption(field.value);
    }
    return selectValue;
  }, [field.value, options, valueKey, creatable]);

  const propsSelectAsync = useMemo(() => ({
    getOptionLabel: option => option[labelKey || 'label'],
    loadOptions,
    onChange,
    value: objectValue,
    isDisabled: calculateDisabled(),
    key: JSON.stringify(model),
    defaultOptions: (preload === true || preload === '') || false,
    getOptionValue: option => option[valueKey || 'value']
  }), [labelKey, valueKey, loadOptions, onChange, objectValue, calculateDisabled, model])

  const propsSelect = useMemo(() => ({
    options,
    getOptionValue: option => option[valueKey || 'value'],
    getOptionLabel: option => option[labelKey || 'label'],
    isSearchable: true,
    isClearable: true,
    value,
    onChange,
    isDisabled: calculateDisabled()
  }), [labelKey, onChange, valueKey, value, calculateDisabled])


  const customStyles = useMemo(() => ({
    control: (base, state) => ({
      ...base,
      borderColor: state.isFocused ?
        '#ddd' : isValid ?
        '#ddd' : 'red',
      '&:hover': {
        borderColor: state.isFocused ?
          '#ddd' : isValid ?
          '#ddd' : 'red'
      }
    })
  }), [isValid]);

  return (
    <React.Fragment>
      {!creatable &&
        <>
          {query &&
            <PortaledAsyncSelect name={field.name}
              {...propsSelectAsync}
              styles={customStyles}
            />
          }
          {!query &&
            <PortaledSelect {...field}
              {...propsSelect}
              styles={customStyles}
            />
          }
        </>}

      {creatable &&
        <>
          {query &&
            <PortaledAsyncCreatable name={field.name}
              getNewOptionData={createNewOption}
              {...propsSelectAsync}
              styles={customStyles}
            />
          }
          {!query &&
            <PortaledCreatable {...field}
              getNewOptionData={createNewOption}
              {...propsSelect}
              styles={customStyles}
            />
          }
        </>}
        {!calculateDisabled() && <input {...field} tabIndex={-1} autoComplete="off"
                                                   style={({ opacity: 0, height: 0, display: 'none'})}
                                                   required={required} />}
      </React.Fragment>
  );
};

export default DynamicSelect;
