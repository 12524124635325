import React, {useCallback} from 'react';
import '../../components/list/BaseList.css';
import './FolderList.css';
import ItemDropdown from "../dropdownElements/ItemDropdown";
import {DefaultFoldersData} from "../../folderNavigator/folderUtils";
import {Api} from "../../../../api";
import {i18n} from "@lingui/core";
import { t } from '@lingui/macro';

const FolderList = ({itemList,onAction,menu = true,canCheck = true,selectedId,parentClass}) => {
  const defaultIconByType = {
    'Dashboard':{label:t`Shared`,icon:'fas fa-chart-area'},
    'ProcessLayout':{label:"BPM's",icon:'fas fa-network-wired'},
    'Link':{label:'Menu',icon:'fas fa-user-cog'},
    'File':{label: t`File`,icon: 'fas fa-file'},
    'folder':{label:'Menu',icon:'fas fa-folder-open'},
    'shortcut':{label:"BPM's",icon:'fas fa-link'},
  }
  const getIcon = (type) => {
    return defaultIconByType[type] ? defaultIconByType[type].icon : 'fas fa-folder-open'
  }

  const cleanIcon = (value) => {
    let parts = value.split(/(##[\w-#]*)+/g);
    for (var i = 1; i < parts.length; i += 2) {
      parts[i] = "";
    }
    return parts.join("");
  }

  const onClick = useCallback((item, event) => {
    console.log('element type', item.type);
    if (item.type === 'file') {
      if (window.confirm(t`Do you want to download the file ${item.name}?`))
        Api.storage.get(item.resourceIdLinked, item.name);
    } else {
      onAction(item.id,event,item)
    }
  }, [onAction]);

  return (
    <div className={`${parentClass ? parentClass : ''} base-list`}>
      <div className="base-list-content">
      {
        itemList.map(item => { return (
          <div key={item.id} className={`base-list-item ${selectedId === item.id ? "active" : ""}`} onDoubleClick={()=>onClick(item, 'doubleClick')} onClick={()=>onClick(item,'selected')}>
            { canCheck &&
              <div className="item-select">
                <i className={"far fa-square"}/>
              </div>
            }
            <div className="item-select">
              <i className={item.icon ? `fas fa-${item.icon}` : getIcon(item.resourceNameLinked)}/>
            </div>
            <div className="item-name">{item.readOnly ? (DefaultFoldersData[item.name] ? i18n._(DefaultFoldersData[item.name].label) : item.name) : cleanIcon(item.name)}</div>
            {item.sharingId && <div className="menu" title={t`Shared with me`}><i className="fas fa-share-alt"/></div>}
            {item.readOnly && <div className="menu"><i className="fas fa-lock" title={t`Read only`}></i></div>}
            {item.type && (item.type === "shortcut") &&<div className="menu"><i className="fas fa-link" title={t`Shortcut`}></i></div>}
            {menu &&
              <ItemDropdown onAction={onAction}  item={item}>
                <div className="menu"><i className="fas fa-ellipsis-v"></i></div>
              </ItemDropdown>
            }
          </div>
        )})
      }
      </div>
    </div>
  )
}
export default FolderList;
