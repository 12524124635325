import {call, query} from './calls';

export const Role = {
  get: (id) => call(`/Role/${id}`),
  getByIdIn: (roleIds) => query("SELECT id as value, name as label FROM Role WHERE id IN (".concat(roleIds.join(",")).concat(")  ORDER BY name")),
  list: () => query("SELECT * FROM Role"),
  search: (q) => query(`SELECT * FROM Role WHERE name like '${q}' ORDER BY name`),
  getAllIdName: () => query(`SELECT id as value, name as label, toString('ROLE') as kind FROM Role ORDER BY name`),
  getByResourceIdJoinAccount: (resourceId) => query(`select Role.name as name, Sharing.grants as grants, Sharing.shareId as shareId, Sharing.shareKind as shareKind from Sharing JOIN Role ON Sharing.shareId = Role.id WHERE shareKind='ROLE' AND Sharing.resourceId=${resourceId}`),
  create: (role) => call("/Role", "POST", role),
  update: (role) => call(`/Role/${role.id}`, "PUT", role),
};

export const RoleAssignation = {
  create: (roleAssigment) => call("/RoleAssignation", "POST", roleAssigment),
  update: (roleAssigment) => call(`/RoleAssignation/${roleAssigment.id}`, "PUT", roleAssigment),
};
