import React from "react";
import generatePropsFromAttributes from "../parser/utils/generatePropsFromAttributes";

export const modelValue = (node, index, children, { resolveValue, model }) => {
  const { attribs } = node;
  let variable = attribs['data-model-variable'];
  if (!variable) {
    return null;
  }
  delete attribs['data-model-variable'];
  if (!variable.startsWith('{')) variable = '{' + variable;
  if (variable.indexOf('}') === -1) variable = variable + '}';
  return <div {...generatePropsFromAttributes(attribs)}>{resolveValue(model, variable)}</div>;
};
