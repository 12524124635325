export const transitionEndEventName = () => {
   let element = document.createElement('div');
   let transEndEventNames = {
      WebkitTransition: 'webkitTransitionEnd',
      MozTransition: 'transitionend',
      OTransition: 'oTransitionEnd otransitionend',
      transition: 'transitionend'
   }
   for (let name in transEndEventNames) if (element.style[name] !== undefined) return transEndEventNames[name];
   return false;
}