import React from "react"
import './styles.css'
import { useLocation } from "react-router";

const FloatMenu = ({ top, items, getPath, getIconColorFromLinkElement, onPersistMenu }) => {
  const location = useLocation()
  return items && items.length > 0 ? (
    <div id="floatMenu" className="floatMenu" style={{ top: (top - 5) + 'px' }} onMouseEnter={() => onPersistMenu(true)} onMouseLeave={() => onPersistMenu(false)}>
      <ul className="side-list my-0">
        {
          items.map((link, index) => {
            const linkPath = getPath(link.resourceNameLinked, link)
            const active = linkPath === location.pathname ? "active" : ""
            return (<li className={active} key={index} title={link.name}>
              <a href={linkPath}>
                <div className="mainitem" style={{ position: 'relative' }} title={'link.title'}>
                  <i className={link.icon ? `fas fa-${link.icon}` : ("fas fa-tag")} style={{ color: getIconColorFromLinkElement(link) }} />
                  <span className="item-text">
                    {link.name}
                  </span>
                </div>
              </a></li>)
          })
        }
      </ul>
    </div>
  ) : null
}
export default FloatMenu
