export const getPermissions = () => {
  const storedData = localStorage.getItem('currentSession');
  const session = (storedData && JSON.parse(storedData)) || {};
  return session.permissions;
};

export const hasPermission = perm => {
  const perms = getPermissions();
  return perms && perms.find(p => p === perm);
}

export const isAdmin = () => {
  const storedData = localStorage.getItem('currentSession');
  return Boolean(storedData.admin)
}