const fieldTypes = {
  text: 'string',
  string: 'string',
  email: 'string',
  password: 'string',
  number: 'number',
  'datetime-local': 'string',
  date: 'string',
  time: 'string',
  month: 'string',
  week: 'string',
  tel: 'string',
  url: 'string',
  checkbox: 'boolean',
  select: 'string',
  array: 'array',
  file: 'string'
};

export const buildField = (node, isArray = false) => ({
  name: node.attribs.name,
  type: isArray ? 'array' : (node.attribs.type || 'string'),
  typescriptType: fieldTypes[isArray ? 'array' : (node.attribs.type || 'string')],
  defaultValue: node.attribs['data-default-value']
});

export const extractBooleanExpressionProps = (attribs, { resolveValue, model, errors }) => {
  const props = {};
  if (attribs['data-required-expression']) {
    props.required = resolveValue(model, attribs['data-required-expression']);
    delete attribs['data-required-expression'];
  }
  if (attribs['data-disabled-expression']) {
    props.disabled = resolveValue(model, attribs['data-disabled-expression']);
    delete attribs['data-disabled-expression'];
  }
  if (attribs['data-readonly-expression']) {
    props.readOnly = resolveValue(model, attribs['data-readonly-expression']);
    delete attribs['data-readonly-expression'];
  }
  return props;
};

export const getInvalidClassName = (name, errors, props, touched) => {
  let { className } = props;
  if(touched && touched[name]){
    const error = errors[name];
    if (error) className = `${(className || '')} is-invalid`;
    return className;
  }else{
    return className;
  }
};
