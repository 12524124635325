import React, { useEffect, useState } from 'react'
import { Api } from '../../api';

/**
 * 
 * @param url insert pdf by external url
 * @param fileId insert pdf by fileId
 * @param size view size { width, height }
 * @return {JSX.Element}
 * @constructor
 */
export const PdfViewer = ({url, fileId, size}) => {
  const [data, setData] = useState();

  useEffect(() => {
    if(fileId && fileId !== ""){
      Api.PublicFile.getPublicFile(fileId).then(res => {
        if(res[0].url) setData(res[0].url)
      });
    } else {
      if(url && url !== "" && typeof url === "string") setData(url);
      else setData();
    }
  }, [fileId, url]);

  return (
    <React.Fragment>
      <iframe title='pdf-viewer-component' src={data} {...size} />
    </React.Fragment>
  )
}
