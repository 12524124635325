/**
 * Converts an RGB color value to HSL. Conversion formula
 * adapted from http://en.wikipedia.org/wiki/HSL_color_space.
 * Assumes r, g, and b are contained in the set [0, 255] and
 * returns h, s, and l in the set [0, 1].
 *
 */


export const rgbToHsl = (r: number, g: number, b: number) => {
  r /= 255;
  g /= 255;
  b /= 255;

  var max = Math.max(r, g, b), min = Math.min(r, g, b);
  var h: number = (max + min) / 2;
  var s: number = (max + min) / 2;
  var l: number = (max + min) / 2;

  if (max == min) {
    h = s = 0; // achromatic
  } else {
    var d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

    switch (max) {
      case r: h = (g - b) / d + (g < b ? 6 : 0); break;
      case g: h = (b - r) / d + 2; break;
      case b: h = (r - g) / d + 4; break;
    }

    h /= 6;
  }

  return { h, s, l };
};

/**
 * Converts an HSL color value to RGB. Conversion formula
 * adapted from http://en.wikipedia.org/wiki/HSL_color_space.
 * Assumes h, s, and l are contained in the set [0, 1] and
 * returns r, g, and b in the set [0, 255].
 *
 */
export const hslToRgb = (h: number, s: number, l:number) => {
  var r, g, b;

  if (s == 0) {
    r = g = b = l; // achromatic
  } else {
    const hue2rgb = (p: number, q: number, t: number) => {
      if (t < 0) t += 1;
      if (t > 1) t -= 1;
      if (t < 1/6) return p + (q - p) * 6 * t;
      if (t < 1/2) return q;
      if (t < 2/3) return p + (q - p) * (2/3 - t) * 6;
      return p;
    };

    var q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    var p = 2 * l - q;

    r = hue2rgb(p, q, h + 1/3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1/3);
  }

  return {
    r: Math.round(r * 255),
    g: Math.round(g * 255),
    b: Math.round(b * 255)
  };
};


export const hexToRgb = (hex: string) => {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
};

const componentToHex = (c: number) => {
  var hex = c.toString(16);
  return hex.length === 1 ? "0" + hex : hex;
};

export const rgbToHex = (r: number, g: number, b: number) => {
  return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
};

export const lighterColor = (hex: string, steps = 1): (string | null) => {
    let rgb = hexToRgb(hex);
    if (rgb) {
      let hsl = rgbToHsl(rgb.r, rgb.g, rgb.b);
      let newHsl = {
        h: findLighterHue(hsl.h),
        s: Math.max(0, hsl.s - STEP_SATURATION),
        l: Math.min(1, hsl.l + STEP_LIGHT)
      };
      let newRgb = hslToRgb(newHsl.h, newHsl.s, newHsl.l);
      let lighter = rgbToHex(newRgb.r, newRgb.g, newRgb.b);
      if (steps === 1) {
        return lighter;
      } else {
        return lighterColor(lighter, steps - 1);
      }
    }
    return null;
};

const STEP_HUE = 0.002;
const STEP_SATURATION = 0.08;
const STEP_LIGHT = 0.15;

const findLighterHue = (h: number) => {
  const lightHues = [60 / 360, 180 / 360, 300 / 360];
  let closestIndex = -1;
  let minDiff = 1000;
  let index = 0;
  for (let hue of lightHues) {
    const diff = Math.abs(h - hue);
    if (diff < minDiff) {
      minDiff = diff;
      closestIndex = index;
    }
    index++;
  }
  const diff = lightHues[closestIndex] - h;
  const sign = diff / Math.abs(diff);
  return Math.min(1, h + (sign * STEP_HUE));
};
