import { t } from '@lingui/macro';
import { useEffect } from 'react'
import { Api } from '../../../api';
const path = "/apps";
const target = "_selft";

const RedirectIssue = ({ match, history }) => {
  const { id } = match.params;

  useEffect(() => {
    if (id.length === 10) {
      Api.Issue.getByNanoId(id).then(res => {
        if (res && res[0]) history.push(`/issueTracker/issue/${res[0].id}`);
        else {
          alert(t`Issue not found`);
          window.open(path, target);
        }
      }).catch(err => {
        alert(err)
        window.open(path, target);
      })
    } else {
      history.push(`/issueTracker/issue/${id}`);
    }
  }, [id]);

  return null
}

export default RedirectIssue;