import { Api } from '.';
import { call } from './calls';

const PERMISSION_STORE = 'localData';

export const Permissions = {
   getAll: () => call('/Permissions')
};

export const PermissionManager = {
   types: localStorage.getItem(PERMISSION_STORE) ? JSON.parse(String(localStorage.getItem(PERMISSION_STORE))) :
      {
         public: true,
         menu_home: false,
         menu_apps: true,
         menu_apps_dashboard: false,
         menu_apps_bpm: false,
         menu_apps_form: false,
         menu_apps_crud: false,
         menu_shared: false,
         menu_navigator: false,
         menu_tasks: false,
         menu_processes: false,
         menu_launch: false,
         menu_admin: false,
         menu_admin_accounts: false,
         menu_admin_accounts_edit: false,
         menu_admin_accounts_enable: false,
         menu_admin_accounts_dynamicgrants: false,
         menu_admin_roles: false,
         menu_admin_things: false,
         menu_admin_events: false,
         menu_admin_cruds: false,
         menu_admin_projects: false,
         menu_admin_dynamicgrants: false,
         menu_admin_dynamicstorerule: false,
         menu_query: false,
         menu_issueTracker: false,
         menu_admin_tinyUrl: false,
         menu_admin_issue_lifecycle: false,
         menu_issueTracker_issue: false,
         menu_issueTracker_milestone: false,
         menu_issueTracker_meeting: false,
         menu_issueTracker_product: false,
         menu_issueTracker_team: false,
         menu_admin_branch_office_delete: false,
         menu_admin_branch_office_edit: false,
         menu_admin_branch_office_create: false,
         menu_admin_branch_office: false

      },
   get() {
      Api.Permissions.getAll().then(res => {
         PermissionManager.types = res[0];
         localStorage.setItem(PERMISSION_STORE, JSON.stringify(PermissionManager.types));
      }).catch(e => {
         console.log(e);
      })
   }
};
