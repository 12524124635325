import React, {useCallback, useEffect, useState} from "react";
import { t } from '@lingui/macro';
import {Alert, Button, Container, Modal, ModalBody, ModalHeader, Spinner} from "reactstrap";
import FullScreen from "react-full-screen";
import { v4 as uuidv4 } from "uuid";
import {useBasicCrudTableActions, useTableColumns} from "../tables/table-hooks";
import FormRenderer from "../../forms/FormRenderer";
import useFormLoad from "../../forms/use-form-load";
import Table from "../tables/Table";

const ObjectArrayEditor = ({ value: valueProp, title, onChange, height, columns, disabled, formId, generateIds, fileKeyPrefix }) => {
  const [value, setValue] = useState([]);
  useEffect(() => {
    setValue(valueProp && Array.isArray(valueProp) ? valueProp : []);
  }, [valueProp]);

  const [editingItem, setEditingItem] = useState();
  const [editingIndex, setEditingIndex] = useState();
  const [cont, setCont] = useState();

  const onAdd = useCallback(() => {
    let length = value.length;
    if(length !== -1) setCont(length);
    else setCont(0);

    setEditingIndex(-1);
    setEditingItem({}); // new item
  }, [value]);
  const onEdit = useCallback((obj, index) => {
    setEditingItem(obj);
    setEditingIndex(index);
    setCont(index);
  }, []);
  const onDelete = useCallback((obj, index) => {
    if (window.confirm(t`Do you want to delete this item?`)) {
      const newArray = [...value];
      newArray.splice(index, 1);
      onChange(newArray);
    }
  }, [value, onChange]);

  const actions = useBasicCrudTableActions(onEdit, onDelete);
  const cols = useTableColumns(columns);

  const { loading, form, error } = useFormLoad(formId);

  const resetEditing = useCallback(() => {
    setEditingIndex(undefined);
    setEditingItem(undefined);
  }, []);
  const onFormSubmit = useCallback((values, formikBag) => {
    const newArray = [...value];
    const newObject = generateIds ? { id: uuidv4(), ...values } : values; // will generate id if editing an object with no id, but will keep existing id if object has one
    if (editingIndex >= 0) { // editing
      newArray[editingIndex] = newObject;
    } else { // creating
      newArray.push(newObject);
    }
    onChange(newArray);
    resetEditing();
  }, [value, onChange, resetEditing, editingIndex, generateIds]);

  const [fullscreen, setFullscreen] = useState(false);
  const onFullscreen = useCallback(() => setFullscreen(true), []);
  const onExitFullscreen = useCallback(() => setFullscreen(false), []);

  return (
    <div style={{ height: `${(height && height + 45) || 300}px`, minHeight: '80px' }}>
      <div className="d-flex align-items-center" style={{ height: '30px' }}>
        <span className="flex-grow-1">{title || ''}</span>
        {editingItem && loading && <Spinner size="sm"/>}
        <Button color="primary" className="ml-2" disabled={disabled} onClick={onAdd}>
          <i className="fas fa-plus ml-2" style={({ display: "flex", alignItems: "center" })} /></Button>
      </div>
      {value.length > 0 &&
      <div style={{ height: `${(height && height - 30) || 240}px`, minHeight: '50px' }}>
        <Table data={value} columns={cols} actions={actions} actionsDisabled={disabled} />
      </div>}
      {form &&
      <Modal isOpen={editingItem !== undefined} size={form.modalSize || "md"}
             className={`form-loader ${form.modalSize === 'xxl' ? 'modal-xxl' : ''}`}>
        <ModalHeader>
          <div style={{ minHeight: '40px' }}>
            {!fullscreen &&
            <div className="fullscreen-button"  onClick={onFullscreen}>
              <i className="fas fa-expand" title={t`Fullscreen`}/>
            </div>
            }
            <div className="close-button" onClick={resetEditing}>
              <i className="fas fa-times" title={t`Close`}/>
            </div>
          </div>
        </ModalHeader>
        <ModalBody className="pt-0">
          <FullScreen enabled={fullscreen} onChange={setFullscreen}>
            <Container fluid>
              {fullscreen &&
              <h5 className="modal-title mb-3 mt-3 border-primary border-bottom" style={{ color: 'var(--mainColor)' }}>
                <div className="fullscreen-button" style={{ float: 'right' }} onClick={onExitFullscreen}>
                  <i className="fas fa-compress" title={t`Restore`}/>
                </div>
              </h5>
              }
              <FormRenderer form={form} model={editingItem} submitDataObjects={false} onSubmit={onFormSubmit} 
                            preventFileSaving={true} 
                            fileKeyPrefix={`${fileKeyPrefix}-${cont}`}
              />
            </Container>
          </FullScreen>
        </ModalBody>
      </Modal>
      }
      {!form && error && <Alert color="danger">{error}</Alert>}
    </div>
  );
};

export default ObjectArrayEditor;
