import React, {Component, Suspense} from 'react';
import './App.css';
import './newLayaut.css';
import MainMenu from './layout/MainMenu';
import logo from "./img/sitrack.svg";
import logoIcon from "./img/sitrack-icon.svg";
import routes from "./routes";
import {Route} from "react-router-dom";
import './styles/table.scss';
import './styles/modal.scss';
import './styles/card.scss';
import './styles/tabs.scss';
import "react-tabulator/lib/styles.css";
import "react-tabulator/lib/css/tabulator.min.css";
import './layout/styles/Breadcrumbs.css';
import './layout/styles/BaseHeader.css';
import './layout/styles/RightBar.css';
import themes from "./styles/themes";
import {Api} from "./api";
import {PermissionManager} from './api/permissions';
import TopBar from "./layout/newLayout/TopBar";
import BottomNavigation from "./layout/newLayout/BottomNavigation";
import UserMenu from "./layout/newLayout/UserMenu";
import {BarLoader} from "react-spinners";
import HelpCenter from "./helpCenter/HelpCenter";
import {Redirect} from "react-router";
import { transitionEndEventName } from './transitionEndEventName';
import { UserPreferencesProvider } from './layout/newLayout/UserPreferencesProvider';
import {I18nProvider} from "@lingui/react";
import {i18n} from "@lingui/core";
import {defaultLocale, dynamicActivate} from "./i18n";
import {getLocale} from "./account/accountUtils";
import { t } from '@lingui/macro';

const CHANGE_THEME_PERMISSION = "changeThemePermission";
const SELECTED_THEME_STORAGE = "selectedTheme";
const SIDEBAR_COLLAPSED = "sidebarCollapsed";
const USER_PREFERENCES = "userPreferences";
class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      windowWidth: 0,
      windowHeight: 0,
      sidebarCollapsed: false,
      uncollapsed: false,
      updateMenu: true,
      actionItems: [],
      hideItems: false
    };
    PermissionManager.get();
  }

  componentDidMount() {
    let selectedTheme = localStorage.getItem(SELECTED_THEME_STORAGE);
    if (!selectedTheme) {
      selectedTheme = "light";
    }
    const userPreferencesStorage = JSON.parse(localStorage.getItem(USER_PREFERENCES)) || {};
    if (userPreferencesStorage.menuStateCacheEnabled) {
      let sidebarCollapsed = JSON.parse(localStorage.getItem(SIDEBAR_COLLAPSED)) || false;
      this.setState({sidebarCollapsed: sidebarCollapsed, menuStateCacheEnabled: true, hideItems: sidebarCollapsed});
    }

    this.switchTheme(selectedTheme);
    Api.UserPreferences.get().then(prefs => {
      let activeTheme = prefs[0].activeTheme ? prefs[0].activeTheme : selectedTheme
      let changeThemePermission = prefs[0].changeThemePermission ? prefs[0].changeThemePermission : false;
      if ((selectedTheme !== activeTheme) || (localStorage.getItem(CHANGE_THEME_PERMISSION) !== changeThemePermission)) {
        localStorage.setItem(SELECTED_THEME_STORAGE, activeTheme);
        localStorage.setItem(CHANGE_THEME_PERMISSION, changeThemePermission);
        this.switchTheme(activeTheme);
      }
    }).catch((e) => {
      console.log("Error al traer las preferencias de usuario", e);
    });
    this.updateDimensions();
    window.addEventListener("resize", () => window.setTimeout(this.updateDimensions.bind(this), 100));

    this.loadLocale();
    this.loadBacklog();
    this.loadPendingNotification();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }

  switchTheme(theme) {
    if (localStorage.getItem(CHANGE_THEME_PERMISSION) === 'false') {
      theme = localStorage.getItem(SELECTED_THEME_STORAGE) ? localStorage.getItem(SELECTED_THEME_STORAGE) : "light";
    }
    let app = document.body;
    app.classList.add("theme-transition");
    app.classList.remove("dark-text");
    app.classList.remove("light-text");
    app.classList.remove("custom");

    themes.forEach(t => {
      if (app.classList.contains(t) && t !== theme) {
        app.classList.remove(t);
      }
    });
    app.classList.add(theme);

    if (theme === "custom") {
      Api.UserPreferences.get().then(prefs => {
        const pref = (prefs && prefs.length > 0 && prefs[0]) || undefined;
        let themeObj = pref && pref.customTheme;
        if (!themeObj) {
          let themeJson = localStorage.getItem("customTheme");
          themeObj = JSON.parse(themeJson);
        }
        if (themeObj) {
          app.style.setProperty("--mainColor", themeObj.mainColor);
          app.style.setProperty("--backgroundColor", "#ECEFF4");
          app.style.setProperty("--accentColor", themeObj.accentColor);
          app.style.setProperty("--lightAccentColor", themeObj.lightAccentColor);
          app.style.setProperty("--menuTextColor", themeObj.menuTextInactiveColor);
          app.style.setProperty("--mainNavActiveTextColor", themeObj.menuTextColor);
          app.style.setProperty("--color1", themeObj.color1);
          app.style.setProperty("--color2", themeObj.color2);
          app.style.setProperty("--color3", themeObj.color3);
          app.style.setProperty("--color4", themeObj.color4);
          app.style.setProperty("--color5", themeObj.color5);
          app.style.setProperty("--color6", themeObj.color6);
          app.style.setProperty("--color7", themeObj.color7);
          app.style.setProperty("--dashboard-color", themeObj.dashboard);
          app.style.setProperty("--dashboard-title-color", themeObj.titleDash);
          app.classList.add(themeObj.lightText ? "light-text" : "dark-text");

          console.log('loading custom theme', themeObj);
          if (themeObj.logoUrl && themeObj.logoUrl.trim().length > 0) {
            this.setState({ logoUrl: themeObj.logoUrl.trim() });
            console.log('setting state', themeObj.logoUrl.trim());
          } else {
            this.setState({ logoUrl: undefined });
          }
          if (themeObj.iconLogoUrl && themeObj.iconLogoUrl.trim().length > 0) {
            this.setState({ iconLogoUrl: themeObj.iconLogoUrl.trim() });
          } else {
            this.setState({ iconLogoUrl: undefined });
          }
        } else {
          this.setState({ logoUrl: undefined,iconLogoUrl:undefined });
        }
      });
    } else {
      app.style.removeProperty("--mainColor");
      app.style.removeProperty("--lightMainColor");
      app.style.removeProperty("--backgroundColor");
      app.style.removeProperty("--accentColor");
      app.style.removeProperty("--lightAccentColor");
      app.style.removeProperty("--menuTextColor");
      app.style.removeProperty("--mainNavActiveTextColor");
      app.style.removeProperty("--navTextColor");
      app.style.removeProperty("--dashboard-color");
      app.style.removeProperty("--dashboard-title-color");
      this.setState({ logoUrl: undefined });
    }

    window.setTimeout(() => app.classList.remove("theme-transition"), 1000);

    if (localStorage.getItem(SELECTED_THEME_STORAGE) && (theme !== localStorage.getItem(SELECTED_THEME_STORAGE))) {
      Api.UserPreferences.changeActiveTheme(theme);
    }
    localStorage.setItem(SELECTED_THEME_STORAGE, theme);
  }

  onSidebarCollapsed() {
    if (this.state.menuStateCacheEnabled) {
      localStorage.setItem(SIDEBAR_COLLAPSED, JSON.stringify(!this.state.sidebarCollapsed));
    }
    if(!this.state.sidebarCollapsed){
      let sideMenu = document.getElementById('menu-collapsed');
      let transitionEnd = transitionEndEventName();
      sideMenu.addEventListener(transitionEnd, (e) => this.setState({hideItems: true}));
    }
    this.setState({sidebarCollapsed: !this.state.sidebarCollapsed, uncollapsed: false, hideItems: false});
  }

  updateDimensions() {
    try {
      let windowWidth = typeof window !== "undefined" ? window.innerWidth : 0;
      let windowHeight = typeof window !== "undefined" ? window.innerHeight : 0;
      this.setState({ windowWidth, windowHeight });
    } catch (e) {
      console.log(e);
    }
  }

  childrenActionHook({actionName,...props}) {
    switch (actionName) {
      case 'updateMenu': {
        this.setState({updateMenu: !this.state.updateMenu});
        break;
      }
      case 'topBar':
        this.setState({actionItems: props.actionItems});
        break;
      default:
    }
  }
  loadPendingNotification(){
    Api.Notification.pending().then(res => res && res.length && res[0]).then(notif => {
      if(notif && !notif.readDate) this.setState({alertNotification: true})
    }).catch(err => console.log(err));
  }
  loadLocale() {
    // noinspection JSIgnoredPromiseFromCall
    dynamicActivate(getLocale() || defaultLocale);
  }

  loadBacklog() {
    Api.Project.getBacklogId().then(res => {
      if(res && res[0]) localStorage.setItem('backlogId', res[0].id);
    }).catch(err => console.log(err));
  }

  render() {
    // @ts-ignore
    const logoUrl = this.state.logoUrl ? this.state.logoUrl : logo;
    const logoIconUrl = this.state.iconLogoUrl ? this.state.iconLogoUrl : ( this.state.logoUrl ? this.state.logoUrl : logoIcon);

    const { windowWidth } = this.state;

    const styles = {
      topBarHeight: 40,
      footerMenuHeight: 50,
      showFooterMenuText: windowWidth > 500,
      showSidebar: windowWidth > 768,
      sidebarWidth: windowWidth < 1100 ? 50 : 250,
      sidebarCollapsed: windowWidth < 1100
    };

    const navigationList = [
      { id: "id2", name: "Apps", title: t`Apps`, link: "/apps", leftDropdown: true },
      { id: "id4", name: "Dashboards", title: "Dashboards", link: "/apps/dashboard", leftDropdown: false },
      { id: "id5", name: "Dashboards", title: "Dashboard", link: "/dashboard/", leftDropdown: false, custom: true },
      { id: "id6", name: "BPM", title: "BPM", link: "/bpm", leftDropdown: false },
      { id: "id7", name: "BPM", title: "BPM", link: "/apps/bpm", leftDropdown: false },
      { id: "id8", name: t`Shared`, title: t`Shared`, link: "/shared", leftDropdown: true },
      { id: "id9", name: t`Folders`, title: t`Folders`, link: "/folders", leftDropdown: true },
      { id: "id10", name: "Tareas", title: t`Tasks`, link: "/tasks", icon: "", iconClass: "", leftDropdown: true },
      { id: "id11", name: "Admin", title: t`Administration`, link: "/admin", icon: "", iconClass: "", leftDropdown: false },
      { id: "id12", name: "Query", title: "Query Runner", link: "/query", icon: "", iconClass: "", leftDropdown: false },
      { id: "id12", name: "Issue Tracker", title: "Issue Tracker", link: "/issueTracker", icon: "", iconClass: "", leftDropdown: false }
    ];

    const override = {
      display: "flex",
      margin: "0 auto",
      borderColor: "red",
      width:"100%",
      height:4,
      position:"absolute",
    }

    return (
      <UserPreferencesProvider>
        <I18nProvider i18n={i18n}>
          <div className="app">
            <div id="portals-notify"></div>
            { styles.showSidebar
              ? (<div id="menu-collapsed" className={this.state.sidebarCollapsed ? `side-menu collapsed ${this.state.hideItems ? "items-collapsed" : ""}` : "side-menu" + (this.state.uncollapsed ? " uncollapsed" : "")}>
                  {
                    <div className="logo-icon">
                      <img src={this.state.uncollapsed ? logoUrl : logoIconUrl} className={logoIconUrl !== logoIcon ? "logo-cover" : ""} alt=""/>
                    </div>
                  }
                  {
                    <div className={"logo" }>
                      <img src={(this.state.sidebarCollapsed ? logoIconUrl : logoUrl)} className={logoUrl !== logo ? "logo-cover" : ""} alt="" />
                    </div>
                  }
                  <MainMenu key={"mainMenu"} alerts={{notification: this.state.alertNotification}} updateMenu={this.state.updateMenu} />
                  <UserMenu switchTheme={this.switchTheme.bind(this)} />
                  <div className="divider full">
                    <hr/>
                  </div>
                  <div className="sidebar-footer" onClick={this.onSidebarCollapsed.bind(this)}><span></span><i className={this.state.sidebarCollapsed ? "fa fa-angle-double-right" : "fa fa-angle-double-left"}></i></div>
                <div className="sidebar-footer-md" onClick={()=> this.setState({uncollapsed: !this.state.uncollapsed, sidebarCollapsed: false})}><span></span><i className={this.state.uncollapsed ? "fa fa-angle-double-left" : "fa fa-angle-double-right"}></i></div>
                </div>)
              : <TopBar navigationList={navigationList} switchTheme={this.switchTheme.bind(this)} actionItems={this.state.actionItems} />
            }
            { !styles.showSidebar && <BottomNavigation/> }


            <Suspense fallback={<div className="top-menu"><BarLoader
              css={override}
              size="100%"
              color={"var(--mainColor)"}
              loading={this.state.loading}
            /></div>}>
            {routes.map((route, index) => {
              return <PrivateRoute key={index} exact={route.exact} path={route.path} component={route.main} permission={route.permission} onAction={this.childrenActionHook.bind(this)}/>;
            })}
            </Suspense>
            <HelpCenter />
          </div>
        </I18nProvider>
      </UserPreferencesProvider>
    );
  }
}

const PrivateRoute  = ({component: Component, ...props}) => {

  return (
      <Route
      key={props.index}
      exact={props.exact}
      path={props.path}
      render={({location, ...routeProps}) => {
        return PermissionManager.types[props.permission ? props.permission : "public"] ? (
          <Component action={(action)=>{props.onAction(action)}} {...routeProps} />
        ) : (
          <Redirect
          to={{
            pathname: "/failPermissions",
            state: { from: location.pathname || "apps" }
          }}
        />)
      }}
      />
  );
}

export default App;
