import {query, call} from './calls';

export const Folder = {
    getHome: () => call(`/Folder`),
    get: (id) => call(`/Folder/${id}`),
    list: (list) => query(`select * from Folder where id in (${list.toString()})`),
    create: (folder) => call('/Folder',"POST", folder),
    delete: (id) => call(`/Folder/${id}`,"DELETE"),
    update: (folder) => call(`/Folder/${folder.id}`,'PUT',folder),
    listByOwner:(accountId) => query(`select * from Folder where owner in (select roles from Account where id = ${accountId}) and name = 'Menu'`)
};
export const FolderManager = {
    syncDashboard: () => call(`/FolderManager/`,'POST', {action:"synchronize"})
};
