import { call, query } from "./calls";

export const DynamicGrant = {
    list: () => query("SELECT id,name,description FROM DynamicGrant"),
    get: (id) =>  query(`SELECT id,name,description FROM DynamicGrant where id=${id}`),
    create: (values) => call("/DynamicGrant", "POST", values),
    update: (id, values) => call(`/DynamicGrant/${id}`, "PUT", values),
    remove: (id) => call(`/DynamicGrant/${id}`, "DELETE"),
    search: (q) => query(`SELECT id,name from DynamicGrant where name like '${q}'`)
};
