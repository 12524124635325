import React, { useCallback, useEffect, useState } from 'react'
import * as L from "leaflet";
// import * as L1 from 'leaflet.markercluster';
import MarkerClusterGroup from "react-leaflet-markercluster";
import 'react-leaflet-markercluster/dist/styles.min.css'
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.min';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
import { Icon } from "leaflet";
import marker from '../../img/markers/svg/marker.svg';
import LeafletMap, { GeoJsonMap } from '../LeafletMap';
import { t } from '@lingui/macro';
import { getLayerDataHash, styles } from '../LeafletMap/mapUtils';
import deepEqual from 'deep-equal';

const GeoEditableMap = ({ geoJson, configMap, editingLayerIndex, onFinished, onCancel }) => {
  const { zoom, cartographies, layerControlPosition, center, zoomPosition, streetView } = configMap;
  const [map, setMap] = useState();
  const [layerColor, setLayerColor] = useState();
  //layerCollection of layers are geometries generated by the map
  const [layerCollection, setLayersCollection] = useState();
  const [currentLayerCollection, setCurrentLayerCollection] = useState();
  const [currentEditingLayerIndex, setCurrentEditingLayerIndex] = useState();
  const init = useCallback(setMap, []);
  // Layers to modify
  const [layersEdit, setLayersEdit] = useState([]);
  const [currentGeoJson, setCurrentGeoJson] = useState();
  const [clusteringData, setClusteringData] = useState([]);
  const [hashClustering, setHashClustering] = useState('');

  const controls = () => {
    map.pm.addControls({
      editControls:true,
      editMode:false,
      dragMode:true,
      cutPolygon:true,
      removalMode:true,
      drawControls:true,
      customControls:true,
      position: "topleft"
    })
  }

  const onSubmit = useCallback(() => {
    let geoJSON = { type: "FeatureCollection", features: [] };
    setLayersEdit(current => {
      if (current.length > 0) {
        for (const key in current) {
          if (current[key].eventType) {
            if (current[key].eventType === 'add') {
              current[key].features.forEach(l => geoJSON.features.push(l));
            } else if (current[key].eventType === 'remove') {
              current.splice(key, 1);
            }
          } else {
            geoJSON.features.push(current[key]);
          }
        }
      }
      return [];
    });
    setLayersCollection();
    setClusteringData([]);
    onFinished(geoJSON);
  }, [onFinished]);

  const createLayerMap = useCallback((data) => {
    const collection = L.geoJson(data, {
      style: styles,
      pointToLayer: (feature, latlng) => {
        if (feature.properties.radius) {
          if (feature.properties.shape === "Circle")
            return new L.Circle(latlng, feature.properties.radius);
          return new L.CircleMarker(latlng, feature.properties.radius);
        } else {
          return new L.Marker(latlng);
        }
      },
    });

    collection.on('pm:edit', ({ layer }) => {
      let newLayer = layer.toGeoJSON();
      setLayersEdit((current) => {
        let findIndex = current.findIndex(l => l.id === newLayer.id);
        if (findIndex !== -1) current[findIndex] = newLayer;
        return current;
      });
    });

    collection.on('pm:cut', ({layer,originalLayer}) => {
      setLayersEdit((current) => {
        let findIndex = current.findIndex(l => l.id === originalLayer.toGeoJSON().id);
        if(findIndex !== -1) current[findIndex] = {...layer.toGeoJSON()};
        return current;
      });
    });

    collection.on('pm:remove', ({layer}) => {
      let newLayer = layer.toGeoJSON();
      setLayersEdit((current) => {
        let findIndex = current.findIndex(l => l.id === newLayer.id);
        newLayer = {...newLayer, eventType: "remove"};
        if (findIndex !== -1) current[findIndex] = newLayer;
        return current;
      });
    })

    if(map) collection.addTo(map);
    return collection;

  }, [map]);

  const resetCollection = useCallback(() => {
    if(map){
      map.eachLayer(layer => {
        if (layer.toGeoJSON)
          layer.remove()
      });
      map.invalidateSize();
      const theCollection = createLayerMap(geoJson);

      setCurrentLayerCollection();
      setClusteringData([]);
      setLayersCollection(theCollection);
      setCurrentGeoJson(geoJson);
    }
  }, [map, geoJson, createLayerMap]);

  const onCancelChanges = useCallback(() => {
    setLayersEdit([]);
    resetCollection();
    onCancel(undefined);
  }, [onCancel, resetCollection]);

  const createCustomControl = useCallback(()=>{
    if(!map.pm.Toolbar.buttons.saveFeature){
      map.pm.Toolbar.createCustomControl(
        {
          name: "saveFeature",
          block: "custom",
          className: "far fa-save",
          title: t`Count layers`,
          toggle:false,
          onClick: onSubmit,
          doToggle: true,
          toggleStatus: false,
        });
    } else{
      map.pm.Toolbar.buttons.saveFeature._button.onClick = onSubmit
    }
    if(!map.pm.Toolbar.buttons.cancelChanges) {
      map.pm.Toolbar.createCustomControl(
        {
          name: "cancelChanges",
          block: "custom",
          className: "fas fa-ban",
          title: t`Cancel changes`,
          toggle:false,
          onClick: onCancelChanges,
          doToggle: true,
          toggleStatus: false,
        });
    }else {
      map.pm.Toolbar.buttons.cancelChanges._button.onClick = onCancelChanges
    }
    map.pm.Toolbar._showHideButtons()
  },[map, onSubmit, onCancelChanges]);

  // Enters edition mode or switches editing layer (resets the collection)
  useEffect(() => {
    if (editingLayerIndex !== undefined) {
      if (editingLayerIndex !== currentEditingLayerIndex) {
        resetCollection();
      }
    } else {
      if (map) {
        map.pm.disableGlobalEditMode();
        map.pm.removeControls();
      }
    }
    setCurrentEditingLayerIndex(editingLayerIndex);
  }, [editingLayerIndex, map, currentEditingLayerIndex, resetCollection]);

  // Updates map geometries with editing layer (observes the collection)
  useEffect(() => {
    if(currentEditingLayerIndex !== undefined && layerCollection && !deepEqual(layerCollection, currentLayerCollection)){
      setCurrentLayerCollection(layerCollection);
      
      let layerEditing = [];
      let collection = layerCollection.pm._layers;
      let layersOn = collection.filter(c => c.toGeoJSON().parentIndex === currentEditingLayerIndex);
      let layersOff = collection.filter(c => c.toGeoJSON().parentIndex !== currentEditingLayerIndex);
  
      // save editable layer color
      if (layersOn.length > 0) {
        let color = (layersOn && layersOn[0].toGeoJSON().properties.color) || undefined;
        setLayerColor(color);
      }
      // enable layer selected for editing
      layersOn.forEach(l => {
        layerEditing.push(l.toGeoJSON());
        l.pm.enable({ allowSelfIntersection: false });
      });
      // disable layer not selected for editing
      layersOff.forEach(l => l.pm.disable());
  
      setLayersEdit(layerEditing);
  
      // add controls and custom controls
      controls();
      createCustomControl();
    }
  }, [createCustomControl, currentEditingLayerIndex, layerCollection, currentLayerCollection]);

  const addNewGeometryToZone = useCallback(({ layer, shape }) => {
    let features;
    let properties = {color: layerColor, fillColor: layerColor};
    switch (shape) {
      case "Circle":
        features = [{
          "type": "Feature",
          "properties": { ...properties,
            "shape": "Circle",
            "radius": layer._mRadius,
            "category": "default"
          },
          "geometry": {
            "type": "Point",
            "coordinates": [layer._latlng.lng, layer._latlng.lat]
          },
        }]; break;
      case "CircleMarker":
        features = [{
          "type": "Feature",
          "properties": {...properties,
            "shape": "CircleMarker",
            "radius": layer._radius,
            "category": "default"
          },
          "geometry": {
            "type": "Point",
            "coordinates": [layer._latlng.lng, layer._latlng.lat]
          },
        }]; break;
      default: 
        features = [{type: "Feature", properties, geometry: layer.toGeoJSON().geometry }]; 
        break;
    }
    layersEdit.push({ layer, features, eventType: 'add' });
  }, [layerColor, layersEdit]);

  useEffect(() => {
    if(map){
      map.invalidateSize();
      map.pm.setGlobalOptions({
        markerStyle: {
          icon: new Icon({
            iconUrl: marker,
            iconSize: [20, 50],
          })
        }
      });
    }
  }, [map]);

  // intercept points
  useEffect(() => {
    const clustering = [];
    if(layerCollection && map){
      const collection = layerCollection.pm._layers;
      const layersPoint = collection.filter(c => c.toGeoJSON().geometry.type === "Point" && !c.toGeoJSON().properties.radius);
      if (layersPoint.length > 0) {
        let layersOn = layersPoint.filter(c => c.toGeoJSON().parentIndex === currentEditingLayerIndex);
        let layersOff = layersPoint.filter(c => c.toGeoJSON().parentIndex !== currentEditingLayerIndex);

        layersOn.forEach(l => {
          // set icon marker in layers editable
          l.setOpacity(1);
          l.setIcon(new Icon({
            iconUrl: marker,
            iconSize: [20, 50],
          }));
        });

        layersOff.forEach(l => {
          // verify enabled cluster
          if (l.toGeoJSON().cluster) {
            l.feature.properties = { ...l.feature.properties, icon: marker, size: [20, 50] };
            clustering.push(l.toGeoJSON());
            l.setOpacity(0);
          } else {
            // set icon layers disabled
            l.setOpacity(1);
            l.setIcon(new Icon({
              iconUrl: marker,
              iconSize: [20, 50],
            }));
          }
        });
      }
    }
    
    setClusteringData(d => {
      if (!deepEqual(d, clustering)) {
        setHashClustering(getLayerDataHash(clustering));
        return clustering;
      }
      return d;
    });
  }, [layerCollection, currentEditingLayerIndex, map]);

  // Create new geometry
  useEffect(()=>{
    if(map){
      let refEvent= map.on('pm:create',addNewGeometryToZone);
      return () => { if(refEvent) map.off('pm:create',addNewGeometryToZone) };
    }
  },[map, addNewGeometryToZone]);

  // Initialization Map
  useEffect(() => {
    if (!deepEqual(geoJson, currentGeoJson)) resetCollection();
  }, [resetCollection, geoJson, currentGeoJson]);

  return (
    <LeafletMap
      initialHandle={init}
      cartographies={cartographies} streetView={streetView}
      center={center} zoom={zoom} zoomPosition={zoomPosition}
      layerControlPosition={layerControlPosition}
    >
      {map && clusteringData.length > 0 &&
        <MarkerClusterGroup maxClusterRadius={10}>
          <GeoJsonMap data={clusteringData} key={hashClustering} hash={hashClustering} />
        </MarkerClusterGroup>
      }
    </LeafletMap>
  );
}

export default GeoEditableMap;
