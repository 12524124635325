import React, { useCallback, useState } from 'react'
import PortaledSelect from "../PortaledSelect/PortaledSelected";
import { Col, Nav, NavItem, NavLink, Row, TabContent } from 'reactstrap'

export const TabPaneCustom = ({ tabOptions, children, tabSize=3 }) => {
  const [activeTab, setActiveTab] = useState(1);
  const [selectedTab, setSelectedTab] = useState();

  const onTabChange = useCallback((option, action) => {
    if (action.action === 'select-option') {
      setActiveTab(option.value); setSelectedTab(option);
    }
  }, []);

  return (
    <Row>
      <Col lg={tabSize} className="d-none d-lg-block">
        <Nav tabs vertical>
          {tabOptions.map(t => (
            <NavItem key={t.value}>
              <NavLink active={activeTab === t.value}
                onClick={() => { setActiveTab(t.value); setSelectedTab(t) }}>
                {t.label}
              </NavLink>
            </NavItem>
          ))}
        </Nav>
      </Col>
      <Col xs={12} className="d-block d-lg-none mb-4">
        <PortaledSelect options={tabOptions} onChange={(e, action) => onTabChange(e, action)} value={selectedTab}
          styles={{
            menu: provided => ({ ...provided, zIndex: 9999 })
          }} />
      </Col>
      <Col xs={12} lg={12-tabSize}>
        <TabContent activeTab={activeTab}>
          {children}
        </TabContent>
      </Col>
    </Row>
  )
}
