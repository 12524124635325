import React, {useEffect, useState} from 'react';
import {Container, FormGroup, Label} from "reactstrap";
import FolderList from "./list/FolderList";
import {Api} from "../../../api";

const MiniNavigator = ({onAction,isShowLinks,message}) => {
  const [currentFolder,setCurrentFolder] = useState({});
  const [selectedItem,setSelectedItem] = useState({});
  const [folderList,setFolderList] = useState([]);
  const [linkList,setLinkList] = useState([]);



  useEffect(()=>{
    Api.Folder.getHome().then(res => {
      if (res[0]) {
        let folder = res[0];
        setCurrentFolder(folder);
        if (folder.children) {
          let folderList = folder.children.Folder ? folder.children.Folder : [];
          if (folderList.length > 0) {
            Api.Folder.list(folderList).then(res => {
              setFolderList(res);
            }).catch(e =>{setFolderList([]);});
          } else {
            setFolderList([]);
          }
          let linkList = folder.children.Link ? folder.children.Link : [];
          if (linkList.length > 0) {
            Api.Link.listByFolderId(folder.id).then(res => {
              setLinkList(res);
            }).catch(e =>{setLinkList([]);});
          } else {
            setLinkList([]);
          }
        }
      }
    });
  },[]);

  const goBack = () => {
    if (currentFolder.parent) {
      Api.Folder.get(currentFolder.parent).then(folder => {
        setCurrentFolder(folder);
        onAction(folder.id,'selected',folder);
        if (folder.children) {
          let folderList = folder.children.Folder ? folder.children.Folder : [];
          if (folderList.length > 0) {
            Api.Folder.list(folderList).then(res => {
              setFolderList(res);
            }).catch(e =>{setFolderList([]);console.log('error folder')});
          } else {
            setFolderList([]);
          }
          let linkList = folder.children.Link ? folder.children.Link : [];
          if (linkList.length > 0) {
            Api.Link.listByFolderId(folder.id).then(res => {
              setLinkList(res);
            }).catch(e =>{setLinkList([]);console.log('error link')});
          } else {
            setLinkList([]);
          }
        } else {
          setLinkList([]);
          setFolderList([]);
        }
      })
    }
  };

  const onItemAction = (id,action,item) => {
    switch(action){
      case 'selected': {
        setSelectedItem(item);
        onAction(id,action,item);
        break;
      }
      case 'doubleClick': {
        if (!item.resourceLinkedId) {
          setCurrentFolder(item);
          getFolderChildren(item);
          getLinkChildren(item);
        }
        onAction(id,"selected",item);
        break;
      }
      default: return null;
    }
  }

  const getFolderChildren = (menuFolder) => {
    if (menuFolder.children) {
      let folderList = menuFolder.children.Folder ? menuFolder.children.Folder : [];
      if (folderList.length > 0) {
        Api.Folder.list(folderList).then(res => {
          setFolderList(res);
        }).catch(e => {
          setFolderList([]);
        });
      } else {
        setFolderList([]);
      }
    } else {
      setFolderList([]);
    }
  }

  const getLinkChildren = (menuFolder) => {
    if (menuFolder.children) {
      let linkList = menuFolder.children.Link ? menuFolder.children.Link : [];
      if (linkList.length > 0) {
        Api.Link.listByFolderId(menuFolder.id).then(res => {
          setLinkList(res);
        }).catch(e => {
          setLinkList([]);
          alert("Error al buscar Links: " + e)
        });
      } else {
        setLinkList([]);
      }
    } else {
      setLinkList([]);
    }
  }

  return (
    <Container>
      <FormGroup>
        { message && <Label xs={12}>`${message}` {selectedItem.name ? selectedItem.name : ""} </Label>}
        <div className="navigator-menu">
          <div className="folder-back-icon" onClick={goBack}><i className="fas fa-long-arrow-alt-left" /></div>
        </div>
        <FolderList itemList={isShowLinks ? [...folderList,...linkList] : [...folderList]} onAction={onItemAction} selectedId={selectedItem.id} menu={false} canCheck={false}/>
      </FormGroup>
    </Container>
  );
}
export default MiniNavigator;
