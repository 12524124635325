import React from "react";
import StylishCreatable from "../stylish-select/StylishCreatable";

const PortaledCreatable = (props) => {
  const newStyles = {...props.styles, menuPortal: base => ({ ...base, zIndex: 9999 }) }
  return (<StylishCreatable
    {...props}
    menuPortalTarget={document.body}
    styles={{...newStyles}}
  />)
}
export default PortaledCreatable
