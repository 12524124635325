import {LineType} from "recharts";
import {Layout} from "react-grid-layout";

export enum WidgetType {
  OldMap = "map",
  Map = "layered-map",
  Table = "table",
  TableDX = "table-dx",
  Metric = "metric",
  LineChart = "line-chart",
  LineChartDX = "line-chart-dx",
  BarChart = "bar-chart",
  BarChartDX = "bar-chart-dx",
  PieChart = "pie-chart",
  DonutChart = "donut-chart",
  AreaChart = "area-chart",
  AreaChartDX = "area-chart-dx",
  TimelineChart = "timeline-chart",
  TimelineChartDX = "timeline-chart-dx",
  FunnelChart = "funnel-chart",
  FunnelChartDX = "funnel-chart-dx",
  GaugeChart = "gauge-chart",
  QRCode = "qrcode",
  Image = "image",
  MapQueryLayer = "mapQueryLayer",
  ButtonSet = "button-set",
  Leaflet = "leaflet",
  Json = "json",
  PivotGrid = "pivot-grid",
  ArrayUi = "array-ui-renderer",
  RangeBarChart = "range-bar-chart",
  PolarCoordinatesChart = "polar-coordinates-chart",
  ScatterChart = "scatter-chart",
  BubbleChart = "bubble-chart",
  Gantt = "gantt",
  PdfViewer = "pdf-viewer",
  Composite = "composite",
  Scheduler = "scheduler",
  Video = "video"
}

export interface DashboardData {
  id?: string;
  name: string;
  widgets: WidgetConfig[];
  filters?: FilterConfig[];
  linkId?:string;
}

export interface WidgetAction {
  type: ("link" | "general" | "contextual");
  name: string;
  form?: { id: string, name: string };
  event?: string;
  linkedDashboardId?: string;
  condition?: string;
  confirmation: ("confirm" | "direct");
  linkTarget?: ("current" | "blankTab" | "blankWindow");
  showResult: ("alert" | "silent");
  actionParams?:string;
  includeActionParameters?:boolean;
  excludeFilters?:boolean;
  displayAsDisabled?:boolean;
  title?:string;
}

export interface WidgetActionMenuItem extends WidgetAction{
  disabled?:boolean;
  displayTitle?:string;
}

export interface WidgetConfig {
  key: string;
  titulo?:string;
  type: WidgetType;
  grid?: Layout;
  query: string;
  title: string;
  pristine?: boolean;
  linkedDashboardId?: string;
  refreshPeriod?: number;
  alertQuery?: string;
  actions?: WidgetAction[];
  refreshTimestamp?: any;
  icon?: string;
  snoozeTime?: number;
  staticData?: boolean;
  evaluateQuery?: boolean;
  tooltipText?: string;
  actionParams?:string;
  titleHidden?:boolean;
  collapseIcons?: boolean;
  classes?:string;
  shouldRefresh?: boolean;
  datasourceEnabled?: boolean;
}

export interface OldMapWidgetConfig extends WidgetConfig {
  latKey?: string;
  lngKey?: string;
  centerLat?: string;
  centerLng?: string;
  geometryQuery?: string;
  pointKey?: string;
  geometryKey?: string;
  actionParams?: string;
  detailValuesToShow?: string;
  typeLayer?:string;
  fillColor?:string;
  strokeColor?:string;
  strokeWidth?:string;
}

export interface Layer {
  key?: string;
  query?: string;
  title?: string;
  layerType?:string;
  visible?:boolean;
  actionParams?:string;
  detailValuesToShow?:string;
  fillColor?: string;
  strokeColor?:string;
  strokeWidth?:string;
  clustering?: boolean;
}

export interface MapWidgetConfig extends WidgetConfig {
  layers: MapLayer[];
  latKey?: string;
  lngKey?: string;
  centerLat?: string;
  centerLng?: string;
  mainLayer: MapLayer;
}

export enum MapLayerType {
  Marker = "marker",
  Geometry = "geometry",
  Route = "route",
  GeoJSON="geoJSON"
}

export interface MapLayer {
  key: string;
  query: string;
  title: string;
  layerType:MapLayerType;
  visible?:boolean;
  actionParams?:string;
  detailValuesToShow?:string;
  fillColor?: string;
  strokeColor?:string;
  strokeWidth?:string;
  clustering?: boolean;
  clusteringRatio?:number;
  updatesBounds: boolean;
  labelOnHover?:boolean;
}

export enum MapType {
  OSM = "osm",
  MAPBOX = "mapbox",
  GOOGLE_ROAD = "roadmap",
  GOOGLE_SATELLITE = "satellite",
  GOOGLE_TERRAIN = "terrain",
  GOOGLE_HYBRID = "hybrid",
  GOOGLE_TRAFFIC = "traffic"
}


export interface MetricWidgetConfig extends WidgetConfig {
  valueKey?: string;
  unit?: string;
  formatRules?: FormatRule[];
  textStyles?: string;
}

export interface TableWidgetAction extends WidgetAction {
  column: string;
  renderAs: "link" | "button" | "custom";
}

export interface TableWidgetConfig extends WidgetConfig {
  columnsDefinition?: string;
  formatRules?: FormatRule[];
  csvDelimiter?: string;
  actionParams?: string;
  customActions?: TableWidgetAction[];
  downloadDisabled?: boolean;
  exportColumns?: string;
  disableHTML?:boolean;
}

export interface ChartWidgetConfig extends WidgetConfig {
  labelKey: string;
}

export interface LineChartWidgetConfig extends ChartWidgetConfig {
  lines: Line[];
}

export interface Line {
  valueKey: string;
  lineType: LineType;
  strokeColor: string;
}

export interface PieChartWidgetConfig extends ChartWidgetConfig {
  valueKey: string;
  fillColors: string;
  fixedColors: any;
  useFixedColors: boolean;
  colorKey: string;
  showLabelAs: "value" | "percentage";
}

export interface GaugeChartWidgetConfig extends ChartWidgetConfig {
  valueKey: string;
  fillColors: string;
  width: number;
  labels: boolean;
  colorsData: {value:number, color:string, name:string}[];
}

export interface AreaChartWidgetConfig extends ChartWidgetConfig {
  areas: Area[];
}

export interface Area {
  valueKey: string;
  areaType: LineType;
  fillColor: string;
  strokeColor: string;
  fillOpacity?: number;
}

export interface BarChartWidgetConfig extends ChartWidgetConfig {
  bars: Bar[];
  domainYRangeMin:number;
  domainYRangeMax:number;
}

export interface Bar {
  valueKey: string;
  fillColor: string;
}

export interface TimelineChartWidgetConfig extends ChartWidgetConfig {
  startKey: string;
  endKey: string;
}

export interface FunnelChartWidgetConfig extends ChartWidgetConfig {
  valueKey: string;
  bars: Bar[];
  label?: {position:string, fill:string, stroke:string};
}

export interface WidgetProps {
  data: any;
  updateCallback?: (key: string, config: WidgetConfig) => void;
  removeCallback?: (key: string) => void;
  editingDashboard?: boolean;
  executing: boolean;
  filterVariables: Map<string, any>;
  globalRefreshEnabled: boolean;
  exportWidgetCallback?: (key: string) => void;
  onQueryChanged: (config: WidgetConfig, queryKey: string, queryText: string, shouldRefresh?: boolean) => void;
}


// Filters

export enum FilterType {
  Text = "text",
  Slider = "slider",
  DateTime = "date-time",
  Date = "date",
  Time = "time",
  TrueFalse = "truefalse",
  Combo = "combo",
  Map = "map"
}

export interface FilterConfig {
  variableName: string;
  label: string;
  type: FilterType;
  defaultValue: string;
  pristine?: boolean;
  dependencies?: string;
  dynamicDefaultValue?: string;
  disableTimeOffset?: boolean;
}

export interface TextFilterConfig extends FilterConfig {
  textType: string;
}

export interface RangeFilterConfig extends FilterConfig {
  min?: number;
  max?: number;
  step?: number;
}

export interface ComboFilterConfig extends FilterConfig {
  query?: string;
  labelCombo?: string;
  valueCombo?: string;
  includeAll?: boolean;
  multiple?: boolean;
}

export interface FilterProps<FC extends FilterConfig = FilterConfig> {
  filter: FC;
  index: number;
  editing: boolean;
  onRemoveFilter: (index: number) => void;
  onEditFilter: (index: number) => void;
  values: any;
  disabled?: boolean;
}


// Rules

export enum RuleType {
  style = "style"
}

export interface FormatRule {
  column: string;
  priority: number;
  condition?: string;
  type: RuleType;
}

export interface StyleFormatRule extends FormatRule {
  background?: string;
  text?: string;
  bold?: boolean;
  italic?: boolean;
  type: RuleType.style;
}

export interface ButtonSetWidgetConfig extends WidgetConfig {

}

export interface LeafletWidgetConfig extends WidgetConfig {
  layers: MapLayer[];
  latKey?: string;
  lngKey?: string;
  centerLat?: string;
  centerLng?: string;
  mainLayer: MapLayer;
}

export interface JsonWidgetConfig extends WidgetConfig {
}

export interface PivotGridConfig extends WidgetConfig {
  fieldsChooser: any[];
  showRowGrandTotals: boolean;
  showRowTotals: boolean;
  showColumnGrandTotals: boolean;
  showColumnTotals: boolean;
  FieldChooser: boolean;
  allowFiltering: boolean;
  allowSortingBySummary: boolean;
  fieldChooser: boolean;
}
