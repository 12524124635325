import {Field} from "formik";
import DynamicSelect from "../DynamicSelect";
import React from "react";
import {extractPropsFromDataset, extractValuesEvents} from "./propsHelpers";
import generatePropsFromAttributes from "../parser/utils/generatePropsFromAttributes";
import {extractBooleanExpressionProps} from "./inputHelpers";
import { ErrorMessageCustom } from "../ErrorMessageCustom";

export const select = (node, index, children, context) => {
  const { attribs } = node;
  const { model, errors, touched } = context;

  const needsModel = attribs['data-model-variable'] || attribs['data-query'];
  const events = extractValuesEvents(attribs, context.onInternalEvent);
  const booleanExpProps = extractBooleanExpressionProps(attribs, context);
  const props = extractPropsFromDataset(attribs, {
    'data-model-variable': 'variableName'
  });
  props.creatable = props.creatable !== undefined;
  
  if (props.query) {
    const doc = new DOMParser().parseFromString(props.query, "text/html");
    props.query = doc.documentElement.textContent;
  }
  if (needsModel) props.model = model;
  return (
    <React.Fragment key={attribs.name}>
      <Field component={DynamicSelect} {...events} isValid={touched && touched[attribs.name] && !errors[attribs.name]} 
        {...generatePropsFromAttributes(attribs)} {...props} {...booleanExpProps}>
        {children}
      </Field>
      <ErrorMessageCustom name={attribs.name} touched={touched}/>
    </React.Fragment>
  );
};
