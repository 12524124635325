import { call, query } from "./calls";

export const Apikey = {
    create: (body) => call('/ApiKey', 'POST', body),
    destroy: (id) =>call( `/ApiKey/${id}`, 'DELETE'),
    update: (id, body) => call(`/ApiKey/${id}` , 'PUT', body),
    get: (id) => call(`/ApiKey/${id}`),
    list: () => query("select * from ApiKey"),
    getPermissions: () => query('SELECT * FROM Grant'),
    getPolicies: () => call('/Policy'),
    search: (q) => query(`SELECT * from ApiKey where name like '${q}'`)
}