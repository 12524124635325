import {call} from "./calls";

const GOOGLE_MAPS_API_KEY = 'GOOGLE_MAPS_API_KEY';
const MAPBOX_ACCESS_TOKEN = 'MAPBOX_ACCESS_TOKEN';

export const EnvironmentVariables = {
  create: (values) => call("/EnvironmentVariables", "POST", { values }),
  get: () => call("/EnvironmentVariables").then(result => (result && result.length > 0 && result[0]) || {}),
  GOOGLE_MAPS_API_KEY,
  MAPBOX_ACCESS_TOKEN,
  PREDEFINED_VARIABLES: [
    GOOGLE_MAPS_API_KEY,
    MAPBOX_ACCESS_TOKEN
  ]
};
