import marker from "../../../img/markers/svg/marker.svg";
import markern from "../../../img/markers/svg/marker-n.svg";
import markerne from "../../../img/markers/svg/marker-ne.svg";
import markere from "../../../img/markers/svg/marker-e.svg";
import markerse from "../../../img/markers/svg/marker-se.svg";
import markers from "../../../img/markers/svg/marker-s.svg";
import markerso from "../../../img/markers/svg/marker-so.svg";
import markero from "../../../img/markers/svg/marker-o.svg";
import markerno from "../../../img/markers/svg/marker-no.svg";
import repair from "../../../img/markers/svg/repair.svg";
import repairn from "../../../img/markers/svg/repair-n.svg";
import repairne from "../../../img/markers/svg/repair-ne.svg";
import repaire from "../../../img/markers/svg/repair-e.svg";
import repairse from "../../../img/markers/svg/repair-se.svg";
import repairs from "../../../img/markers/svg/repair-s.svg";
import repairso from "../../../img/markers/svg/repair-so.svg";
import repairo from "../../../img/markers/svg/repair-o.svg";
import repairno from "../../../img/markers/svg/repair-no.svg";

const defaultIcons = [marker, markern, markerne, markere, markerse, markers, markerso, markero, markerno];
const repairIcons = [repair, repairn, repairne, repaire, repairse, repairs, repairso, repairo, repairno];

const MAX_WIDTH = 48,MAX_HEIGHT=48;

localStorage.setItem("localIcons", JSON.stringify([]));

const loadImage =  url => {
  return new Promise((resolve, reject) => {
    let img = new Image();
    img.onload = () => {
        let ratio = 1,width = img.width,height = img.height

        if(height > MAX_HEIGHT || width > MAX_WIDTH){
          if(height > width){
            ratio = MAX_HEIGHT / height
          }
          else{
            ratio = MAX_WIDTH / width
          }
          width = width * ratio
          height = height * ratio
        }
        resolve([width,height])
      };
    img.onerror = () => resolve([MAX_WIDTH,MAX_HEIGHT])
    img.src=url
  });
};

export const getIcon = async (data) => {

  let icon = defaultIcons[getBearingIndexOffset(data)];
  let size = [MAX_WIDTH, MAX_HEIGHT];

  if (data.icon) {
    switch (data.icon) {
      case "repair": icon = repairIcons[getBearingIndexOffset(data)]; break;
      default:
        if (data.icon.startsWith("http")) {
          let arrIcon = JSON.parse(localStorage.getItem("localIcons"));
          let localIcons = arrIcon.find(el => el.icon === data.icon);
          if(localIcons !== undefined){
            return localIcons
          }
          else{
            icon=data.icon
            size = await loadImage(data.icon);
            arrIcon.push({icon,size})
            localStorage.setItem("localIcons",JSON.stringify(arrIcon))
          }
        } else {
          icon = defaultIcons[getBearingIndexOffset(data)];
        }
        break;
    }
  }
  return {icon,size};
};


const getBearingIndexOffset = (data) => {
  if (data && data.bearing) {
    const b = (data.bearing + 22.5) % 360;
    const index = Math.floor(b / 45);
    return index + 1;
  }
  return 0;
};
