export function camelToTitle(camelCase) {
  if (camelCase === camelCase.toUpperCase()) {
    return camelCase.toLowerCase()
      .replace(/_/g, ' ')
      .replace(/(?: |\b)(\w)/g, (match) => match.toUpperCase());
  }

  return camelCase
    .replace(/([A-Z])/g, (match) => ` ${match}`)
    .replace(/^./, (match) => match.toUpperCase())
    .trim();
}
