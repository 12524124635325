import { call, query } from "./calls";
import { BASE_URL } from './config';

export const Account = {
    get: (id) => call(`/Account/${id}`),
    getCorporates : ()=> query("SELECT id, name from Account WHERE kind='CORPORATE'"),
    update: (id,account) => call(`/Account/${id}`, "PUT", account),
    list: () => query("SELECT *  FROM Account WHERE kind != 'THING'"),
    listActiveIdValue: () => query("SELECT id as value, name as label FROM Account WHERE status='ACTIVE' and kind != 'THING'"),
    listActiveIdValueInId: (accountIds) => query("SELECT id as value, name as label FROM Account WHERE status='ACTIVE' AND id IN (".concat(accountIds.join(",")).concat(")  and kind != 'THING'")),
    searchTree: (parentId, name) => query(`select * from Account where status='ACTIVE' and (${parentId} in parents or id = ${parentId}) and isNotNull(name) and name != '' and name like '${name}'  and kind != 'THING'`), // TODO: change back to preparedQuery
    getParents: (accountId) => query(`select parents from Account where id=${accountId}`),
    search: (q) => query(`select * from Account where kind != 'THING' and isNotNull(name) and name != '' and name like '${q}' order by name`),
    ckeckIdentifier: (identifier) => callResetPassword("POST", '/edna/pwdreset', identifier),
    checkToken: (token) => callResetPassword("GET", `/edna/pwdreset/${token}`),
    passwordReset: (body, token) => callResetPassword("PUT", `/edna/pwdreset/${token}`, body),
    updateDashboardId:(accountId,dashboardId) => call(`/Account/${accountId}`, "PUT", {"_command": {"command": "update-dashboard-id","payload": {"accountId": accountId, "dashboardId": dashboardId}}})
};


export const callResetPassword = (method, path, body) => {
    return fetch(`${BASE_URL}${path}`,{
        method,
        body: body ? JSON.stringify(body) : undefined,
        headers: {
            'Content-Type': "application/json"
        }
    });
}