import React, {useCallback, useEffect, useState, memo, useMemo} from "react";
import {InputGroup,Input,InputGroupAddon,Button,Spinner,Form,FormGroup,CustomInput,Collapse,Label} from "reactstrap";
import _ from "lodash";
import styled from "styled-components";
import ItemZone from "./ItemZone";
import {Trans,t} from "@lingui/macro";
import {camelToTitle} from "./Utils";

const ZoneListContainer = styled.div`
  display: flex;
  flex: 1;
  overflow: auto;
`
const ZoneList = ({displayEdit,loadingZones,data,zoneSelected,onClickItemZone,openModalEditZone,onClickDrawNewZone,onDeleteZone, maxHeight, isSaving,scrollToZone,resourceToAddFeature}) => {
  const [results,setResults]=useState([]);
  const [filter,setFilter]=useState(() => {
    return resourceToAddFeature.filterOnFields ? {} : {zoneRadio:'rTodos',filterAll:''}
  });

  const [isOpenFilters, setIsOpenFilters] = useState(false);

  useEffect(()=>{
    if(zoneSelected !== null){
      scrollToZone(zoneSelected,undefined,results)
    }
  },[scrollToZone,results])

  const toggleFilters = () => setIsOpenFilters(!isOpenFilters);


  const delayedFilterZones = useMemo(() =>_.debounce(() => {
    if(data && data.data) {
      if (isOpenFilters && Object.keys(filter).length > 0) {
        let temp = data.data.filter(zone => {
          if (resourceToAddFeature.filterOnFields) {
            return Object.keys(filter).every(fkey =>(filter[fkey] !== '' && zone.hasOwnProperty(fkey) && zone[fkey].toString().toLowerCase().search(filter[fkey].toString().toLowerCase()) > -1))
          }else{
            for (const key in zone){
              if (key !== 'type' && key !== 'id' && (typeof zone[key] === "string" || typeof zone[key] === "number") && zone[key].toString().toLowerCase().search(filter.filterAll.toString().toLowerCase()) > -1) {
                if (filter.zoneRadio === 'rConZona' && !zone[resourceToAddFeature.geometryKey]) {
                  return false
                }
                return !(filter.zoneRadio === 'rSinZona' && zone[resourceToAddFeature.geometryKey]);
              }
            }
          }
          return false
        }) || []
        setResults(temp)

      }else{
        setResults(data.data)
      }
    }
  }, 500),[data && data.data,filter,resourceToAddFeature,isOpenFilters])

  useEffect(()=>{
    delayedFilterZones()
  },[filter,data && data.data])

  const onChangeRadioHandler = useCallback(event => {
    event.persist()
    setFilter(current =>{
      let temp = {...current}
      temp.zoneRadio = event.target.id;
      return temp
    })
  },[])

  const onChangeHandler = useCallback(event => {
    event.persist()
    setFilter(current => {
      let temp = {...current}
      if(resourceToAddFeature.filterOnFields && event.target.value === ''){
          delete temp[event.target.name]
      }else{
        temp[event.target.name] = event.target.value
      }
      return temp
    })
  },[delayedFilterZones,filter,data,resourceToAddFeature]);


  const onClickDrawNewZoneHandler = useCallback(event => {
    event.preventDefault();
    event.stopPropagation()
    onClickDrawNewZone && onClickDrawNewZone()
  },[onClickDrawNewZone])

  const onClearFilters = useCallback(()=>{
    setFilter({})
  },[data])

  return <div className={"container-fluid h-100 p-0"}>
    <div>
      <div className="d-flex justify-content-between">
       <div className="d-flex align-items-center">
         <h6><Trans>Filters</Trans>
           {
             (resourceToAddFeature.filterOnFields && resourceToAddFeature.filterOnFields.length > 0) &&  <span className='ml-1' style={{color:'var(--accentColor)'}}><i className="fas fa-circle fa-xs"/></span>
           }
         </h6>
       </div>
        <Button size='sm' color="link" onClick={toggleFilters} style={{ marginBottom: '.5rem' }}>
          <i className="fas fa-filter"/>
          <span><i className={`fas ${isOpenFilters ? 'fa-angle-up':'fa-angle-down'}`}/></span>
        </Button>
      </div>
      <Collapse isOpen={isOpenFilters}>
        {
          !(resourceToAddFeature.filterOnFields && resourceToAddFeature.filterOnFields.length > 0) ?  <div className={'row'}>
            <div className={'col-12'}>
              <InputGroup>
                <Input name={'filterAll'}  onChange={onChangeHandler}/>
                <InputGroupAddon addonType="prepend">
                  <Button size={'sm'}>
                    <i className="fas fa-search"/>
                  </Button>
                </InputGroupAddon>
              </InputGroup>
            </div>
            <div className={'col-12 mt-3'}>
              <Form>
                <FormGroup>
                  <div>
                    <CustomInput
                      className={'mr-2'}
                      type="radio"
                      checked={filter.zoneRadio === 'rTodos'}
                      id="rTodos"
                      name="filterRadio"
                      label={t`All`}
                      inline
                      onChange={onChangeRadioHandler}
                    />
                    <CustomInput
                      className={'mr-2'}
                      type="radio"
                      checked={filter.zoneRadio === 'rConZona'}
                      id="rConZona"
                      name="filterRadio"
                      label={t`With Zone`}
                      inline
                      onChange={onChangeRadioHandler}
                    />
                    <CustomInput
                      type="radio"
                      checked={filter.zoneRadio === 'rSinZona'}
                      id="rSinZona"
                      name="filterRadio"
                      label={t`With out Zone`}
                      inline
                      onChange={onChangeRadioHandler}
                    />
                  </div>
                </FormGroup>

              </Form>
            </div>
          </div>:<div className='row'>
            <div className='col-12 mt-3'>
              <Form>
                {
                  resourceToAddFeature.filterOnFields && resourceToAddFeature.filterOnFields.map((ff,index) =>{
                    let keys = Object.keys(ff)
                    if(keys.length > 0){
                      return <FormGroup key={index}>
                        <Label className="text-capitalize" for="exampleEmail">{camelToTitle(ff[keys[0]][0])}</Label>
                        <Input type="text"
                               name={keys[0]}
                               id={keys[0]}
                               value={filter[keys[0]] || ''}
                               onChange={onChangeHandler} />
                      </FormGroup>
                    }
                    return null

                  })
                }
                <FormGroup className='d-flex justify-content-end'>
                  <Button outline color="secondary" size='sm' onClick={onClearFilters}><Trans>Clear Filters</Trans></Button>
                </FormGroup>

              </Form>
            </div>
          </div>
        }

      </Collapse>
    </div>
    <ZoneListContainer id={"zoneListContainer"} style={{maxHeight:isOpenFilters?maxHeight-243:maxHeight-143}}>
      {
        loadingZones && <div className={"w-100 d-flex justify-content-center"}><Spinner/></div>
      }
      {
        !loadingZones &&  <div className="list-group ml-0 w-100">
          <div
            className="list-group-item list-group-item-action text-center"
            onClick={onClickDrawNewZoneHandler}
          >
            <p className="font-weight-bold mb-1"><Trans>Create new Zone</Trans></p>
          </div>
          {
            results.map((feature,index)=>{
              return <ItemZone
                key={index}
                index={index}
                isSaving = {isSaving}
                displayEdit={displayEdit}
                feature={feature}
                fieldsToShow={resourceToAddFeature.fieldsToShow}
                onClickItemZone = { onClickItemZone }
                itemZoneSelected = { zoneSelected }
                openModalEditZone = { openModalEditZone }
                onDeleteZone = { onDeleteZone }
                geometryKey = { resourceToAddFeature.geometryKey }
              />
            })
          }
        </div>
      }
    </ZoneListContainer>
  </div>
}
export default memo(ZoneList)
