import React, { useState, useCallback, useEffect } from "react";
import _ from "lodash";
import { Api } from "../api";
import deepDiff from "deep-diff";
import { ConditionEvalSafe, replaceTextVariables, resolvePath } from "./conditions";
import { getAccountId, getTimeZoneCode, getNowMapping } from "../account/accountUtils";
import { useDebounce } from "use-debounce";

const findDependencies = (q, context) => {
  const deps = q.match(/{([\w.[\]]*?)}/g);
  const resolvedDeps = deps && deps.map(d => {
    if (d.includes('[')) { // using [key] inside expression
      d = d.replace(/\[([\w.[\]]*?)]/g, (substring, subKey) => resolvePath(context, subKey));
    }
    return d.substring(1, d.length - 1);
  })
  return resolvedDeps || [];
};

// memoize debounce to separate by different arguments
_.mixin({
  memoizeDebounce: function (func, wait = 0, options = {}) {
    let mem = _.memoize(function () {
      return _.debounce(func, wait, options)
    }, options.resolver);
    return function () { mem.apply(this, arguments).apply(this, arguments) }
  }
});

const ActionContainer = ({ queries, mappings, values, onKeyUpdated, setFieldValue, onModelUpdated, submitDataObjects }) => {

  const [currentValues, setCurrentValues] = useState(values);

  const [appliedInitialQueries, setAppliedInitialQueries] = useState(false);
  const [executedInitialMappings, setExecutedInitialMappings] = useState(false);

  const [pendingChanges, setPendingChanges] = useState({
    observedValues: values,
    paths: []
  });
  const [debouncedChanges] = useDebounce(pendingChanges, 600);

  const applyQueries = useCallback((changes) => {
    const { paths, observedValues: values } = (changes || {});
    console.log(`${!appliedInitialQueries ? 'initial ' : ''}applying queries for paths, values`, paths, values);
    const condition = new ConditionEvalSafe(values);
    queries.forEach(q => {
      if (!q.onEvents || q.onEvents === "") {
        const depsQuery = findDependencies(q.queryText);
        const depsCondition = (q.condition && findDependencies(q.condition)) || [];
        const deps = [...depsQuery, ...depsCondition]
        if (!appliedInitialQueries || deps.some(d => paths.some(p => d.startsWith(p)))) {
          if (!q.condition || condition.run(q.condition)) {
            console.log('deps and paths match', deps, paths);
            const query = values ? replaceTextVariables(q.queryText, values) : q.queryText;
            Api.query(query).then(result => {
              if (q.updatesFormValues) {
                if (setFieldValue)
                  setFieldValue(q.name, result);
              } else {
                onKeyUpdated(q.name, result);
              }
            });
          }
        }
      }
    });
    setAppliedInitialQueries(true);
  }, [queries, onKeyUpdated, setFieldValue, appliedInitialQueries]);

  const executeMappings = useCallback((changes) => {
    const { paths, observedValues: values } = (changes || {});
    const extendedValues = { ...values, _accountId: getAccountId(), _timeZoneCode: getTimeZoneCode(), _now: getNowMapping() };
    console.log("extendedValues mappiung: ", extendedValues);
    const condition = new ConditionEvalSafe(extendedValues);
    console.log(`executing mappings for paths, values`, paths, extendedValues);
    mappings.forEach(m => {
      if (!m.onEvents || m.onEvents === "") {
        const depsExpression = findDependencies(m.expression, extendedValues);
        const depsCondition = (m.condition && findDependencies(m.condition, extendedValues)) || [];
        const deps = [...depsExpression, ...depsCondition]
        if (!executedInitialMappings || deps.some(d => paths.some(p => d.startsWith(p)))) {
          if (!m.condition || condition.run(m.condition)) {
            const sd = deps.find(d => paths.some(p => p.startsWith(d))); // debugging
            const result = condition.run(m.expression);
            console.log('executed mapping', executedInitialMappings, sd, m.key, m.expression, result);
            if (m.updatesFormValues) {
              if (setFieldValue) {
                if (m.individualKeys) {
                  if (typeof result === 'object') {
                    Object.keys(result).forEach(k => setFieldValue(k, result[k]));
                  }
                } else {
                  setFieldValue(m.key, result);
                }
              }
            } else {
              if (m.individualKeys) {
                if (typeof result === 'object') {
                  Object.keys(result).forEach(k => onKeyUpdated(k, result[k]));
                }
              } else {
                onKeyUpdated(m.key, result);
              }
            }
          }
        }
      }
    });
    setExecutedInitialMappings(true);
  }, [mappings, onKeyUpdated, setFieldValue, executedInitialMappings]);
  // accumulate pending changes for queries
  useEffect(() => {
    const currentValues = pendingChanges.observedValues;
    const diffResult = deepDiff(currentValues, values);
    console.log('received new values for queries, diff is', diffResult !== undefined);
    if (diffResult) {
      console.log('diff result', diffResult);
      setPendingChanges(changes => {
        const { paths } = changes;
        const newPaths = [...paths];
        diffResult.map(change => change.path.join('.')).forEach(path => {
          if (!newPaths.includes(path)) {
            newPaths.push(path);
          }
        });
        console.log('accumulating changes', newPaths, values);
        return { observedValues: values, paths: newPaths }; // difference in paths
      });
    }
  }, [values]);

  // execute mappings immediately
  useEffect(() => {
    const diffResult = deepDiff(currentValues, values);
    console.log('received new values for mappings, diff is', diffResult !== undefined);
    if (diffResult) {
      const paths = [...new Set(diffResult.map(change => change.path.join('.')))];
      executeMappings({
        observedValues: values,
        paths
      });
      if (onModelUpdated) {
        const { _initialModel, _model, ...other } = values;
        if (!submitDataObjects) onModelUpdated({ ..._initialModel, ...other });
        else onModelUpdated(values);
      }
    }
    setCurrentValues(values);
  }, [currentValues, values, executeMappings, onModelUpdated, submitDataObjects]);

  // process pending changes when debounced
  useEffect(() => {
    if (debouncedChanges.paths.length > 0) {
      console.log('processing accumulated changes', debouncedChanges.paths, debouncedChanges.observedValues);
      applyQueries(debouncedChanges);

      // remove already processed paths
      setPendingChanges(changes => {
        const { paths } = changes;
        console.log('removing processed paths', paths, debouncedChanges.paths);
        const newPaths = paths.filter(path => !debouncedChanges.paths.includes(path))
        console.log('new remaining paths', newPaths, changes.observedValues);
        return { ...changes, paths: newPaths };
      });
    }
  }, [debouncedChanges, applyQueries]);

  useEffect(() => {
    // initial execution
    if (!appliedInitialQueries)
      applyQueries(debouncedChanges);
    if (!executedInitialMappings)
      executeMappings(debouncedChanges);
  }, [queries, mappings, appliedInitialQueries, executedInitialMappings, debouncedChanges]);

  return <div />;
};

export default ActionContainer;
