import {call, query} from "./calls";
import {getTimeZoneCode} from "../account/accountUtils";

export const UserTask = {
  list: () => query(`SELECT *, id, description as name, elementId, triggerId, formId, subtitle,
                    dateFormat(creationDate, '${getTimeZoneCode()}', 'dd/MM/yyyy HH:mm:ss') as date,
                    if(isNotNull(lastSubmit), dateFormat(lastSubmit, 'America/Mendoza', 'dd/MM/yyyy HH:mm:ss'), null) as lastSubmit
                    FROM PendingUserTasks`),
  getModel: (id) => call(`/PendingUserTaskModel/${id}`),
  getComments: (id) => query(`select id, comment, AccountList.name as accountName,
    dateFormat(date, '${getTimeZoneCode()}', 'dd/MM/yyyy HH:mm:ss') as date, processInstanceStepId
    from ProcessInstanceComment left join AccountList on ProcessInstanceComment.accountId = AccountList.id
    where processInstanceStepId = ${id} order by date asc`),
  addComment: (text, taskId) => call('/ProcessInstanceComment', 'POST', {
    processInstanceStepId: taskId,
    comment: text
  })
};
