import React from 'react';
import './NavigationListDropdown.css';
import themes from "../../../../styles/themes";
import {Link} from "react-router-dom";

const NavigationListDropdown = ({switchTheme,openEditor}) => {

  return (
    <li className="hover-dropdown">
      <div className="dropbtn"><Link to={{}}><div className="mainitem"><i className="fas fa-palette"></i>
        <span className="item-text">
                Temas
              </span>
      </div></Link></div>
      <div className="dropdown-content">
          <ul>
            {themes.map((value, index) =>(
              <li key={index}>
                <div className="list-item" onClick={()=>switchTheme(value)}><i className={value === 'dark' ? "fas fa-moon" : "fas fa-sun"}></i>
                  <span className="item-text">{value}</span>
                </div>
              </li>
            ))
            }
            <li><div className="list-item"  onClick={openEditor}><i className="fas fa-paint-brush"></i>
              <span className="item-text">Custom</span>
            </div></li>
          </ul>
      </div>
    </li>
  );
}
export default NavigationListDropdown;
