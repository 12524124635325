// eslint-disable-next-line no-unused-vars
import React, {useEffect, useState, memo} from 'react';
import L from 'leaflet'
import {useLeaflet} from "react-leaflet";
import {useLink,useScript} from "../mapUtils";
import {getGoogleMapsKey} from "../../../keys";

const StreetView = ({viewData, onCloseStreetViewClearViewData}) =>{
  const {map}=useLeaflet()
  const [isLoadedCss,setLoadedCss] = useState(false);
  const [isLoadedJs,setLoadedJs] = useState(false);
  const [pegman,setPegman] = useState(null)

  useLink("https://unpkg.com/leaflet-pegman@0.1.6/leaflet-pegman.css",()=>{
    setLoadedCss(true)
  })
  useScript("https://unpkg.com/leaflet-pegman@0.1.6/leaflet-pegman.js",()=>{
   setLoadedJs(true)
  })

  useEffect(() => {
    if(map && isLoadedCss && isLoadedJs){
      let pegmanControl = new L.Control.Pegman({
        position: 'bottomright', // position of control inside the map
        theme: "leaflet-pegman-v3-small", // or "leaflet-pegman-v3-default"
        apiKey: getGoogleMapsKey()
      });

      pegmanControl.onStreetViewPanoramaClose = () =>{
        pegmanControl.clear()
        onCloseStreetViewClearViewData()
      }
      setPegman(pegmanControl.addTo(map))
    }

  },[map,isLoadedCss,isLoadedJs, onCloseStreetViewClearViewData])

  useEffect(()=>{
    if(viewData){
      pegman.openStreetViewPanorama();
      pegman.findStreetViewData(viewData[0], viewData[1] );
    }
  },[viewData])
  return null
}

export default memo(StreetView)
