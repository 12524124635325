import React from 'react';
// @ts-ignore
import SocialLogin from 'react-social-login';

// @ts-ignore
const Button = ({ children, triggerLogin, img, ...props}) => (
    <img src={img} width={64} className="loginmethod"
         onClick={triggerLogin}
         {...props}>
        {children}
    </img>
);

export default SocialLogin(Button);
