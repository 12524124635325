import { lazy } from "react";

const routes = [
  {
    path:"/bpm/:id/instance/:instanceId",
    exact: true,
    main:lazy(()=>import('./components/bpmn-tool/BpmnInstanceViewer')),
    permission: 'menu_apps_bpm'
  },
  {
    path: "/bpm/:id",
    exact: true,
    main: lazy(() => import('./components/bpmn-tool/BpmnTool')),
    permission: 'menu_apps_bpm'
  },

	{
	  path: "/bpm",
    exact: true,
	  main: lazy(() => import('./components/bpmn-tool/BpmnTool')),
	  permission: 'menu_apps_bpm'
	},
  {
    path: "/",
    exact: true,
    redirect: true,
    pathTo: "/login",
    permission: 'public'
  },
  {
    path: "/dashboard/:id",
    main: lazy(() => import('./dashboard/Dashboard')),
    permission: 'menu_apps_dashboard'
  },
  {
    path: "/dashboard",
    exact: true,
    main: lazy(() => import('./layout/newLayout/folderNavigator/FolderNavigator')),
    permission: 'menu_apps_dashboard',
  },
  {
    path: "/processes",
    exact: true,
    main: lazy(() => import('./processes/Processes')),
    permission: 'menu_processes'
  },
  {
    path: "/tasks",
    exact: true,
    main: lazy(() => import('./tasks/Tasks')),
    permission: 'menu_tasks'
  },
  {
    path: "/notifications",
    exact: true,
    main: lazy(() => import('./notifications/NotificationsList')),
    permission: 'public'
  },
  {
    path: "/admin",
    exact: true,
    main: lazy(() => import('./admin/Admin')),
    permission: 'menu_admin'
  },
  {
    path: "/admin/apps/:id([0-9a-fA-F]{8}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{12})/artifacts",
    main: lazy(() => import('./admin/apps/artifacts/ArtifactsAdmin')),
    permission: 'menu_admin_apps',
    exact: true
  },
  {
    path: "/admin/apps/:id([0-9a-fA-F]{8}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{12})/version",
    exact: true,
    main: lazy(() => import('./admin/apps/version/Version')),
    permission: 'menu_admin_apps',
  },
  {
    path: "/admin/:id",
    main: lazy(() => import('./admin/AdminLayout')),
    permission: 'menu_admin',
    exact: true
  },
  {
    path: "/launch",
    exact: true,
    main: lazy(() => import('./launch/Launch')),
    permission: 'menu_launch'
  },
  {
    path: "/query",
    exact: true,
    main: lazy(() => import('./components/query-runner/QueryRunner')),
    permission: 'menu_query'
  },
  {
    path: "/crud",
    exact: true,
    main: lazy(() => import('./admin/cruds/CrudRepositoryNavigator')),
    permission: 'menu_apps_crud'
  },
  {
    path: "/crud/:id+",
    main: lazy(() => import('./admin/cruds/CrudsRepository')),
    permission: 'menu_apps_crud'
  },
  {
    path: "/apps",
    exact: true,
    main: lazy(() => import('./layout/newLayout/apps/Apps')),
    permission: 'menu_apps',
  },
  {
    path: "/apps/:filter",
    exact: true,
    main: lazy(() => import('./layout/newLayout/folderNavigator/FolderNavigator'))
  },
  {
    path: "/apps/:filter/:id+",
    main: lazy(() => import('./layout/newLayout/folderNavigator/FolderNavigator')),
    permission: 'menu_apps',
  },
  {
    path: "/home",
    exact: true,
    main: lazy(() => import('./layout/newLayout/home/Home')),
    permission: 'menu_home',
  },
  {
    path: "/home/:id+",
    main: lazy(() => import('./layout/newLayout/folderNavigator/FolderNavigator')),
    permission: 'menu_home',
  },
  {
    path: "/shared",
    exact: true,
    main: lazy(() => import('./layout/newLayout/shared/Shared')),
    permission: 'menu_shared'
  },
  {
    path: "/shared/:id+",
    main: lazy(() => import('./layout/newLayout/shared/Shared')),
    permission: 'menu_shared'
  },
  {
    path: "/folders",
    exact: true,
    main: lazy(() => import('./layout/newLayout/folderNavigator/FolderNavigator')),
    permission: 'menu_navigator'
  },
  {
    path: "/folders/:id+",
    main: lazy(() => import('./layout/newLayout/folderNavigator/FolderNavigator')),
    permission: 'menu_navigator'
  },
  {
    path: "/issueTracker",
    main: lazy(() => import('./admin/issueTracker/IssueTracker')),
    permission: 'menu_issueTracker',
    exact: true
  },
  {
    path: "/issueTracker/issue",
    main: lazy(() => import('./admin/issueTracker/issue/Issue')),
    permission: 'menu_issueTracker',
    exact: true
  },
  {
    path: "/issueTracker/issue/:id([0-9a-fA-F]{8}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{12})/edit",
    main: lazy(() => import('./admin/issueTracker/issue/issue-form/IssueForm')),
    exact: true,
    permission: 'menu_issueTracker'
  },
  {
    path: "/issueTracker/issue/:id([0-9a-fA-F]{8}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{12})",
    main: lazy(() => import('./admin/issueTracker/issue/issue-detail/IssueDetail')),
    exact: true,
    permission: 'menu_issueTracker'
  },
  {
    path: "/issueTracker/issue/new",
    main: lazy(() => import('./admin/issueTracker/issue/issue-form/IssueForm')),
    permission: 'menu_issueTracker',
    exact: true,
  },
  {
    path:"/issueTracker/milestone",
    main: lazy(() => import('./admin/issueTracker/milestone/Milestone')),
    permission: 'menu_issueTracker_milestone',
    exact: true
  },
  {
    path:"/issueTracker/milestone/:id([0-9a-fA-F]{8}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{12}|[0-9a-z]{10})/edit",
    main: lazy(() => import('./admin/issueTracker/milestone/MilestoneForm')),
    permission: 'menu_issueTracker_milestone',
    exact: true
  },
  {
    path:"/issueTracker/milestone/new",
    main: lazy(() => import('./admin/issueTracker/milestone/MilestoneForm')),
    permission: 'menu_issueTracker_milestone',
    exact: true
  },
  {
    path:"/issueTracker/milestone/:id([0-9a-fA-F]{8}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{12}|[0-9a-z]{10})",
    main: lazy(() => import('./admin/issueTracker/milestone/MilestoneDetail')),
    permission: 'menu_issueTracker_milestone',
    exact: true
  },
  {
    path: "/issueTracker/:id+",
    main: lazy(() => import('./admin/issueTracker/IssueTrackerLayout')),
    exact: true,
    permission: 'menu_issueTracker'
  },
  {
    path: "/issueTracker/:section/:id",
    main: lazy(() => import('./admin/issueTracker/IssueTrackerLayout')),
    exact: true,
    permission: 'menu_issueTracker'
  },
  {
    path: "/failPermissions",
    main: lazy(() => import('./layout/FailPermissionsTemplate')),
    permission: 'public'
  },
  {
    path:"/issuelifecycleEditor/:id",
    main: lazy(() => import('./admin/issueLifeCycle/IssueLifeCycleEditor')),
    permission: 'public',
    exact: true
  },
  {
    path:"/issuelifecycleEditor",
    main: lazy(() => import('./admin/issueLifeCycle/IssueLifeCycleEditor')),
    permission: 'public',
    exact: true
  },
  {
    path: "/packagedApp",
    main: lazy(() => import('./admin/packagedApp/SearchPackagedApp')),
    permission: 'public',
    exact: true
  }
];

export default routes;
