import { call, query } from "./calls";

export const DynamicStoreRule = {
    list: () => query("SELECT * FROM DynamicStoreRule order by resourceName"),
    search: (q) => query(`SELECT * FROM DynamicStoreRule WHERE resourceName like '${q}' OR description like '${q}' order by resourceName limit ,50`),
    get: (id) => query(`SELECT * FROM DynamicStoreRule where id=${id}`),
    create: (values) => call("/DynamicStoreRule", "POST", values),
    update: (id, values) => call(`/DynamicStoreRule/${id}`, "PUT", values),
    remove: (id) => call(`/DynamicStoreRule/${id}`, "DELETE"),
    getDynamicStoreRule:(name) => query(`select id as artifactId, concat(description, ' (', kind, ' ', resourceName, ')') as artifactName from DynamicStoreRule where description like '${name}' limit 100`),
};
