import React, {useEffect, useState, useCallback, memo} from 'react';
import {Map} from "react-leaflet";
import PropTypes from "prop-types";
import {LayersControlWrapped, MyLeafletComponent} from './mapUtils'
import 'leaflet/dist/leaflet.css';
import './style.scss';
import { GeoJsonMap, StreetView } from '.';

const LeafletMap = ({center=[0,0],
                      zoom = 2, streetView = true,
                      zoomPosition = "bottomright",
                      cartographies=[],
                      initialHandle,
                      onDragend,
                      handleZoomEnd,children,layerControlPosition='topleft'}) => {

  const [map,setMap]=useState(null)
  const [streetViewData, setStreetViewData] = useState();

  const [cartographySelected,setCartographySelected]=useState(()=>{
    if(cartographies.length > 0)
      return cartographies[0];
    return null;
  })

  const init = useCallback((newMap) => {
    initialHandle && initialHandle(newMap)
    !map && setMap(newMap)
  },[map,initialHandle])

  useEffect(()=>{
    if(map !== null){
      map.zoomControl.setPosition(zoomPosition)
    }
  },[map]);

  const cartographySelectedHandle = useCallback((layer)=>{
    setCartographySelected(layer)
  },[])

  const onDragendHandle = useCallback((event)=>{
    onDragend && onDragend(event)
  },[onDragend])

  const onZoomEndHandle = () => {
    handleZoomEnd && handleZoomEnd()
  };
  
  const openViewStreetFromMarker = useCallback(setStreetViewData,[]);
  const onCloseStreetViewClearViewData = useCallback(() =>setStreetViewData(false) ,[]);

  return <Map
    center={center}
    zoom={zoom}
    onBaseLayerChange={baseLayer => setCartographySelected(baseLayer)}
    onZoomend={onZoomEndHandle}
    onDragend={onDragendHandle}
  >
    <MyLeafletComponent
      initialHandle={init}
    />
    <LayersControlWrapped
      layerControlPosition={layerControlPosition}
      cartographySelected={cartographySelected}
      setCartographySelected={cartographySelectedHandle}
      cartographies={cartographies}
    />
    <GeoJsonMap openViewStreetFromMarker={openViewStreetFromMarker} />
    {streetView && <StreetView viewData={streetViewData} onCloseStreetViewClearViewData={onCloseStreetViewClearViewData}/>}
    {
      children
    }
  </Map>
}
LeafletMap.propTypes = {
  //map center
  center:PropTypes.array,
  //initial map zoom
  zoom:PropTypes.number,
  //map cartographies by default OSM
  cartographies:PropTypes.array,
  // callback when map is ready
  initialHandle:PropTypes.func,
  // event Map DragEnd
  onDragend:PropTypes.func,
  // event Map ZoomEnd
  onZoomEnd:PropTypes.func
}
export default memo(LeafletMap)
