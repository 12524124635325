import React, { useCallback, useEffect, useMemo, useState } from 'react';
import DataGrid, { Column, FilterRow, Scrolling, Pager, Paging, Summary, TotalItem, Format, Selection } from 'devextreme-react/data-grid';
import './DataGridComponent.css';
import {getLocaleDateString} from "../../i18n";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { t } from '@lingui/macro';
/**
 *
 * @param columns array with objects as
 * { name: 'title', label: 'Title', type: 'string | number | date | datetime | boolean', alignment: 'left | center | right',
 * allowFiltering: boolean, fixed: boolean, summary: 'sum | mmin | max | avg | count' }
 * @param values array with data
 * @param cellRender render columns cell (cellData) => return JSX.Element
 * @param setRef set table reference (ref) => {}
 * @param onCellClick a function that is executed when a cell is clicked or tapped. Executed before onRowClick.
 * @param onRowClick a function that is executed when a row is clicked or tapped.
 * @param enableRowSelection activate row selection. false (default)
 * @param rowSelectionMode select selection mode (single (default) | multiple)
 * @param onRowSelected function that returns selected rows. (selectedRows) => {}
 * @returns {JSX.Element}
 * @constructor
 */
const DataGridComponent = ({columns = [], values, cellRender=defaultCellRender, onRefCallback, onCellClick, onRowClick,
  rowSelectionMode = 'single', enableRowSelection = false, onRowSelected, pageSize = false, allowSorting=true, onDownload,
  showDownload}) => {
  const [extendedMenuOpen, setExtendedMenuOpen] = useState(false);
  const toggleExtendedMenu = useCallback(() => setExtendedMenuOpen(open => !open), []);
  const [summary, setSummary] = useState([]);

  const filter = useMemo(() => {
    for (const key in columns) {
      if(columns[key] && columns[key].filter) { return true; }
    };
    return false;
  }, [columns]);

  useEffect(() => {
    //check summary
    if(columns.length > 0){
      let totalItem = [];
      columns.forEach(col => { if(col.summary) totalItem.push(col) });
      setSummary(totalItem);
    }
  }, [columns]);

  const propsColumns = (col) => {
    let format = {}
    if (!col.format) {
      if (col.type === "date") {
        format = { format: getLocaleDateString() }
      } else if (col.type === "datetime") {
        format = { format: getLocaleDateString() + " HH:mm:ss" }
      }
    } else {
      format = { format: { type: col.numberFormat, currency: col.currency, precision: col.precision } };
    }
    return {
      ...col, dataField: col.name, dataType: col.type, caption: col.label,
      alignment: col.alignment, allowFiltering: col.filter, fixed: col.freeze,
      cellRender: cellRender || col.cellRender, ...format
    }
  };

  return (
    <div className='dx-container-component'>
      <DataGrid className="data-grid-component-content" dataSource={values} showColumnLines={false}
        columnAutoWidth={true}
        hoverStateEnabled={true}
        ref={(ref) => onRefCallback && onRefCallback(ref)}
        onRowClick={onRowClick}
        onCellClick={onCellClick}
        allowColumnResizing={true}
        columnResizingMode="widget"
        onSelectionChanged={(row) => onRowSelected && onRowSelected(row)}
        rowAlternationEnabled={true}
        loadPanel={false}
      >
        <Scrolling mode={!pageSize && "virtual"} rowRenderingMode={pageSize && "virtual"} />
        {enableRowSelection && <Selection mode={rowSelectionMode} showCheckBoxesMode="onClick" />}
        <FilterRow visible={filter} />
        {columns.length > 0 && columns.map((c, index) => (
          <Column key={index} {...propsColumns(c)} allowSorting={allowSorting} allowSearch={true} cssClass="column-date-box"  >
            {c.numberFormat && <Format type={c.numberFormat} currency={c.currency} precision={c.precision} />}
          </Column>
        ))}
        {pageSize && <Paging pageSize={pageSize} />}
        {pageSize && <Pager displayMode="full" showNavigationButtons={true} />}
        {summary.length > 0 &&
          <Summary>
            {summary.map((item, key) => <TotalItem key={key} column={item.name} summaryType={item.summary} />)}
          </Summary>
        }
      </DataGrid>

      {showDownload && onDownload &&
        <div className='container-button-download-table'>
          <div className='d-flex justify-content-end'>
            <Dropdown isOpen={extendedMenuOpen} toggle={toggleExtendedMenu}>
              <DropdownToggle color="link">
                <i className="fas fa-download mr-0" title={t`Download`} />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={onDownload}>
                  <div>
                    <i className="fas fa-file-csv" style={{ marginRight: 10, fontSize: "80%", color: "var(--accentColor)" }} />
                    CSV
                  </div>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
        </div>
      }
    </div>
  )
}

const defaultCellRender = (cellData) => {
  const {text} = cellData;
  return <div className='default-cell-render'>{text}</div>
}

export const types = ["number", "text", "boolean", "date", "datetime"];
export default DataGridComponent;
