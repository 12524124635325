import React, {useCallback, memo} from "react";
import {Button} from "reactstrap";
import {t} from "@lingui/macro";

const ItemZone = ({displayEdit,feature,fieldsToShow,
                    itemZoneSelected, onClickItemZone,openModalEditZone,onDeleteZone,index,geometryKey,isSaving}) =>{
  const selected = itemZoneSelected && itemZoneSelected.id === feature.id;
  const onClickHandler = useCallback((event) =>{
    event.preventDefault()
    event.stopPropagation()
    onClickItemZone && onClickItemZone(feature,index)
  },[onClickItemZone,feature,index])

  const onItemEditClickHandler = useCallback((event) => {
    event.preventDefault()
    event.stopPropagation()
    openModalEditZone && openModalEditZone(feature)
  },[openModalEditZone,feature])

  const onDeleteHandler = useCallback(()=>{
    let confirm = window.confirm(t`Are you sure you want to delete this Zone?`)
    if(confirm){
      onDeleteZone && onDeleteZone(feature,index)
    }
  },[onDeleteZone,feature,index])

  return  <div
    className={"list-group-item list-group-item-action p-0 pr-1 " + (selected?"active":"") }
    onClick={onClickHandler}
  >
    <div className="d-flex w-100 justify-content-between">
      <div className={"d-flex flex-row"}>
        <div className='d-flex align-items-center ml-1 mr-3'>
          {
            feature[geometryKey] ?<i className="fab fa-connectdevelop"/>:<i className="fas fa-draw-polygon"/>
          }
        </div>
        <div className='d-flex align-items-left flex-column'>
          {
            fieldsToShow.split(',').map((field,index)=>{
                if(feature[field]){
                  return (index === 0) ? <p key={index} className={"font-weight-bold mb-1" + (selected?" text-white":"")}>{feature[field]}</p> :
                    <small key={index}  className={(selected?" text-white":"text-muted")}>{feature[field]}</small>
                }
                return null;
               })
          }
        </div>
      </div>

        <div className={"d-flex flex-column"}>
          {displayEdit &&  <Button disabled={isSaving} size={'sm'} color={'link'} onClick={onItemEditClickHandler}>
            <i className={"fas fa-pencil-alt"}/>
          </Button>}
          <Button disabled={isSaving} size={'sm'} color={'link'} onClick={onDeleteHandler}>
            <i className={"fas fa-times"} />
          </Button>
        </div>

    </div>
  </div>
}
export default memo(ItemZone)
