import React, { useState, useCallback, useEffect } from 'react';
import Input from 'reactstrap/lib/Input';
import Button from 'reactstrap/lib/Button';
import FormGroup from 'reactstrap/lib/FormGroup';
import './SelectionList.css';
import { t, Trans } from "@lingui/macro";

const SelectionList = ({ options, value, onChange, disabled, valueKey, labelKey, placeholder }) => {
    const [valueArray, setValueArray] = useState([...(value || [])]);
    const [option, setOption] = useState(options);
    const [valueSearch, setValueSearch] = useState('');

    useEffect(() => setValueArray([...(value || [])]), [value]);
    useEffect(() => {
        const newOptions = [...options];
        newOptions.sort((a, b) => {
            if(a[labelKey] > b[labelKey]) return 1;
            if(a[labelKey] < b[labelKey]) return -1;
            return 0;
        });
        valueArray.forEach(e => {
            let keyOption = newOptions.findIndex(item => item[valueKey] === e);
            if(keyOption >= 0){
                const option = newOptions[keyOption];
                newOptions.splice(keyOption, 1);
                newOptions.unshift(option);
            }
        });
        setOption(newOptions);
    }, [options]);

    const search = useCallback((value) => {
        setValueSearch(value);
        let filter = options.filter((index) => index[labelKey].toString().toUpperCase().indexOf(value.toUpperCase()) > -1);
        setOption(filter);
    }, [options]);

    const check = useCallback((e, id) => {
        if (e.checked) {
            valueArray.push(id);
        } else {
            valueArray.splice(valueArray.indexOf(id), 1);
        }
        onChange(valueArray);
    }, [onChange, valueArray]);

    const onSelectAll = useCallback(() => {
        onChange(option.map(o => o[valueKey]));
      }, [onChange, option, valueKey]);
    const onClear = useCallback(() => {
        onChange([]);
    }, [onChange]);  
    const onInvert = useCallback(() => {
        onChange(option.map(o => o[valueKey]).filter(o => !valueArray.includes(o)));
    }, [onChange, option, valueKey, valueArray]);

    return (
        <>
            <FormGroup>
                <Input type="text" value={valueSearch} disabled={disabled} placeholder={placeholder ? placeholder : t`Filter`} onChange={e => search(e.target.value)} />
                <div className="list-option">
                    <div className="checks">
                        {option && option.map(op => {
                            return (
                                <div className="content-checks" key={op[valueKey]}>
                                    <Input type="checkbox" disabled={disabled} id={op[valueKey]} checked={valueArray.includes(op[valueKey])} onChange={(e) => check(e.target, op[valueKey])} />
                                    <p>{op[labelKey]}</p>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className="list-actions">
                    <Button color="link" size="sm" onClick={onSelectAll}><Trans>All</Trans></Button> |
                    <Button color="link" size="sm" onClick={onClear}><Trans>Clean Up</Trans></Button> |
                    <Button color="link" size="sm" onClick={onInvert}><Trans>Invert</Trans></Button>
                </div>
            </FormGroup>
        </>
    );


};

export default SelectionList;
