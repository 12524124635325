import { isArray } from "lodash";
import nanoid from "nanoid";

const setData = ({key, color, cluster, data}) => {
  let values = {...data}
  if(color) values.properties = { ...values.properties, fillColor: color, color };
  if(key !== undefined) {
    values.id = nanoid();
    values.parentIndex = key;
  }
  if(cluster) values.cluster = cluster;
   // add type if it doesn't exist in the features
  if(!values.type) values.type = "Feature";
  return values;
}

export const extractFeatures = (layers, config) => {
  const { key, color, cluster } = config;
  const collection = [];
  if(isArray(layers)){
    layers.forEach(layer => {
      if (layer.type === "FeatureCollection") {
        layer.features.forEach(f => {
          let layerData = setData({key, color, cluster, data: f});
          collection.push(layerData);
        });
      } else if (layer.type === "Feature") {
        let layerData = setData({key, color, cluster, data: layer});
        collection.push(layerData);
      };
    })
  }else{
    if (layers.type === "FeatureCollection") {
      layers.features.forEach(f => {
        let layerData = setData({key, color, cluster, data: f});
        collection.push(layerData);
      });
    } else if (layers.type === "Feature") {
      let layerData = setData({key, color, cluster, data: layers});
      collection.push(layerData);
    };
  }
  return collection;
}

export const cleanFeatures = (data) => {
  if(data){
    data.forEach(f => {
      delete f.id; delete f.parentIndex; delete f.cluster;
    });
  }
  return data;
}