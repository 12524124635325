import React, {useState} from 'react'
import {Collapse, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from "reactstrap";
import themes from "../../../../styles/themes";
import "./DropdownMobile.css";
import "./OverflowMenuDropdown.css";

const CHANGE_THEME_PERMISSION = "changeThemePermission";

const OverflowMenuDropdown = ({userData,switchTheme,openEditor,onUserAccount,onUserPreferences, actionItems=[]}) => {

  const [submenuIsOpen, setSubmenuIsOpen] = useState(false);
  const {name,srcAvatar,email,rgb} = userData;

  return (
    <UncontrolledDropdown className="overflow-menu-dropdown">
      <DropdownToggle tag="a" className="" onClick={()=>{setSubmenuIsOpen(false)}}>
        {actionItems.length === 0 &&
          (srcAvatar.startsWith("http")
            ? <img alt={"user avatar"} src={srcAvatar} width={23} height={23} style={{borderRadius: "15px"}}></img>
            : <span className="fa-stack fa-1x">
                <i className="fa fa-circle fa-stack-2x" style={{color: rgb}} />
                <span className="fa fa-stack-1x avatar">{srcAvatar}</span>
              </span>
          )
        }
        {actionItems.length > 0 &&
          <i className="fa fa-ellipsis-v"></i>
        }
      </DropdownToggle>
      <DropdownMenu right className="dropdown-menu">
        { actionItems.length === 0 &&
          <>
            <DropdownItem className="user-preference-item">
              <div className="user-data-dropdown-header">
                <div className="user-avatar">
                  {srcAvatar.startsWith("http")
                    ? <img alt="user avatar" src={srcAvatar} width={30} height={30} style={{borderRadius: "15px"}}></img>
                    : <span className="fa-stack fa-1x">
                        <i className="fa fa-circle fa-stack-2x" style={{color: rgb}}></i>
                        <span className="fa fa-stack-1x avatar user-avatar">{srcAvatar}</span>
                      </span>
                  }
                </div>
                <div className="user-info" onClick={()=>{}}>
                  <span className="item-text name">{name}</span>
                  <span header={"true"} className="item-text">{email}</span>
                </div>
              </div>
            </DropdownItem>
            <DropdownItem divider/>
            <DropdownItem className={"menu"} onClick={onUserAccount}>
              <i className="fas fa-user"></i>
              <span className="">Mi Cuenta</span>
            </DropdownItem>
            <DropdownItem className={"menu"} onClick={onUserPreferences}>
              <i className="fas fa-tools"></i>
              <span className="">Preferencias</span>
            </DropdownItem>
            { localStorage.getItem(CHANGE_THEME_PERMISSION) === 'true' &&
              <React.Fragment>
                <div className="dropdown-item menu" onClick={() => setSubmenuIsOpen(!submenuIsOpen)}>
                  <i className="fas fa-palette"></i>
                  <span className="">Temas</span>
                </div>
                <Collapse isOpen={submenuIsOpen}>
                  <div className="sub-list">
                    <ul>
                    {themes.map((value, index) => (
                      <li key={index}>
                        <div className="list-item" onClick={() => switchTheme(value)}><i className="fa fa-paint-brush"></i>
                          <span className="item-text">{value}</span>
                        </div>
                      </li>
                    ))
                    }
                    <li><div className="list-item"  onClick={openEditor}><i className="fas fa-paint-brush"></i>
                      <span className="item-text">Custom</span>
                    </div></li>
                    </ul>
                  </div>
                </Collapse>
              </React.Fragment>
            }
            <DropdownItem onClick={() => {window.location.replace(`/login`)}} className={"menu"}>
              <i className="fas fa-power-off"></i>
              <span className="">Cerrar Sesión</span>
            </DropdownItem>
          </>
        }
        { actionItems.map((item) => {
          return (
            <>
              <DropdownItem onClick={() => {item.action && item.action()}} className={"menu"}>
                <i className={item.iconClass ? item.iconClass : 'fas fa-'}></i>
                <span className="">{item.title}</span>
              </DropdownItem>
            </>
          );
          })
        }
      </DropdownMenu>
    </UncontrolledDropdown>
  )
};

export default OverflowMenuDropdown;
