import { createContext } from "react";

export interface Account {
  id?: string;
  token?: string;
  parentAccount?: string;
  name?: string;
  locale?: string;
  timeZoneCode?: string;
  status?: string;
  statusDescription?: string;
  statusUpdateDate?: string;
  permissions?: string[];
  namespaces?: string[];
  admin?: boolean;
  loggedIn?: boolean;
  updateAccount?: (account: Account) => void;
}

const AccountContext = createContext({
  loggedIn: false,
  updateAccount: () => {}
} as Account);

export default AccountContext;
