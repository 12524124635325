import React from "react";
import "./BottomNavigation.css";
import {useHistory} from "react-router";
import { t } from "@lingui/macro";

const BottomNavigation = () => {

  const bottomMenuList = [
    { title: "Apps", link: "/apps", icon: "fas fa-th-large" },
    { title: t`Tasks`, link: "/tasks", icon: "fas fa-list" }
  ];
  const history = useHistory();
  return (
    <div className="bottom-navigation">
      <ul>
        {bottomMenuList.map((item, i) => {
          return (
            <li className={"nav-item" + (history.location.pathname.startsWith(item.link) ? ' active' : '') } onClick={()=> history.push(item.link)} key={i}>
              <i className={item.icon}/>
              <label>{item.title}</label>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default BottomNavigation;
