import React, {useCallback, useEffect, useState} from "react";
import {Button, Input} from "reactstrap";
import "./ArrayEditor.scss";
import ArrayEditorItem from "./ArrayEditorItem";

const ArrayEditor = ({ value: valueProp, onChange, maxHeight, required, disabled, valueType, editorComponent, rendererComponent, onCreateNewValue, itemHeight = 31 }) => {
  const [newValue, setNewValue] = useState((onCreateNewValue && onCreateNewValue()) || (valueType === 'number' ? 0 : ''));
  const resetNewValue = () => setNewValue((onCreateNewValue && onCreateNewValue()) || (valueType === 'number' ? 0 : ''));
  useEffect(resetNewValue, [onCreateNewValue, valueType]);
  const newValueChange = useCallback(e => {
    const v = e.target.value;
    setNewValue(valueType === 'number' ? Number(v) : v);
  }, [valueType]);
  const [value, setValue] = useState([]);
  useEffect(() => {
    setValue(valueProp && Array.isArray(valueProp) ? valueProp : []);
  }, [valueProp]);

  const moveUp = useCallback(index => {
    let newArray = [...value];
    const elem = newArray[index - 1];
    newArray[index - 1] = newArray[index];
    newArray[index] = elem;
    onChange(newArray);
  }, [value, onChange]);
  const moveDown = useCallback(index => {
    let newArray = [...value];
    const elem = newArray[index + 1];
    newArray[index + 1] = newArray[index];
    newArray[index] = elem;
    onChange(newArray);
  }, [value, onChange]);
  const deleteCallback = useCallback(index => {
    const newArray = [...value];
    newArray.splice(index, 1);
    onChange(newArray);
  }, [value, onChange]);
  const edit = useCallback((index, v) => {
    const newArray = [...value];
    newArray[index] = v;
    onChange(newArray);
  }, [value, onChange]);

  const onAdd = useCallback(() => {
    const newArray = [...value, newValue];
    onChange(newArray);
    resetNewValue();
  }, [value, onChange, newValue, onCreateNewValue, valueType]);

  const onEnterKey = useCallback(e => {
    if (e.keyCode === 13) {
      onAdd();
      e.preventDefault();
      return false;
    }
  }, [onAdd]);

  const EditorComponent = editorComponent;
  const editorComponentProps = {
    value: newValue,
    onChange: setNewValue,
    onKeyDown: onEnterKey,
    disabled: disabled
  };

  return (
    <div className="array-editor">
      <div className="array-editor-input">
        {EditorComponent && <EditorComponent {...editorComponentProps} />}
        {!EditorComponent && <Input {...editorComponentProps} type={valueType || 'text'} onChange={newValueChange} />}
        <Button color="secondary" onClick={onAdd} disabled={disabled}><i className="fas fa-plus" /></Button>
      </div>
      <div className="array-editor-list" style={{ maxHeight: maxHeight ? `${maxHeight * itemHeight + 15}px` : 'inherit'}}>
        {value && Array.isArray(value) && value.map((v, i) => (
          <ArrayEditorItem key={i} value={v} index={i} totalCount={value.length}
                           onMoveUp={moveUp} onMoveDown={moveDown} valueType={valueType}
                           onDelete={deleteCallback} onEdit={edit} disabled={disabled}
                           editorComponent={editorComponent} rendererComponent={rendererComponent} />
        ))}
      </div>
    </div>
  );
};

export default ArrayEditor;
