import React, {useReducer} from "react";
import ZoneEditor from "../../components/ZoneEditor/ZoneEditor";
import {extractPropsFromDataset} from "./propsHelpers";
import {Utils} from "../../components/Geoman";

const zoneEditor = (node, index, children, { zoneResources }) => {
  return <ZoneEditor resources={zoneResources} />;
};

const isValid = arg => arg && arg.trim() !== '';

export const zoneResourceExtractor = (node, { updateZoneResources,artifactReferences = null }) => {
  const { attribs } = node;
  const props = extractPropsFromDataset(attribs);
  const { resourceName, query, geometryKey } = props;
  if(props.formId && artifactReferences && artifactReferences[props.formId]) {
    const formId = artifactReferences[props.formId].artifactId
    if(formId)
      props.formId = formId
  }
  if (isValid(resourceName) && isValid(query) && isValid(geometryKey)) {
    const id = attribs.id;
    const resource = { ...props, id };
    if (isValid(props.drawOptions)) {
      resource.drawOptions = props.drawOptions.split(',').map(o => o.trim());
    } else {
      resource.drawOptions = Object.keys(Utils.DRAW_OPTIONS); // if not specified, allow all drawings
    }
    if(isValid(props.detailFields)){
      let temp = props.detailFields.split(',').map(o => o.trim());
      resource.detailFields = temp.map(d =>{
        let parts = d.split(':');
        return parts.length > 1?{[parts[0]]:[parts[1]]}:{[parts[0]]:[parts[0]]}
      })
    }
    if(isValid(props.filterOnFields)){
      let temp = props.filterOnFields.split(',').map(o => o.trim());
      resource.filterOnFields = temp.map(d =>{
        let parts = d.split(':');
        return parts.length > 1?{[parts[0]]:[parts[1]]}:{[parts[0]]:[parts[0]]}
      })
    }
    updateZoneResources(resource);
  }
};

const zoneResourcesReducer = (zoneResources, zoneResource) => {
  if (zoneResource.id === undefined || !zoneResources.some(z => z.id === zoneResource.id)) {
    return [...zoneResources, zoneResource];
  }
  return zoneResources;
};

export const useZoneResources = () => {
  const [resources, updateResources] = useReducer(zoneResourcesReducer, [], undefined);
  return [resources, updateResources];
};

export default zoneEditor;
