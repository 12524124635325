import { call, query } from "./calls";

export const FormAction = {
    getByTaskId: (taskId) => query(`select * from FormAction where id_task = '${taskId}'`),
    getTriggeredByFormId: (formId) => query(`select * from FormAction where id_form = ${formId} and isNotNull(triggerName) order by name`), // TODO: prepared query [formId]),
    create: (formAction) => call("/FormAction", "POST", formAction),
    update: (formAction) => call(`/FormAction/${formAction.id}`, "PUT", formAction),
    delete: (id) => call(`/FormAction/${id}`, "DELETE"),
    get: (id) => query(`select * from FormAction where id = ${id}`),
    list: () => query("select * from FormAction"),
    searchTriggered: (q) => query(`select * from FormAction where isNotNull(triggerName) and isNotNull(name) and isNotNull(id_form) and name like '${q}'`), // TODO: prepared query [q])
};