import { call, query } from "./calls";

export const Product = {
    list: () => query("SELECT * FROM Product"),
    getId: (productId) => query(`SELECT * FROM Product WHERE id=${productId}`),
    getByIds: (productsId) => query(`SELECT name, id FROM Product WHERE id IN (${productsId})`),
    create: (values) => call("/Product", "POST", values),
    update: (productId, values) => call(`/Product/${productId}`, "PUT", values),
    delete: (productId) => call(`/Product/${productId}`, "DELETE"),
    search: (q) => query(`SELECT name, id FROM Product WHERE name like '${q}'`)
};