
import React, { createContext, useCallback, useReducer } from "react";
import useFileChooser from '../components/FileChooser/hooks';

export const FileUploadContext = createContext({
  fileData: {},
  isUploading: false,
  isSaving: false
});

const filesReducer = (fileFields, action) => {
  const uploadedFile = action.payload;
  const existing = fileFields[uploadedFile.name] || {};

  switch (action.action) {
    case 'upload':
      return {
        ...fileFields,
        [uploadedFile.name]: {
          fileName: uploadedFile.value.name,
          fileId: uploadedFile.value.fileId,
          saved: false,
          error: undefined
        }
      };
    case 'save':
      return {
        ...fileFields,
        [uploadedFile.name]: { ...existing, saved: true, error: undefined }
      };
    case 'error':
      return {
        ...fileFields,
        [uploadedFile.name]: { ...existing, error: uploadedFile.value }
      }
    default:
      return fileFields;
  }
};

export const FileUploadProvider = ({ children }) => {
  const [fileData, updateFile] = useReducer(filesReducer, {});

  const filesCallback = useCallback((fieldName, event, value) => {
    if (event === 'upload' || event === 'save' || event === 'error') {
      updateFile({
        action: event,
        payload: {
          name: fieldName,
          value: value
        }
      });
    }
  }, [updateFile]);

  const { isUploading, isSaving, uploadFile, saveFile } = useFileChooser(filesCallback);

  return (
    <FileUploadContext.Provider value={{ fileData, isUploading, isSaving, uploadFile, saveFile }}>
      {children}
    </FileUploadContext.Provider>
  )
}

/**
 * 
 * @param prefix string 
 * @param name string 
 * @description verifying if it contains a prefix, if it contains it it is a child, therefore it places a # at the beginning
 * @returns #fileKeyPrefix-fieldName || fieldName.
 */
export const getFileDataKey = (prefix, name) => {
  return prefix ? `#${prefix}-${name}` : name;
}

/**
 * 
 * @param prefix string 
 * @param prefixedName string 
 * @description extract the name of the field clean
 * @returns fieldName.
 */
export const getFieldName = (prefix, prefixedName) => {
  return prefix ? prefixedName.substring(`#${prefix}-`.length) : prefixedName;
}

/**
 * 
 * @param prefix string 
 * @param name string 
 * @description returns the key prefix
 * @returns fileKeyPrefix-fieldName || fieldName.
 */
export const getFileKeyPrefix = (prefix, name) => {
  return prefix ? `${prefix}-${name}` : name;
}
