import { call, query } from "../calls";

export const TeamManager = {
  list: () => query(`SELECT *,teamName as title FROM Team`),
  create: (values) => call("/Team", "POST", values),
  getId: (teamId) => query(`SELECT * FROM Team WHERE id=${teamId}`),
  search: (q) => query(`SELECT id, teamName as name FROM Team WHERE teamName like '${q}'`),
  update: (teamId, values) => call(`/Team/${teamId}`, "PUT", values),
  delete: (teamId) => call(`/Team/${teamId}`, "DELETE"),
};
