import {useEffect, useState} from "react";
import {Api} from "../api";

const useFormLoad = (formId) => {
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState();
  const [error, setError] = useState();
  useEffect(() => {
    setLoading(true);
    Api.Form.get(formId)
      .then(form => {
        setLoading(false);
        setForm(form);
        setError(undefined);
      })
      .catch(err => {
        setLoading(false);
        console.error(err);
        setError(err.message);
      });
  }, [formId]);

  return { loading, form, error };
};

export default useFormLoad;
