import {getAccountId, getNowQuery, getTimeZoneCode} from "../account/accountUtils";
// import jexl from "jexl";
/*
  Expression replacement example

  Mapping:
    {model.array.[index].name} !== undefined

  1. resolve sub keys (between [])
   => index = 2
   => final key: {model.array.2.name}

  2. resolve keys
   => model.array.2.name = "Juan Pablo"

  3. generate local variable for resolved value
   => new variable: model_array_2_name
   => ctx[model_array_2_name] = "Juan Pablo"

  4. replace original text with new local variable
   => this.localContext.model_array_2_name

  5. execute expression
   => return this.localContext.model_array_2_name !== undefined


  Syntax examples:

  {model.array.3.attribute} - support for indices
  {model.object.[dynamicAttribute]} - support for dynamic attribute names
  {model.array.[dynamicIndex].attribute} - support for dynamic index
  {model.array.[dynamicIndex].[dynamicAttribute]} - both can be combined
 */

export class ConditionEvalSafe {
  constructor(context) {
    this.context = context;
  }

  run(str) {
    const ctx = {};
    str = str.replace(/{([\w.[\]]*?)}/g, (substring, variableName) => {
      if (variableName.includes('[')) { // using [key] inside expression
        variableName = variableName.replace(/\[([\w.[\]]*?)]/g, (substring, subKey) => resolvePath(this.context, subKey));
      }

      const resolvedValue = resolvePath(this.context, variableName);
      const localVariableName = variableName.split('.').join('_');
      ctx[localVariableName] = resolvedValue;
      // return localVariableName;
      return `this.localContext.${localVariableName}`;
    });
    // eslint-disable-next-line no-new-func
    let evalFunction = new Function("return " + str);
    try {
      this.localContext = ctx;

      return evalFunction.call(this);
    } catch (err) {
      console.log('error evaluating condition', str, err.message);
      return undefined;
    }
    // try {
    //   return jexl.evalSync(str,ctx)
    // } catch (err) {
    //   console.log('error evaluating condition', str, err.message);
    //   return undefined;
    // }
  }
}

export const resolvePath = (model, path) => {
  return path.split('.').reduce((a, b) => {
    const n = parseInt(b);
    return (a || {})[isNaN(n) ? b : n]
  }, model);
}

export const resolveValue = (model, expression) => new ConditionEvalSafe(model).run(expression);

export const replaceTextVariables = (s, values) => {
  return s.replace(/{([\w.]*)}/g, (_m, key) => {
    if (key === "_accountId") {
      return getAccountId() || "";
    }
    if (key === "_timeZoneCode") {
      try {
        return getTimeZoneCode() || "";
      } catch (err) {
        return "";
      }
    }
    if (key === "_now") {
      try {
        return getNowQuery() || "";
      } catch (err) {
        return "";
      }
    }

    const value = resolvePath(values, key);
    return value !== undefined ? value : '';
  });
};
