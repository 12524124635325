import React, {useEffect, useState} from "react";
import {Input} from "reactstrap";
import {Field} from "formik";
import EditableMap from "../components/editable-map/EditableMap";
import deepEqual from "deep-equal";

const FormMap = (props) => {
    const {setFieldValue, variableName, value} = props;
    const [mapData, setMapData] = useState();
    const [currentValue, setCurrentValue] = useState();

    useEffect(() => {
        setCurrentValue(current => {
            if(!deepEqual(current, value)){
                return value;
            }
            return current;
        })
    }, [value]);

    useEffect(() => setMapData(currentValue), [currentValue]);

    return (
        <React.Fragment>
            <EditableMap {...props} height={500} value={mapData} extendedInfo onChange={data => {
                setMapData(data);
                setFieldValue(variableName, data);
            }}/>
            <Input type="hidden" name={variableName} tag={Field}/>
        </React.Fragment>
    );
};

export default FormMap;
