import {Api} from "./api";
import {getCorporateVariables} from "./corporateVariables";

export const getGoogleMapsKey = () => {
  let key = getCorporateVariables()[Api.EnvironmentVariables.GOOGLE_MAPS_API_KEY];
  return key || 'NO_GOOGLE_MAPS_KEY';
}

export const getMapboxAccessToken = () => {
  let key = getCorporateVariables()[Api.EnvironmentVariables.MAPBOX_ACCESS_TOKEN];
  return key || `NO_MAPBOX_TOKEN`;
};
