import React from "react";
import ArrayUIRenderer from "../../components/ui-renderer/ArrayUIRenderer";
import {extractPropsFromDataset} from "./propsHelpers";
import htmlparser2 from "htmlparser2";

export const arrayUIRendererLayer = (node, index, children, { resolveValue, model }) => {
  const { attribs } = node;
  let variable = attribs['data-model-variable'];
  if (!variable) return null;

  delete attribs['data-model-variable'];
  if (!variable.startsWith('{')) variable = '{' + variable;
  if (variable.indexOf('}') === -1) variable = variable + '}';

  const data = resolveValue(model, variable);

  let html = null;
  try {
    html = htmlparser2.DomUtils.getOuterHTML(node);
  } catch (e) {
    console.error(e);
  }

  const dataProps = extractPropsFromDataset(attribs);

  return <ArrayUIRenderer {...dataProps} row={dataProps.row !== undefined} data={data} resolverFunction={() => html} />;
};
