import React from "react";
import {Link} from "react-router-dom";
import {Api} from "../api";
import {Badge} from "reactstrap";
import {posix} from "path";
import ItemDropdown from "./newLayout/components/dropdownElements/ItemDropdown";

interface MainItemProps {
  queries?: string[],
  link: string,
  title:string,
  icon: string,
  iconColor?: string,
  refreshPeriod?:number,
  alert?: JSX.Element 
}

class MainItem extends React.Component<MainItemProps, any> {

  state = {
    bubbleData: [{}],
    showBubble: false
  };

  bubbleData = [{}];

  componentDidMount(): void {
    if(this.props.queries != null && this.props.queries.length > 0) {
      this.setUpInterval();
    }
  }

  setUpInterval() {
    const period = Math.max(3, this.props.refreshPeriod!);
    const queryTimer = setInterval(this.executeQuery.bind(this), period! * 1000);
    this.setState({queryTimer});
    this.executeQuery();
  }

  executeQuery() {
    if(this.props.queries != null) {
      this.bubbleData = [];
      for(let query of this.props.queries) {
        Api.query(query)
          .then(res => {
            for(let row of res) {
              this.bubbleData.push({name:row.name, value:row.value as any, color: row.color[0] as any});
            }
            this.setState({bubbleData: this.bubbleData, showBubble: true});
          }).catch(error => this.setState({fetchError: error}));
      }
    }
  }

  getBubbles() {
      let bubbles;
      bubbles = this.state.bubbleData.map((value, index) => {
        return (<Badge className="alert-bubble" key={index} style={{backgroundColor: ((typeof ((value as any).color) === 'string' ) ? (value as any).color : 'var(--accentColor)')}}>{(value as any).value}</Badge>);
      });
      return bubbles;
  }

  render() {
    return (
      <Link to={this.props.link}>
        <div className="mainitem" style={{position:'relative'}} title={this.props.title}>
          <i className={this.props.icon} style={{color:this.props.iconColor ? this.props.iconColor : ""}} ></i>
          <span className="item-text d-flex align-items-center">
            {this.props.title}
            {this.props.alert && this.props.alert}
          </span>
          {this.state.showBubble &&
          this.getBubbles()}
        </div>
      </Link>
    );
  }
}

export default MainItem;
