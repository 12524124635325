import React, {memo,useCallback} from "react";
import ItemResource from "./ItemResource";

const ResourceList = ({data,onSelectResource,resourcesVisibility,onSelectResourceToAddFeature,resourceToAddFeature,onSelectDisplayZones,maxHeight}) => {

  const SelectResourceToAddFeatureHandle = useCallback((item) => {
    if(resourceToAddFeature !== null && resourceToAddFeature.id === item.id){
        onSelectResourceToAddFeature(null)
    }else{
      onSelectResourceToAddFeature(item)
      onSelectResource(true,item)
    }
  },[resourceToAddFeature,onSelectResourceToAddFeature,onSelectResource])

  return <div className="list-group flex-grow-1" style={{ maxHeight: maxHeight - 150, overflow: 'auto'}}>
    {
      data.map((item,index) => {
        let active = (resourceToAddFeature !== null && item.id === resourceToAddFeature.id)
        return <ItemResource
          key={index}
          active={active}
          color={item.fillColor}
          selected={resourcesVisibility[index]}
          layerTitle={item.layerTitle}
          onSelectItem={checked =>onSelectResource(checked,item,index)}
          onSelectResourceToAddFeature ={() => SelectResourceToAddFeatureHandle(item)}
          onSelectDisplayZones = {() => onSelectDisplayZones(index,item)}
        />
      } )
    }
    </div>
}

export default memo(ResourceList)
