import React, { useEffect } from 'react'
import logo from '../img/sitrack_color.svg';
import './Login.css';
import {i18n} from "@lingui/core";
import {I18nProvider} from "@lingui/react";
import {defaultLocale, dynamicActivate} from "../i18n";
import { detect, fromNavigator } from "@lingui/detect-locale";

const TemplateLogin = ({children, title}) => {

  useEffect(() => {
    let locale = detect(fromNavigator(), () => defaultLocale);
    const sep = locale.indexOf('-');
    if (sep > -1) locale = locale.substring(0, sep);
    // noinspection JSIgnoredPromiseFromCall
    dynamicActivate(locale);
  }, []);

  return (
    <I18nProvider i18n={i18n}>
      <div className="login">
        <div className="d-none d-md-block" style={{ flex: 1 }} />
        <div className="loginform-container">
          <div className="loginform">
            <img src={logo} alt="Sitrack" className="login-logo" />
            <div className="d-none d-md-block">
              <h4 className="welcome">{title}</h4>
            </div>
            {children}
          </div>
        </div>
      </div>
    </I18nProvider>
  )
}

export default TemplateLogin;