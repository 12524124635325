import React, { useCallback, useEffect, useState } from 'react';
import { Api } from '../../api';

let userPreferencesContext = React.createContext();
let { Provider, Consumer } = userPreferencesContext;
const USER_PREFERENCES = "userPreferences";
const USER_TOKEN = "token";

const UserPreferencesProvider = ({ children }) => {
   const token = localStorage.getItem(USER_TOKEN);

    const savePreferences = useCallback((prefs) => {
        let newPrefs = { ...preferences.preferences, ...prefs};
        localStorage.setItem(USER_PREFERENCES, JSON.stringify(newPrefs));
        Api.UserPreferences.save(prefs);
        setPreferences(prevState => ({ ...prevState, preferences: newPrefs }));
    }, []);

    // load prefs from backend
    useEffect(() => {
        if (token && !localStorage.getItem(USER_PREFERENCES)) {
            Api.UserPreferences.get().then(res => {
                setPreferences(prevState => ({ ...prevState, preferences: res[0] }));
                localStorage.setItem(USER_PREFERENCES, JSON.stringify(res[0]));
            }).catch(err => console.log(err));
        }
    }, []);

    const userPreferences = {
        preferences: (localStorage.getItem(USER_PREFERENCES) && JSON.parse(localStorage.getItem(USER_PREFERENCES))) || {},
        savePreferences
    };

    const [preferences, setPreferences] = useState(userPreferences);

    return (
        <Provider value={preferences}>
            {children}
        </Provider>
    );
}

export { UserPreferencesProvider, Consumer as UserPreferencesConsumer, userPreferencesContext };
