import { call, query } from "./calls";

export const Metadata = {
    get: (id) => call(`/Metadata/${id}`),
    getByTypeName: (type, name) => query(`select id, name, fields, id as value, name as label from Metadata where type = '${type}' and name = '${name}'`),
    searchByTypeName: (type, name) => query(`select id, name, fields, id as value, name as label from Metadata where type = '${type}' and name like '${name}'`),
    searchByType: (type) => query(`select id, name, fields, jsonschema, id as value, name as label from Metadata where type = '${type}'`),
    search: (q) => query(`select id, name, fields, id as value, name as label from Metadata where type = 'Resource' and name like '${q}'`),// TODO: prepared query [q]),
    searchActions: (q) => query(`select id, name, fields, jsonschema, id as value, label, helpText from Metadata where type = 'Action' and name like '${q}'`),// TODO: prepared query [q])
    listQueryMetadata: () => query(`select name, helpText, type, fields from Metadata where type = 'Resource' OR type = 'QueryFunction' order by name`),
    listByType: (type) => query(`select name from Metadata where type = '${type}' order by name`),
    getByType: (type) => query(`select * from Metadata where type = '${type}' order by name`),
    getAllStrategies: () => query(`select id, name, fields, jsonschema, id as value, name as label from Metadata where type like 'Storage'`),// TODO: prepared query [q])
    strategyByName: (name) => query(`select id, name, fields, jsonschema, id as value, name as label from Metadata where type like 'Storage' and name = '${name}'`),
    getPresentationLayer: () => query(`select * from Metadata where type = 'PresentationLayer' and jsonschema.properties != null`)
};
