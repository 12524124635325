import React, {useCallback, useMemo} from "react";
import {Field, Form, Formik} from "formik";
import {Button, Col, FormFeedback, FormGroup, Input, Label, Row, UncontrolledAlert} from "reactstrap";
import {Api} from "../../../api";
import { t, Trans } from "@lingui/macro";

const BasicCredentialsEditor = ({ credentials }) => {
  const onSubmit = useCallback((values, { setSubmitting, setFieldError, setStatus, resetForm }) => {
    const { currentPassword, newPassword, confirm } = values;
    if (newPassword !== confirm) {
      setFieldError('confirm', t`Password don't match`);
      setSubmitting(false);
      return;
    }

    Api.Credentials.update({
      ...credentials,
      activationCode: `'${credentials.activationCode}'`,
      password: newPassword,
      extraParams: { currentPassword }
    }).then(() => {
      resetForm();
      setStatus({ ok: t`The password was updated successfully.` });
      setSubmitting(false);
    }).catch(err => {
      setStatus({ error: err.message });
      setSubmitting(false);
    });
  }, []);

  const initialValues = useMemo(() => ({
    currentPassword: '',
    newPassword: '',
    confirm: ''
  }), []);

  return (
    <Formik onSubmit={onSubmit} initialValues={initialValues}>
      {({ values, isSubmitting , error, errors, status }) => (
        <Form>
          <FormGroup>
            <Label for="currentPassword"><Trans>Currect Password</Trans></Label>
            <Input type="password" required id="currentPassword" name="currentPassword"
                   invalid={errors.currentPassword !== undefined} tag={Field} />
            <FormFeedback>{errors.currentPassword}</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label for="newPassword"><Trans>New Password</Trans></Label>
            <Input type="password" required id="newPassword" name="newPassword"
                   invalid={errors.newPassword !== undefined} tag={Field} />
            <FormFeedback>{errors.newPassword}</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label for="confirm"><Trans>Confirm Password</Trans></Label>
            <Input type="password" required id="confirm" name="confirm"
                   invalid={errors.confirm !== undefined} tag={Field} />
            <FormFeedback>{errors.confirm}</FormFeedback>
          </FormGroup>
          {error && <UncontrolledAlert color="danger">{error.message}</UncontrolledAlert>}
          {status && status.error && <UncontrolledAlert color="danger">{status.error}</UncontrolledAlert>}
          {status && status.ok && <UncontrolledAlert color="success">{status.ok}</UncontrolledAlert>}
          <Row className="justify-content-end">
            <Col xs={4} md={2} className="text-right">
              <Button type="submit" color="primary" disabled={isSubmitting}><Trans>Save</Trans></Button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default BasicCredentialsEditor;
