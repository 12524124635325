import {query, queryPost} from "./calls";
import {Dashboard} from './dashboard';
import {DecisionMap} from './decisionMap';
import {Bpm} from './bpm';
import {Form} from './form';
import {Account} from './account';
import {Credentials} from './credentials';
import {Metadata} from './metadata';
import {FormAction} from './formAction';
import {UserPreferences} from './userPreferences';
import {Project} from './project';
import {Event} from './event';
import {packagedApp} from './app';
import {Query} from './query';
import {Crud} from './crud';
import {Link} from './link';
import {Issue} from './Issue';
import {Milestone} from './milestone';
import {Meeting} from './meeting';
import {StoreDynamic} from './storeDynamic';
import {Product} from './product';
import {AccountList} from './accountList';
import {EventFlow} from './eventFlow';
import {DynamicStoreRule} from './dynamicStoreRule';
import {DynamicGrant} from './dynamicGrant';
import {Role, RoleAssignation} from './role';
import {Sharing, ShareAccounts, ResourceSharingGrants} from './sharing';
import {Thing, CompleteThing, ParametersTemplates} from './thing';
import {Folder, FolderManager} from './folder';
import {Permissions} from './permissions';
import {WebSession} from './webSession';
import {File} from './file';
import {TeamManager} from './issueTracker/teamManager';
import {storage} from "./storage";
import {DynamicStoreMetadata} from "./dynamicStoreMetadata";
import {ForwardingQueue} from './forwardingQueue';
import {TinyUrl} from './tinyUrl';
import { CorporateHandShake } from "./corporateHandShake";
import {UserTask} from "./userTask";
import { MilestoneLog } from './issueTracker/milestoneLog';
import {PublicFile} from "./publicFile";
import {Branch} from './branch';
import {EnvironmentVariables} from "./environmentVariables";
import {IssueLifeCycle } from "./issueTracker/IssueLifeCycle";
import {Policy } from "./policy";
import {Apikey } from "./apikey"
import {PackagedApp} from "./packagedApp";
import {Widget } from "./widget";
import { PackagedAppVersion } from "./packagedAppVersion";
import { PackagedAppArtifacts } from "./packagedAppArtifacts";
import { Notification } from './notification';
import { BranchOffice } from './branchOffice';


export const Api = {
  query: (q: string, raw: boolean = false, encrypt: boolean = false) => query(q, raw, encrypt),
  queryPost,
  Dashboard,
  DecisionMap,
  Bpm,
  Form,
  Account,
  Credentials,
  Metadata,
  FormAction,
  UserPreferences,
  Project,
  Event,
  packagedApp,
  Query,
  Crud,
  Link,
  Issue,
  Branch,
  Milestone,
  MilestoneLog,
  Meeting,
  DynamicGrant,
  StoreDynamic,
  Product,
  AccountList,
  EventFlow,
  DynamicStoreRule,
  Role,
  RoleAssignation,
  Sharing,
  ShareAccounts,
  ResourceSharingGrants,
  Thing,
  CompleteThing,
  ParametersTemplates,
  Folder,
  FolderManager,
  Permissions,
  WebSession,
  File,
  TeamManager,
  storage,
  DynamicStoreMetadata,
  ForwardingQueue,
  TinyUrl,
  CorporateHandShake,
  UserTask,
  PublicFile,
  EnvironmentVariables,
  IssueLifeCycle,
  Policy,
  Apikey,
  PackagedApp,
  PackagedAppArtifacts,
  Widget,
  PackagedAppVersion,
  Notification,
  BranchOffice
};



