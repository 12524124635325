import {extractBooleanExpressionProps} from "./inputHelpers";
import {extractPropsFromDataset, extractValuesEvents} from "./propsHelpers";
import generatePropsFromAttributes from "../parser/utils/generatePropsFromAttributes";
import VoidElements from "../parser/dom/elements/VoidElements";
import React from "react";
import {replaceTextVariables} from "../conditions";

const custom = (layers, node, index, children, context) => {
  const { attribs } = node;
  const customComponent = attribs['data-custom-component'];
  if (customComponent) {
    delete attribs['data-custom-component'];
    const customLayer = layers[customComponent];
    if (customLayer) {
      return customLayer(node, index, children, context);
    } else if (!attribs['data-skip-generic-processing']) {
      // try generic processing
      const Component = layers.generic[customComponent];
      if (Component) {
        return <Component {...extractValuesEvents(attribs, context.onInternalEvent)}
                          {...extractBooleanExpressionProps(attribs, context)}
                          {...extractPropsFromDataset(attribs)}
                          {...generatePropsFromAttributes(attribs)}
                          model={context.model} values={context.values} setFieldValue={context.setFieldValue} />;
      }
    }
  }
};

const transformNodes = (nodes, layers, context) => {
  const nodesCopy = nodes.map(n => ({...n, attribs: {...n.attribs}}));
  return nodesCopy.map((n, i) => {
    if (n.type === 'tag') {
      let children = null;
      if (VoidElements.indexOf(n.name) === -1 && n.children) {
        children = transformNodes(n.children, layers, context);
      }

      // find appropriate layer
      for (const layerName in layers) {
        const layer = layers[layerName];
        if (Array.isArray(layer)) {
          const layerObj = layer.find(l => l.applies(n));
          if (layerObj) {
            return layerObj.converter(n, i, children, context);
          }
        } else if (layerName === n.name) {
          return layer(n, i, children, context);
        }
      }
      const customComponent = custom(layers, n, i, children, context);
      if (customComponent !== undefined) return customComponent;

      let visibleExpr;
      if (n.attribs['data-visible']) {
        visibleExpr = n.attribs['data-visible'];
        delete n.attribs['data-visible'];
      }

      const events = extractValuesEvents(n.attribs);
      const props = generatePropsFromAttributes(n.attribs, i);

      if (visibleExpr) {
        const visible = context.resolveValue(context.model, visibleExpr);
        if (visible === false)
          props.className = `${(props.className || '')} d-none`;
      }

      if(events){
        if (events.onClickEvents) props.onClick = () => context.onInternalEvent(events.onClickEvents, context.values);
        if(events.onHoverEvents) props.onMouseEnter = () => context.onInternalEvent(events.onHoverEvents, context.values);
      }

      return React.createElement(n.name, props, children);
    } else if (n.type === 'text') {
      return replaceTextVariables(n.data, context.model || {});
    }
    return n.data;
  });
};

export default transformNodes;
