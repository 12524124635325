import {t} from "@lingui/macro";

export const DefaultFoldersData = {
  'Favorites': {label:t`Favorites`,icon:'far fa-star'},
  'Shared':{label:t`Shared items`,icon:'fas fa-share-alt'},
  'shared':{label:t`Shared items`,icon:'fas fa-share-alt'},
  'Menu':{label:t`Menu`,icon:'fas fa-user-cog'},
  'dashboard':{label:t`Dashboard`,icon:'fas fa-chart-area'},
  'bpm':{label:t`BPMs`,icon:'fas fa-network-wired'},
  'form':{label:t`Forms`,icon:'far fa-file-alt'},
  'crud':{label:t`CRUDs`,icon:'far fa-file-code'},
  'query':{label:t`Queries`,icon:'fas fa-code'},
  'folders':{label:t`Folders`,icon:'fas fa-folder-open'},
};

export const getPathByResourceName = {
  'Dashboard':"dashboard",
  'ProcessLayout':"bpm",
  "File": "file"
};

export const defaultIconByResource = {
  'Dashboard':"chart-area",
  'ProcessLayout':"network-wired",
  "File": "file"
};
