import ActionContainer from "../ActionContainer";
import React from "react";

export const actionContainer = (node, index, children, { model, onKeyUpdated, queries, mappings, setFieldValue, onModelUpdated, submitDataObjects}) => {
  return (
    <ActionContainer key={`action-container-${index}`} onKeyUpdated={onKeyUpdated} setFieldValue={setFieldValue}
                     values={model} mappings={mappings} queries={queries} onModelUpdated={onModelUpdated} submitDataObjects={submitDataObjects} >
      {children}
    </ActionContainer>
  );
};

export const dataQuery = () => {
  return null;
};

export const dataMapping = () => {
  return null;
};