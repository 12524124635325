import React from 'react';
import "../../layout/newLayout/UserMenu.css";
import {Trans} from '@lingui/macro';

function list(files) {
  const label = (file) => `'size '${file.size}'`;
  return files.map((file) =>
    <li>
      <div className="file-type-container"><span className="circle"><div className="file-type-text">{getType(file.type)}</div></span>
        <div className="file-data">
          <div className="file-name">{file.name}</div>
          <div className="file-metadata"> {label(file)}</div>
        </div>
      </div>
    </li>
  );
}
const getType = (fileType)=> {
  return fileExtensionByType[fileType] || "file";
}
const fileExtensionByType = {
  "application/pdf": "PDF",
  "application/xls": "XLS",
  "application/doc": "DOC",
  "application/jpg": "JPG",
  "application/json": "JSON",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": "DOCX",
  "text/csv": "CSV",
  "text/plain": "TXT",
  "application/msword": "DOC",
  "application/java-archive": "JAVA",
  "image/jpeg": "JPEG"
};

export const FileList = ({ files }) => {
  return files.length === 0
    ? (<div className="file-list">
        <ul><li><Trans>No file was selected</Trans>.</li></ul>
      </div>)
    : (<div className="file-list"> <ul>{list(files)} </ul></div>);
};
