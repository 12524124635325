import React from "react";
import generatePropsFromAttributes from '../parser/utils/generatePropsFromAttributes';
import FileLink from "../../components/FileLink/FileLink";
import {extractPropsFromDataset} from "./propsHelpers";

export const link = (node, index, children,{ resolveValue, model } ) => {
  const { attribs } = node;

  let link = attribs["href"];
  delete attribs["href"];
  let { fileId } = extractPropsFromDataset(attribs)

  const propsFromAttr = generatePropsFromAttributes(attribs, index)
  let comp;
  if(fileId){
    if (fileId.startsWith('{') && fileId.includes('}')) fileId = resolveValue(model, fileId);
    comp = <FileLink fileId={fileId} {...propsFromAttr}>{children}</FileLink>
  }else{
    if (link.startsWith('{') && link.includes('}')) link = resolveValue(model, link);
    comp = <a {...propsFromAttr} href={link}>{children}</a>
  }
  return comp
}
