export const filterTag = (options) => {
  const allTags = [];
  options.forEach(p => p.tags && allTags.push(...p.tags));
  const tagSet = [...new Set(allTags)]
    .sort((a, b) => a.localeCompare(b, undefined, { sensitivity: 'base' }))
    .map(t => ({ label: t, value: t, type: 'tag' }));
  return tagSet;
};

export const createNewOption = (text) => ({ label: `"${text}"`, value: text, type: 'text' });

export const permissionFilterFunction = (option, filterValue) => {
  const tags = filterValue.filter(o => o.type === 'tag').map(o => o.value);
  if (tags.length > 0) {
    if (!option.tags || option.tags.length === 0) return false; // option has no tags
    if (!tags.every(t => option.tags.includes(t))) return false; // option does not have all tags
  }

  const texts = filterValue.filter(o => o.type === 'text').map(o => o.value);
  if (texts.length > 0) {
    if (option.label && texts.every(t => option.label.toUpperCase().includes(t.toUpperCase()))) return true;
    if (option.description && texts.every(t => option.description.toUpperCase().includes(t.toUpperCase()))) return true;
    if (option.name && texts.every(t => option.name.toUpperCase().includes(t.toUpperCase()))) return true;

    return false;
  } else return true;
};

export const policyFilterFunction = (option, filterValue) => {
  const texts = filterValue.filter(o => o.type === 'text').map(o => o.value);
  if (texts.length > 0) {
    if (option.label && texts.every(t => option.label.toUpperCase().includes(t.toUpperCase()))) return true;
    if (option.description && texts.every(t => option.description.toUpperCase().includes(t.toUpperCase()))) return true;
    if (option.name && texts.every(t => option.name.toUpperCase().includes(t.toUpperCase()))) return true;

    return false;
  } else return true;
};