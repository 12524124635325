import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { withRouter } from "react-router";
import MainItem from "./MainItem";
import { Api } from "../api";
import { PermissionManager } from '../api/permissions';
import CreateShortcutModal from "./newLayout/components/CreateShortcutModal";
import ItemDropdown from "./newLayout/components/dropdownElements/ItemDropdown";
import { Trans, t } from "@lingui/macro";
import Shortcut from './Shortcut';
import { Badge } from 'reactstrap';
import FloatMenu from './floatMenu/FloatMenu';
import { getAccountId } from '../account/accountUtils';


const actionsDefault = [{ id: "id1", name: "edit", title: t`Edit`, link: "#", icon: "far fa-edit" },
{ id: "id2", name: "delete", title: t`Remove`, link: "#", icon: "far fa-trash-alt" }];

const MainMenu = (props) => {
  const { updateMenu, alerts } = props;

  const [linkList, setLinkList] = useState([]);
  const [folderList, setFolderList] = useState([]);

  const [openShorcut, setOpenShorcut] = useState(false);
  const [dataEditShorcut, setDataEditShorcut] = useState(false);

  const [folders, setFolders] = useState();
  const [links, setLinks] = useState();
  const [foldersOwner, setFoldersOwner] = useState();

  const [showFloatMenu, setShowFloatMenu] = useState(false);
  const [dataFloatMenu, setDataFlowMenu] = useState();
  const [persistMenu, setPersistMenu] = useState(false);

  const getLinkChildren = (folder) => {
    if (folder.children) {
      let linkList = folder.children.Link ? folder.children.Link : [];
      if (linkList.length > 0) {
        return Api.Link.listByFolderId(folder.id)
      }
    }
    return [];
  };

  const getFolderChildren = (folderOwner) => {
    if (folderOwner.children) {
      let folderList = folderOwner.children.Folder ? folderOwner.children.Folder : [];
      if (folderList.length > 0) {
        return Api.Folder.list(folderList)
      }
    }
    return [];
  };

  const setFolderLinksByOwner = async (foldersOwner, links = [], folders = []) => {
    let copyFolders = [...folders];
    let copyLinks = [...links];

    for (let folderOwner of foldersOwner) {
      const foldersChildren = await Promise.all([getFolderChildren(folderOwner), getLinkChildren(folderOwner)]);
      copyLinks = [...copyLinks, ...foldersChildren[1]]
      foldersChildren[0].forEach((folder) => {
        let index = copyFolders.findIndex((f) => f.name === folder.name)
        if (index !== -1) {
          let extraData = copyFolders[index].extraData || []
          extraData.push(folder.id)
          copyFolders[index] = { ...copyFolders[index], extraData: extraData }
        } else {
          copyFolders.push(folder)
        }
      })
    }

    return { newFolders: copyFolders, newLinks: copyLinks };
  }

  const getLinksByFolderIds = async (foldersId = []) => {
    let links = []
    let folders = foldersId;

    try {
      let folder;
      // eslint-disable-next-line no-cond-assign
      while (folder = folders.shift()) {
        let result = await Api.Folder.list([folder])
        // eslint-disable-next-line no-loop-func
        result.forEach((f) => {
          const { children } = f
          folders = children.Folder ? [...folders, ...children.Folder] : folders
          links = children.Link ? [...links, children.Link] : links
        })
      }
      return links.length > 0 ? Api.Link.list(links) : null
    } catch (err) {
      // this.setState({error:{msg:t`Error getting links`}})
      throw new Error(t`Error getting links`)
    }
  }

  const createMenuLinksByFolder = async () => {
    let { newLinks, newFolders } = await setFolderLinksByOwner(foldersOwner, links, folders);
    const folderPromises = []

    for (let folder of newFolders) {
      let temp = getLinksByFolderIds(folder.extraData ? [...folder.extraData, folder.id] : [folder.id])
      if (temp !== null) {
        folderPromises.push(temp)
      }
    }

    Promise.all(folderPromises)
      .then(res => {
        res.forEach((menuLinks, index) => {
          if (res[index] !== null) {
            newFolders[index].menuLinks = menuLinks
          }
        })
      })
      .catch(alert)
      .finally(() => {
        setLinkList(newLinks);
        setFolderList(newFolders);
      })
  }

  const getMenuFolder = () => {
    Api.Folder.getHome().then(res => {
      if (res && res.length > 0) {
        Promise.all([Api.Folder.list(res[0].children.Folder), Api.Folder.listByOwner(getAccountId())]).then(res => {
          res[0].forEach((folder) => {
            if (folder.name === 'Menu') {
              if (folder.id) localStorage.setItem("menuFolder", JSON.stringify(folder));
              Promise.all([getLinkChildren(folder), getFolderChildren(folder)]).then(prom => {
                setLinks(prom[0]);
                setFolders(prom[1]);
                setFoldersOwner(res[1]);
              });
            }
          });
        });

      }
    });
  };

  useEffect(()=> {if(updateMenu) getMenuFolder()}, [updateMenu]);

  useEffect(() => {
    if (links && folders && foldersOwner) {
      createMenuLinksByFolder()
    }
  }, [links, folders, foldersOwner]);

  const onContextAction = useCallback((itemId, actionName, item) => {
    switch (actionName) {
      case "edit": {
        setOpenShorcut(true);
        setDataEditShorcut(item);
        break;
      }
      case "delete": {
        let confirmDelete = window.confirm(t`Are you sure you want to delete the item?`);

        if (confirmDelete) {
          Api.Link.delete(itemId).then(res => { getMenuFolder(); }).catch(e => { alert(t`Failed to delete an item` + " : " + e); });
        }
        break;
      }
      default:
    }
  }, [getMenuFolder]);

  const list = useMemo(() => ({
    "list_1": [
      { name: "home", title: t`Home`, permission: "menu_home", link: "/home", icon: "fas fa-home" },
      { name: "apps", title: t`Apps`, permission: "menu_apps", link: "/apps", icon: "fas fa-th-large" },
      { name: "shared", title: t`Shared`, permission: "menu_shared", link: "/shared", icon: "fas fa-share-alt" },
      { name: "folders", title: t`Navigator`, permission: "menu_navigator", link: "/folders", icon: "fas fa-ship" },
      {
        name: "task", title: t`Task`, permission: "menu_tasks", link: "/tasks", icon: "fas fa-list",
        queries: ["SELECT new('value') as v, count(id) as value, new('var(--accentColor)') as color FROM PendingUserTasks GROUP BY v"],
        refreshPeriod: 20
      },
      {
        name: "notifications",
        title: t`Notifications`,
        permission: "public",
        link: "/notifications",
        icon: "fas fa-bell",
        alert: <AlertNotification active={alerts["notification"]} />
      },
    ],
    "list_2": [
      { name: "processes", title: t`Processes`, permission: "menu_processes", link: "/processes", icon: "fas fa-cogs" },
      { name: "rocket", title: t`Actions`, permission: "menu_launch", link: "/launch", icon: "fas fa-rocket" },
      { name: "admin", title: t`Administration`, permission: "menu_admin", link: "/admin", icon: "fas fa-users" },
      { name: "query", title: t`Query Runner`, permission: "menu_query", link: "/query", icon: "fas fa-code" },
      { name: "issue-tracker", title: t`Issue Tracker`, permission: "menu_issueTracker", link: "/issueTracker", icon: "fas fa-list-alt" },
      { name: "packaged-app", title: t`Packaged App`, permission: 'public', link: "/packagedApp", icon: "fas fa-cubes" }
    ]
  }), [alerts]);

  const onFolderLinkMouseEnter = useCallback((event, menuLink) => {
    event.preventDefault();
    event.stopPropagation();
    setShowFloatMenu(true);
    setDataFlowMenu({ menuLink, pageY: event.pageY });
  }, []);

  const onFolderLinkMouseLeave = useCallback(() => {
    if(!persistMenu){
      setShowFloatMenu(false);
      setDataFlowMenu();
    }
  }, [persistMenu]);

  useEffect(() => {
    if(!persistMenu){
      setShowFloatMenu(false);
      setDataFlowMenu();
    }
  }, [persistMenu]);

  return (
    <div id="list-container" className="list-container scrollbar scrollbar-near-moon thin">
      <ul key={"list_1"} className="side-list">
        {list["list_1"].map((l, index) => {
          if (PermissionManager.types[l.permission]) {
            return (
              <li key={`${l.name}-${index}`} className={props.history.location.pathname.startsWith(l.link) ? 'active' : ''}>
                <MainItem {...l} />
              </li>
            )
          }
          return null;
        })}
      </ul>
      <div className="divider">
        <hr />
      </div>
      <ul key={"list-2"} className="side-list">
        {list["list_2"].map((l, index) => {
          if (PermissionManager.types[l.permission]) {
            return (
              <li key={`${l.name}-${index}`} className={props.history.location.pathname.startsWith(l.link) ? 'active' : ''}>
                <MainItem {...l} />
              </li>
            )
          }
          return null;
        })}
      </ul>
      <div className="divider">
        <hr />
      </div>

      <ul key={"list-3"} className="side-list">
        <li key="access-1-key" onClick={(e) => { setOpenShorcut(true) }}>
          <span className="link" style={{ marginLeft: 7 }}>
            <div className="mainitem" style={{ position: 'relative' }}>
              <i className="fas fa-tag"></i>
              <span className="item-text"><Trans>Create Shortcut</Trans></span>
            </div>
          </span>
        </li>
        {showFloatMenu &&
          <FloatMenu
            top={dataFloatMenu.pageY}
            items={dataFloatMenu.menuLink}
            getPath={getPath}
            getIconColorFromLinkElement={getIconColorFromLinkElement}
            onContextAction={onContextAction} id={props.match.params.id}
            onPersistMenu={setPersistMenu}
          />
        }
        <ItemCustom
          data={folderList}
          id={props.match.params.id}
          onFolderLinkMouseEnter={onFolderLinkMouseEnter}
          onFolderLinkMouseLeave={onFolderLinkMouseLeave}
        />

        <ItemCustom data={linkList} onContextAction={onContextAction} id={props.match.params.id} />

      </ul>
      {openShorcut &&
        <CreateShortcutModal
          isOpen={openShorcut}
          onDone={() => {
            setOpenShorcut(false);
            setDataEditShorcut();
            getMenuFolder();
          }}
          onClose={setOpenShorcut}
          item={dataEditShorcut}
          edit={dataEditShorcut} />
      }
    </div>
  );
}


const cleanIcon = (value) => {
  let parts = value.split(/(##[\w-#]*)+/g);
  for (var i = 1; i < parts.length; i += 2) { parts[i] = ""; }
  return parts.join("");
}

const getIconColorFromLinkElement = (linkElement) => {
  return linkElement.optionalParams ? (linkElement.optionalParams.iconColor ? linkElement.optionalParams.iconColor : "") : "";
};

const getPath = (resourceNameLinked, item) => {
  switch (resourceNameLinked) {
    case 'Dashboard': return `/dashboard/${item.resourceIdLinked}`
    case 'ProcessLayout': return `/bpm/${item.resourceIdLinked}`
    case 'Folder': return `/folders/${item.resourceIdLinked}`
    default: return '';
  }
};

const AlertNotification = ({ active }) => {
  if (active) {
    return (
      <div className='ml-2'>
        <Badge className="badge-circle" color="danger" />
      </div>
    );
  }

  return null;
}

const ItemCustom = ({ data, onFolderLinkMouseEnter, onFolderLinkMouseLeave, onContextAction, id }) => {
  const [activateToogle, setActivateToogle] = useState(false);

  return data.length > 0 ? data.map((item, index) => (
    <li style={{ overflow: "visible" }} key={index} className={id === item.id ? 'active' : ''}
      onMouseEnter={(event) => onFolderLinkMouseEnter && onFolderLinkMouseEnter(event, item.menuLinks)}
      onMouseLeave={onFolderLinkMouseLeave && onFolderLinkMouseLeave}
    >
      <ItemDropdown
        activateToogle={activateToogle} index={index} key={`drop-${item.id}`}
        customStyle={{ left: 20, width: 60 }}
        actionList={actionsDefault}
        onAction={onContextAction}
        item={item}
        onContext={setActivateToogle}
      >
        <Shortcut
          title={cleanIcon(item.name)}
          link={getPath(item.resourceNameLinked, item)}
          icon={item.icon ? `fas fa-${item.icon}` : ("fas fa-tag")}
          iconColor={getIconColorFromLinkElement(item)} />
      </ItemDropdown>
    </li>
  )) : null
}

export default withRouter(MainMenu);
