import { call, query } from "./calls";

export const Policy = {
    list: () => call("/Policy"),
    get: (id) =>  query(`SELECT id,name,description,permissions,enabled FROM Policy where id=${id}`),
    create: (values) => call("/Policy", "POST", values),
    update: (id, values) => call(`/Policy/${id}`, "PUT", values),
    remove: (id) => call(`/Policy/${id}`, "DELETE"),
    search: (q) => query(`SELECT id,name,permissions,enabled from Policy where name like '${q}'`),
};
