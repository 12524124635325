import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import google from '../img/google.png';
import SocialButton from "../social/SocialButton";
import {Redirect, Route, withRouter} from "react-router";
import AccountContext from "../account/account.context";
import {FormGroup, Input, Label, Spinner, Button} from "reactstrap";
import { Api } from '../api';
import {t, Trans} from "@lingui/macro";
import { getAccountId, getDecodedToken } from '../account/accountUtils';
import moment from "moment";
import { Link } from 'react-router-dom';
import TemplateLogin from './TemplateLogin';
import { loadCorporateVariables } from '../corporateVariables';
import { BASE_URL } from '../api/config';

const OAUTH_GOOGLE_ID = `${process.env.OAUTH_GOOGLE_ID || "483346621380-ss631jvrj99cu67ounp3fk268n4ht1lp.apps.googleusercontent.com"}`;

const Login = (props) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isBasicLogin, setBasicLogin] = useState(false);
  const [isGoogleLogin, setGoogleLogin] = useState(false);
  const urlLogin = `${BASE_URL}/edna/Session`;

  const usernameInput = useRef();

  const getSession = useCallback((token) => {
    let from = (props.location.state || {}).from;
    Api.WebSession.get(token).then(session => {
      localStorage.setItem('currentSession', JSON.stringify(session[0]) || '');

      Api.query("select dashboardId from Account")
        .then(res => {
          if(res[0].dashboardId){
            window.location.replace(`/dashboard/${res[0].dashboardId}`);
          }else{
            Api.query(`select dashboardId from Role where id in (select roles from Account where id = ${session[0].id}) and dashboardId != null`)
              .then(response => {
                if(response.length > 0 && response[0].dashboardId){
                  window.location.replace(`/dashboard/${response[0].dashboardId}`);
                }else{
                  window.location.replace((from !== '' && from) || '/apps')
                }
              })
          }
        })
       }).catch(err => window.location.replace((from !== '' && from) || '/apps'));
  }, [props.location.state]);

  useEffect(() => usernameInput && usernameInput.current && usernameInput.current.focus(), []);

  const account = useContext(AccountContext);

  const googleLoginSuccess = (user) => {
    setGoogleLogin(true);
    localStorage.setItem("avatar", user._profile.profilePicURL);
    localStorage.setItem("email", user._profile.email);
    localStorage.setItem("name", user._profile.name);
    fetch(urlLogin, {
      method: 'post',
      headers: { Authorization: `google ${user._token.idToken}`},
      body: '{}'
    }).then(res => res.json())
      .then(res => {
        localStorage.setItem("token", res.token);
        if (res.timeZoneCode)
          localStorage.setItem("timeZoneCode", res.timeZoneCode);
        else
          localStorage.removeItem("timeZoneCode");
        account.updateAccount && account.updateAccount({
          ...res,
          loggedIn: true
        });
        //console.log('login ok', res);

        // @ts-ignore
        let OneSignal = window.OneSignal || [];
        OneSignal.push(function() {
          console.log('running one signal provide user consent');
          OneSignal.provideUserConsent(true);
        });
        OneSignal.push(function() {
          console.log('running one signal set external user id', getAccountId());
          OneSignal.setExternalUserId(getAccountId());
        });

        loadCorporateVariables();
        // @ts-ignore
        getSession(res.token);
      })
      .catch(err => {
        setGoogleLogin(false);
        account.updateAccount && account.updateAccount({ loggedIn: false });
      });
  };

  const basicLogin = () => {
    setBasicLogin(true);
    fetch(urlLogin, {
      method: 'post',
      headers: { Authorization: `basic ${btoa(`${username}:${password}`)}`},
      body: '{}'
    }).then(res => res.json())
      .then(res => {
        localStorage.setItem("token", res.token);
        let name = res.name.split(' ').map((item) => item.charAt(0)).slice(0,2).join('');
        localStorage.setItem("avatar", name);
        localStorage.setItem("email", "Web");
        localStorage.setItem("name", res.name);
        if (res.timeZoneCode)
          localStorage.setItem("timeZoneCode", res.timeZoneCode);
        else
          localStorage.removeItem("timeZoneCode");
        account.updateAccount && account.updateAccount({
          ...res,
          loggedIn: true
        });

        // @ts-ignore
        var OneSignal = window.OneSignal || [];
        OneSignal.push(function() {
          OneSignal.provideUserConsent(true);
        });
        OneSignal.push(function() {
          OneSignal.setExternalUserId(getAccountId());
        });

        loadCorporateVariables();
        // @ts-ignore
        getSession(res.token);
      })
      .catch(err => {
        setBasicLogin(false);
        //console.log('login error', err);
        account.updateAccount && account.updateAccount({ loggedIn: false });
      });
  };

  const loginError = (err) => {
    setGoogleLogin(false);
    alert(err);
  };

  return (
      <TemplateLogin title={t`Welcome to Sitrack.IO`}>
        <FormGroup>
          <Label for="username"><Trans>Username</Trans></Label>
          <Input id="username"
            type="text"
            name="username"
            ref={usernameInput}
            value={username}
            onChange={e => setUsername(e.target.value)} />
        </FormGroup>
        <FormGroup>
          <Label for="password"><Trans>Password</Trans></Label>
          <Input id="password"
            type="password"
            name="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                basicLogin();
              }
            }}
          />
        </FormGroup>
        <div id="loginactions">
          <FormGroup check>
            <Label check>
              <Input type="checkbox" name="rememberme" className="ml-0 mt-0 mr-1" />{' '}
              <Trans>Remember me</Trans>
            </Label>
          </FormGroup>
          <Link to="/pwdreset">
            <Trans>I forgot my password</Trans>
          </Link>
        </div>
        {isBasicLogin || isGoogleLogin ?
          <div className="d-flex justify-content-center mt-4 mb-4">
            <Spinner className="login-loading" />
          </div> :
          <Button type="submit" className="rounded-pill mt-4 mb-4" onClick={basicLogin}>
            <Trans>Login</Trans>
          </Button>
        }
        <div id="altlogins">
          <div className="title">
            <div className="line" />
            <p><Trans>Login with</Trans></p>
            <div className="line" />
          </div>
          <SocialButton
            img={google}
            provider="google"
            appId={OAUTH_GOOGLE_ID}
            onLoginSuccess={(user) => googleLoginSuccess(user)}
            onLoginFailure={(err) => loginError(err)}
          />
        </div>
        <div id="register">
          <a href="/login"><Trans>You don't have an account? Create you account now</Trans></a><br />
        </div>
      </TemplateLogin>
  );
};

export default withRouter(Login);

const isAuthenticated = () => {
  let infoToken = getDecodedToken();
  let isValid = false;

  if (infoToken && infoToken.exp) {
    let expireDate = moment.unix(infoToken.exp).utc().format();
    let currentDate = moment().format();
    if (moment(expireDate).isSameOrAfter(currentDate)) isValid = true;
  }

  return isValid;
}

export const LoginRoute = (props) => ( isAuthenticated() ? <Redirect to="/apps"/> : <Route {...props}/> );
