import React, {memo, useCallback, useState} from 'react';
import {Button, FormFeedback, Input, InputGroup, InputGroupAddon} from "reactstrap";
import {Trans} from "@lingui/macro";

const InputCoord = ({centerTo}) => {

  const [latLng,setLatLng] = useState('')
  const [coordValid,setCoordValid] = useState(true)

  const onChangeLngLat = useCallback(event => {
    const regex = new RegExp(/^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/);

    if(event.target.value === '' || regex.test(event.target.value)){
      setCoordValid(true)
    }else{
      setCoordValid(false)
    }
    setLatLng(event.target.value)
  },[])

  const onCenterToCoord = useCallback(event =>{
    if(event.type === 'click' || (event.key && event.key === 'Enter')){
      const regex = new RegExp(/^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/);
      if( latLng === '' || regex.test(latLng)){
        setCoordValid(true)
        centerTo && centerTo(latLng)
      }else{
        setCoordValid(false)
      }
    }

  },[latLng,centerTo])

  return  <InputGroup>
    <Input invalid={!coordValid} type={"text"} placeholder={'lat,lng'} onChange={onChangeLngLat}
           onKeyPress={onCenterToCoord}/>
    <InputGroupAddon addonType="prepend">
      <Button size={'sm'} onClick={onCenterToCoord}>
        <i className="fas fa-location-arrow"/>
      </Button>
    </InputGroupAddon>
    <FormFeedback><Trans>Invalid coordinates</Trans></FormFeedback>
  </InputGroup>
}

export default memo(InputCoord)
