import React from 'react';
import './DetailCard.css';

const DetailCard = ({title=undefined,children,onClose,headerChildren=undefined}) => {

  return (
    <div className="detail-card">
      <div className={`card-header ${headerChildren ? 'flex' : ''}`}>
        {title && <h5>{title}</h5>}
        {headerChildren && headerChildren}
        {onClose && <div className="close-button" onClick={onClose}>
          <i className="fas fa-times" title="Cerrar"/>
        </div>}
      </div>
      <div className="card-body">
        {children}
      </div>
    </div>
  )
}
export default DetailCard;
