import React, {useCallback, useState} from 'react';
import "./UserMenu.css";
import {Collapse} from "reactstrap";
import {withRouter} from "react-router";
import {Link} from "react-router-dom";
import NavigationListDropdown from "./components/dropdownElements/NavigationListDropdown";
import ThemeEditor from "../../styles/ThemeEditor";
import UserPreferencesModal from "./UserPreferencesModal";
import UserAccountModal from "./userAccount/UserAccountModal";
import { Trans } from '@lingui/macro';
import {hasPermission} from "../../account/permissionUtils";
import EnvironmentVariablesModal from "./EnvironmentVariablesModal";

const userInfoStorage = ['token', 'currentSession', 'avatar', 'name', 'email', 'userPreferences', 'corporateVariables'];

const UserMenu = ({ switchTheme, history }) => {
  const [themeEditor, setThemeEditor] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenUserPreferences,setIsOpenUserPreferences] = useState(false);
  const CHANGE_THEME_PERMISSION = "changeThemePermission";
  const openEditor = () => setThemeEditor(true);
  const onThemeEditorDone = () => {
    switchTheme("custom");
    setThemeEditor(false);
  };
  const closeEditor = () => setThemeEditor(false);

  const onDoneUserMenu = () => {
    setIsOpenUserPreferences(false);
  }

  const [isOpenUserAccount, setIsOpenUserAccount] = useState(false);
  const closeUserAccount = useCallback(() => setIsOpenUserAccount(false), []);

  const [isOpenEnvironmentVars, setIsOpenEnvironmentVars] = useState(false);

  let uriStorage = localStorage.getItem("avatar");
  let nameStorage = localStorage.getItem("name");
  const srcAvatar = uriStorage !== undefined ? uriStorage : "";
  const name = nameStorage !== undefined ? nameStorage : "";

  let hash = name.split('').reduce((prevHash, currVal) => (((prevHash << 5) - prevHash) + currVal.charCodeAt(0))|0, 0);
  let rgb = '#' + hash.toString().substring(0,6);

  const onLogout = useCallback(() => {
    userInfoStorage.forEach(item => localStorage.removeItem(item));
    history.push("/login");
  }, [])

  return (
    <div className="user-menu" onMouseLeave={()=>setIsOpen(false)}>
      <div className="user-information">
        <div className="user-avatar">
            {srcAvatar.startsWith("http") ?
              <img alt="user avatar" src={srcAvatar} width={30} height={30} style={{borderRadius: "15px"}}/>
              :
              <span className="fa-stack fa-1x avatar">
                    <i className="fa fa-circle fa-stack-2x" style={{color: rgb}}/>
                    <span className="fa fa-stack-1x">{srcAvatar}</span>
              </span>
            }
        </div>
        <div className="user-name" onClick={()=> setIsOpen(!isOpen)}>
          <span className="item-text">
            {name}
          </span>
          <i className={isOpen ? "fa fa-chevron-up" :"fa fa-chevron-down"}></i>
        </div>
      </div>
      <Collapse isOpen={isOpen}>
        <div className="user-options">
          <ul className="side-list">
            <li><Link to={"#"}><div className="mainitem" onClick={() => setIsOpenUserAccount(true)}><i className="fas fa-user"></i>
                  <span className="item-text"><Trans>My account</Trans></span>
            </div></Link></li>
            <li><Link to={"#"}><div className="mainitem" onClick={()=>{setIsOpenUserPreferences(true)}}><i className="fas fa-tools"></i>
              <span className="item-text"><Trans>Preferences</Trans></span>
            </div></Link></li>
            {hasPermission('com.sitrack.appgateway.resources.EnvironmentVariablesResource@create') &&
              <li><Link to={"#"}><div className="mainitem" onClick={() => setIsOpenEnvironmentVars(true)}><i className="fas fa-cog"></i>
                <span className="item-text"><Trans>Environment Variables</Trans></span>
              </div></Link></li>
            }
            { localStorage.getItem(CHANGE_THEME_PERMISSION) === 'true' && <NavigationListDropdown switchTheme={switchTheme} openEditor={openEditor}/>}
            <li><Link to={{}}><div className="mainitem" onClick={onLogout}><i className="fas fa-power-off"></i>
              <span className="item-text"><Trans>Sign Out</Trans></span>
            </div></Link></li>
          </ul>
        </div>
      </Collapse>
      <ThemeEditor isOpen={themeEditor} done={onThemeEditorDone} cancel={closeEditor}/>
      <UserPreferencesModal isOpen={isOpenUserPreferences} onDone={()=>{onDoneUserMenu()}} />
      <UserAccountModal isOpen={isOpenUserAccount} onDone={closeUserAccount} />
      {isOpenEnvironmentVars && <EnvironmentVariablesModal onDone={() => setIsOpenEnvironmentVars(false)} />}
    </div>
  );
};

export default withRouter(UserMenu);
