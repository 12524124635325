import { call, query } from "./calls";

const LIST = "SELECT TinyUrl.id as idTiny, name, hash, TinyUrl.kind as kindTiny, TinyUrl.status as statusTiny, expiration, "
      +"if(equals(TinyUrl.kind,'QUERY'),'query', concat(method,tinyUrl)) AS value FROM TinyUrl "
      +"JOIN Account ON Account.id = TinyUrl.accountId order by expiration DESC"

export const TinyUrl = {
  list: () => query(LIST),
  create: (body) =>call('/TinyUrl', 'POST', body),
  update: (id, body) =>call( `/TinyUrl/${id}`, 'PUT', body),
  destroy: (id) =>call( `/TinyUrl/${id}`, 'DELETE'),
  get: (id) => query(`SELECT name, hash, url,tinyUrl, accountId, expiration,resourceMethod,resourceName, TinyUrl.kind as kindTiny, method, query FROM TinyUrl JOIN Account ON Account.id = TinyUrl.accountId where TinyUrl.id=${id}`)
}