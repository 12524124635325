import React, {useCallback, useRef} from 'react';
import { DropTarget } from 'react-dnd';
import {CustomInput, FormGroup} from "reactstrap";
import {t} from '@lingui/macro';

const TargetBox = ({ canDrop, isOver, connectDropTarget, onManualSelect,accepts='*',disabled, small, styles, multiple = true }) => {
  const isActive = canDrop && isOver;
  const customInputRef = useRef();

  const handleSubmit = useCallback((event) => {
    event.preventDefault();
    if(event.target.files[0]) {
      onManualSelect(Array.from(event.target.files));
    }
  },[onManualSelect])

  return connectDropTarget(<div className={`drag-file-box ${small ? 'small' : ''}`} style={styles}>
    <div className="fake-background" onClick={()=>{customInputRef.current.click()}}/>
    <i className="fas fa-upload"/>
    <div className="info-text">{isActive ? t`Drop the file to add it` : `Drag the file here`}</div>
    <FormGroup>
      <CustomInput
        type="file"
        accept={accepts}
        id="fileBrowser"
        name="customFile"
        label={ `${t`Select the file to import`}.`}
        onChange={handleSubmit}
        invalid={false}
        innerRef={customInputRef}
        disabled={disabled}
        multiple={multiple}
      />
    </FormGroup>
  </div>);
};


export default DropTarget((props) => props.accepts, {
  drop(props, monitor) {
    if (props.onDrop) {
      props.onDrop(props, monitor);
    }
  },
}, (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
  canDrop: monitor.canDrop(),
}))(TargetBox);
