import React, {memo} from "react";
import {GeoJSON} from "react-leaflet";
import {DomEvent, DomUtil, Icon} from "leaflet";
import {styles} from "../mapUtils"

const GeoJsonMap = ( {data,onItemMenuActionClick, showActions, customMarkerStyles}) =>{

  const layerClickHandler = (event) =>{
    let marker = event.target;
    if(showActions || (marker.feature.properties && marker.feature.properties.popup !== null && marker.feature.properties.popup !== undefined && Object.keys(marker.feature.properties.popup).length > 0)){
      let minWidth = 55,maxWidth = 250;
      let box = DomUtil.create('div','');
      let header = DomUtil.create('div','header leaflet-overlay-header',box)
      if(marker.feature.label){
        let title = DomUtil.create('div','title leaflet-overlay-title',header)
        title.innerHTML = marker.feature.label
      }
      let btns = DomUtil.create('div','btn1',header)
      if (showActions) {
        let btnActions = DomUtil.create('button', 'btn btn-link p-0', btns)
        DomUtil.create('i', 'fa fa-rocket', btnActions)
        DomEvent.on(btnActions, 'click', (e) => {
          e.preventDefault();
          onItemMenuActionClick(e, marker.feature);
        });
      }
      let btnClose = DomUtil.create('button','btn btn-link p-0',btns)
      DomUtil.create('i','fa fa-times',btnClose)

      if(marker.feature.properties.popup !== null && marker.feature.properties.popup !== undefined && Object.keys(marker.feature.properties.popup).length > 0){
        let body = DomUtil.create('div','mt-2 leaflet-overlay-body',box)
        let styles = `margin: 5px; ${customMarkerStyles || ''}`;

        let divData = DomUtil.create('div','',body)
        divData.setAttribute('style', styles);

        Object.keys(marker.feature.properties.popup).forEach(p=>{
          let labelField = DomUtil.create('div','text-capitalize',divData)
          labelField.setAttribute('style',"font-weight: bold; font-size: 85%;")
          labelField.innerHTML = p+':';
          let labelValue = DomUtil.create('div','mb-2',divData)
          labelValue.innerHTML = marker.feature.properties.popup[p];
        });
        minWidth = 250
        maxWidth = 250
      }

      if (marker.hasOwnProperty('_popup')) {
        marker.unbindPopup();
      }

      marker.bindPopup(box,{closeButton: false,minWidth: minWidth,maxWidth: maxWidth});
      marker.openPopup();

      DomEvent.on(btnClose, 'click', (e) => {
        e.preventDefault();
        marker.closePopup();
      });
    }
  }

  const onEachFeature = (feature, lay) => {
    if(feature.geometry.type === 'Point'){
      const {icon,size}=feature.properties;
      if(icon && size)
        lay.setIcon(new Icon({
          iconUrl: icon,
          iconSize: size,
        }));
    }

    if(feature.label) {
      lay.bindTooltip(feature.label.toString(), {
        className: 'toolTipStyles',
        permanent: !feature.layer.labelOnHover,
        direction: 'bottom'
      })
    }
    lay.on('click',layerClickHandler);

  };
  return <GeoJSON
    data={data}
    style={styles}
    onEachFeature={onEachFeature}
  />
};
export default memo(GeoJsonMap,(prev,next)=>{
  return prev.hash === next.hash;
});
