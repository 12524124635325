import { query, call } from "./calls";

export const PackagedApp = {
  getInstalled: (corporateId) => query(`select *,packagedAppVersionNumber  as versionNumber,iconFileUrl from ((select * from (select * from InstalledApp where corporateId=${corporateId}) as data where status != 'UNINSTALLED') as pp left join PackagedApp on PackagedApp.id = pp.packagedAppId) as q1 left join PackagedAppVersion on q1.id = PackagedAppVersion.appId `),
  getAll: (corporateId) => query(`select *,packagedAppVersionNumber  as versionNumber,iconFileUrl from ((PackagedApp) as q1 left join (select * from (select * from InstalledApp where corporateId=${corporateId}) as data where status = 'INSTALLED' or status = 'INSTALLING') as q2 on q1.id = q2.packagedAppId) as q3 left join PackagedAppVersion on q3.id = PackagedAppVersion.appId   where status != 'INSTALLED' and  status != 'INSTALLING' limit 50`),
  searchApps: (textSearch, corporateId) => query(`select *,packagedAppVersionNumber  as versionNumber,iconFileUrl from (PackagedApp join PackagedAppVersion on PackagedApp.id = PackagedAppVersion.appId and PackagedAppVersion.published = true) as q1 left join (select * from (select * from InstalledApp where corporateId=${corporateId}) as data) as q2 on q1.id = q2.packagedAppId where q1.name like '${textSearch}' or q1.description like '${textSearch}' or q1.namespace = '${textSearch}' order by q1.name limit 100`),
  install:(appId) => call(`/AppInstallation/${appId}`,"PUT",{id:appId}),
  delete:(appId) => call(`/AppInstallation/${appId}`,"DELETE"),
  getLastVersion:(appId) => query(`Select versionNumber from PackagedAppVersion where published = true and appId = ${appId}`)
}
