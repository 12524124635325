import React, {memo, useCallback, useEffect, useState} from 'react';
import './ItemDropdown.css';
import {DropdownItem, DropdownMenu, DropdownToggle, Spinner} from 'reactstrap';
import {useHistory} from "react-router";
import {Dropdown} from 'reactstrap';
import { t } from '@lingui/macro';
import { Api } from '../../../../api';

const defaultStyle = { left: -150, width: 200 };
export const actionListItemDefault = [
  { id: "id1", name: "moveTo", title: t`Move to Folder`, link: "#", icon: "far fa-folder-open" },
  { id: "id2", name: "rename", title: t`Rename`, link: "#", icon: "far fa-edit" },
  { id: "id3", name: "addToFavorite", title: t`Add to Favorite`, link: "#", icon: "far fa-star" },
  { id: "id4", name: "addToMenu", title: t`Add to Menu`, link: "#", icon: "fas fa-user-cog" },
  { id: "id6", name: "delete", title: t`Remove`, link: "#", icon: "far fa-trash-alt" }
];

const ItemDropdown = (props) => {
  let history = useHistory();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [itemActionsList, setItemActionList] = useState(actionListItemDefault);
  const [loading, setLoading] = useState(false);
  const { resourceIdLinked, resourceNameLinked } = props.item;

  useEffect(() => {
    if(props.actionList){
      setItemActionList(props.actionList);
    }
  }, [props.actionList]);

  useEffect(() => {
    if(props.onContext){
      if(props.index === props.activateToogle) setDropdownOpen(true);
      else setDropdownOpen(false);
    }
  }, [props.activateToogle, props.index, props.onContext]);

  const contextMenu = useCallback((e) => {
    e.preventDefault();
    props.onContext && props.onContext(props.index);
  }, [props.onContext, props.index]);

  const stopPropagation = useCallback((e) => e.stopPropagation(), [])
  
  const onAction = useCallback((value) => {
    if(props.onAction) {
      props.onAction(props.item.id,value.name,props.item, value);
    }
    else history.push(value.link);
    props.onContext && props.onContext(undefined);
  }, [props.onAction, props.item, props.onContext]);

  const onMouseLeave = useCallback(() => {
    props.onContext ? props.onContext(undefined) : setDropdownOpen(false);
  }, [props.onContext]);
  
  const checkPublicAccess =  useCallback(() => {
    if (resourceNameLinked === 'File') {
      setLoading(true);
      Api.File.get(resourceIdLinked).then(res => {
        if(res && res[0]){
          if (res[0].publicAccess === true) {
            console.log(res[0]);
            setItemActionList([
              { id: "makePrivate", name: "makePrivate", title: t`Make Private`, link: "#", icon: "fas fa-user-lock" },
              { id: "copyUrl", name: "copyUrl", title: t`Copy URL`, link: "#", icon: "far fa-copy", url: res[0].url },
              { id: "queryFromFile", name: "queryFromFile", title: t`Query From File`, link: "#", icon: "fa fa-database" },
              ...itemActionsList]);
          } else {
            setItemActionList([
              { id: "makePublic", name: "makePublic", title: t`Make Public`, link: "#", icon: "fas fa-users" },
              { id: "queryFromFile", name: "queryFromFile", title: t`Query From File`, link: "#", icon: "fa fa-database" },
              ...itemActionsList]);
          }
        }
        setLoading(false);
      })
    }
  },[itemActionsList, resourceIdLinked])

  const toggle = useCallback(() => {
    setDropdownOpen(prevState => !prevState);
    checkPublicAccess();
  }, [checkPublicAccess]);
  
  return (
    <Dropdown className={`${props.parentClass ? props.parentClass : 'item-dropdown-default'} item-dropdown`} isOpen={dropdownOpen} toggle={props.onContext ? ()=>{} : toggle} onContextMenu={contextMenu}>
      <DropdownToggle tag="a" onClick={stopPropagation}>
        {props.children}
      </DropdownToggle>
      <DropdownMenu style={props.customStyle ? props.customStyle : defaultStyle} onMouseLeave={onMouseLeave} onClick={stopPropagation}>
        {loading && <Spinner size="sm"/>}
        {itemActionsList.map((value, index) =>(
          <DropdownItem key={index} onClick={() => onAction(value)} className={"menu"} >
            <i className={value.icon}></i>
            <span className="">{value.title}</span>
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
}
export default memo(ItemDropdown);
