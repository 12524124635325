import marker from '../../img/markers/svg/marker.svg';
import repair from '../../img/markers/svg/marker.svg';

export const MAX_ZOOM = 17;
export const mapCartographies=[
  {label:"Google Maps Road",name:"Google Maps Road",checked:true,value:'roadmap',type:'roadmap'},
  {label:"Open Street Map",name:"Open Street Map",value:'osm',type:'osm'},
  {label:"MapBox",name:"MapBox",value:'mapbox',type:'mapbox'},
  {label:"Google Maps Satellite",name:"Google Maps Satellite",value:'satellite',type:'satellite'},
  {label:"Google Maps Terrain",name:"Google Maps Terrain",value:'terrain',type:'terrain'},
  {label:"Google Maps Hybrid",name:"Google Maps Hybrid",value:'hybrid',type:'hybrid'},
  {label:"Google Maps Traffic",name:"Google Maps Traffic",value:'traffic',type:'traffic'}]

export const DRAW_OPTIONS =Object.freeze({
  DRAW_MARKER:"drawMarker",
  DRAW_CIRCLE:"drawCircle",
  DRAW_CIRCLE_MARKER:"drawCircleMarker",
  DRAW_POLYLINE:"drawPolyline",
  DRAW_RECTANGLE:"drawRectangle",
  DRAW_POLYGON:"drawPolygon"
})

export const getMarkerIcon = (icon) => {

  if(icon){
    if(icon.startsWith("http"))
      return icon
    if(icon === 'repair')
      return repair;
  }
  return marker;
}

const normalizeValue = value => {
  return Math.abs(value) > 180 ? (((Math.trunc(Math.abs(value)) % 180) + (parseFloat((Math.abs(value) % 1).toFixed(10)))) * (value >= 0 ? 1:-1) )  : value
}

const normalizePoint = geometry => {
  return geometry.coordinates.map(c => {
    return normalizeValue(c)
  })
}

const normalizeMultiPointLineString = geometry => {
  geometry.coordinates.forEach(point =>{
    point.forEach((c,index) => {
      point[index] = normalizeValue(c)
    })
  })
  return geometry
}

const normalizeMultiLineStringPolygon = geometry => {
  geometry.coordinates.forEach(polygons => {
    polygons.forEach(points => {
      points.forEach((c,index) => {
        points[index] = normalizeValue(c)
      })
    })
  })
  return geometry
}

const normalizeMultiPolygon = geometry => {
  geometry.coordinates.forEach(polygons => {
    polygons.forEach(polygon => {
      polygon.forEach(points => {
        points.forEach((c,index) => {
          points[index] = normalizeValue(c)
        })
      })
    })
  })
  return geometry
}

const normalizeGeometryCollection = geometry => {
  geometry.forEach(geo => {
    switch (geo.type) {
      case "Point":
        geo.coordinates = normalizePoint(geo.coordinates);break;
      case "MultiPoint":
        geo.coordinates = normalizeMultiPointLineString(geo.coordinates);break;
      case "LineString":
        geo.coordinates = normalizeMultiPointLineString(geo.coordinates);break;
      case "MultiLineString":
        geo.coordinates = normalizeMultiLineStringPolygon(geo.coordinates);break;
      case "Polygon":
        geo.coordinates = normalizeMultiLineStringPolygon(geo.coordinates);break;
      case "MultiPolygon":
        geo.coordinates = normalizeMultiPolygon(geo.coordinates);break;
      default:break;
    }
  })
  return geometry
}

export const normalizeCoordinates = (geometry) => {
  const geometryNormalizers = {
    "Point":normalizePoint,
    "MultiPoint":normalizeMultiPointLineString,
    "LineString":normalizeMultiPointLineString,
    "MultiLineString":normalizeMultiLineStringPolygon,
    "Polygon":normalizeMultiLineStringPolygon,
    "MultiPolygon":normalizeMultiPolygon,
    "GeometryCollection":normalizeGeometryCollection
  }
  return geometryNormalizers[geometry.type] ? geometryNormalizers[geometry.type](geometry) : geometry;
}

