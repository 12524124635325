import {useEffect, useMemo, useState} from "react";
import camelToTitle from "./camelCaseTitle";


export const parseColumns = columnSpec => {
  return columnSpec.split(",").map(c => {
    const col = {
      field: c.indexOf(':') === -1 ? c : c.split(':')[0]
    };
    if (c.indexOf(':') !== -1) {
      const config = c.split(':');
      col.title = config[1] !== '' ? config[1] : camelToTitle(col.field);
      if (config.length > 2) {
        col.width = parseInt(config[2]);
      }
    } else {
      col.title = camelToTitle(col.field);
    }
    return col;
  });
};

export const useTableColumns = (columnSpec) => {
  const [cols, setCols] = useState();
  useEffect(() => {
    setCols(parseColumns(columnSpec));
  }, [columnSpec]);
  return cols;
};

export const useBasicCrudTableActions = (onEdit, onDelete) => {
  const actions = useMemo(() => [
    {
      icon: 'fa-edit',
      label: 'Editar',
      showAsIcon: true,
      width: 40,
      fixedWidth: true,
      callback: onEdit
    },
    {
      icon: 'fa-trash-alt',
      label: 'Eliminar',
      showAsIcon: true,
      width: 40,
      fixedWidth: true,
      callback: onDelete
    }
  ], [onEdit, onDelete]);
  return actions;
};

export const onExportCsv = (columns, data, delimiter = ',') => {

  const header = columns.map(c => (
    typeof c.title == "undefined" || c.title === null ? "" :
      ('"' + String(c.title).split('"').join('""') + '"'))).join(delimiter);
  const contents = [];
  data.forEach(d => {
    const fields = columns.map(c => {
      let value = d[c.field];
      switch (typeof value) {
        case "object":
          value = JSON.stringify(value); break;
        case "undefined":
          value = ""; break;
        default:
      }
      return ('"' + String(value).split('"').join('""') + '"');
    });
    contents.push(fields.join(delimiter));
  });

  let fileContents = header + '\n' + contents.join('\n');
  const blob = new Blob(["\ufeff", fileContents], { type: 'text/csv' });
  if (navigator.msSaveOrOpenBlob) {
    navigator.msSaveOrOpenBlob(blob, 'table.csv');
  } else {
    const element = document.createElement('a');
    element.setAttribute('href', window.URL.createObjectURL(blob));
    element.setAttribute('download', 'table.csv');
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }
}
