import React, { useEffect, useState, useCallback } from "react";
import DataGridComponent from "../components/data-grid/DataGridComponent";
import { Button } from 'reactstrap';
import { onExportCsv } from "../components/tables/table-hooks";

const DataTable = ({model, modelVariable, columns, maxHeight, onClickEvents, onInternalEvent}) => {
  const [columnDefs, setColumnDefs] = useState([]);
  const [data, setData] = useState([]);
  const [height, setHeight] = useState();

  useEffect(() => {
    if (!columns || columns.trim().length === 0) return;

    const cols = columns.trim().split(',').map(col => {
      const parts = col.split(':');
      const label = parts.length > 1 ? parts[1] : parts[0];
      const name = parts[0];
      return { label, name };
    });

    setColumnDefs(cols);
  }, [columns]);

  useEffect(() => {
    if (!modelVariable || modelVariable.trim().length === 0) return;
    let mv = modelVariable.trim();
    if (mv.startsWith('{')) {
      mv = mv.substr(1);
    }
    if (mv.endsWith('}')) {
      mv = mv.substring(0, mv.length - 1);
    }
    const value = mv.split('.').reduce((a, b) => a[b], model);
    if (!value) {
      setData([]);
    } else if (!value.length) {
      setData([value]);
    } else {
      setData(value);
    }
  }, [model, modelVariable]);

  const exportCsv = useCallback(() => {
    let columns = columnDefs.map(c => ({title: c.label, field: c.name}));
    onExportCsv(columns, data);
  }, [data, columnDefs]);

  useEffect(() => {
    const heightNumber = parseInt(maxHeight);
    if (!isNaN(heightNumber)) {
      setHeight(`${heightNumber}px`);
    } else {
      setHeight(maxHeight);
    }
  }, [maxHeight]);

  return (
    <React.Fragment>
      <Button color="link" type="button" onClick={exportCsv}>
        <i className="fas fa-download"></i> CSV
      </Button>

      <div style={({ maxHeight: height || 'auto', overflow: 'auto', marginBottom: "1rem" })}>
        <DataGridComponent 
          columns={columnDefs} 
          values={data}
          onRowClick={(e) => onInternalEvent(onClickEvents, e.row.data)}
          onCellHoverChanged={(e) => onInternalEvent(onClickEvents, e.row.data)}
        />
      </div>
    </React.Fragment>
  );
};

export default DataTable;
