import jwt from "jsonwebtoken";
import moment from "moment-timezone";


export const getAccountId = () => {
  let decoded = getDecodedToken();
  return decoded && decoded.accountId;
};

export const getDecodedToken = () => {
  let decodedToken = undefined;
  const token = localStorage.getItem("token");
  if (token) decodedToken = jwt.decode(token);
  return decodedToken;
}


export const getTimeZoneCode = () => {
  const timeZone = localStorage.getItem("timeZoneCode");
  if (timeZone && timeZone.trim().length > 0)
    return timeZone;
  else {
    return moment.tz.guess();
  }
};

export const getLocale = () => {
  let decoded = getDecodedToken();
  return decoded && decoded.locale;
}

export const getNow = (format) =>{
  return moment().format(format).toString();
}

export const getNowMapping = () =>{
  return getNow("YYYY-MM-DDTHH:mm");
}

export const getNowQuery = () =>{
  return getNow("YYYY-MM-DD HH:mm:ss");
}
