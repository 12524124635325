import React, {useEffect, useState} from "react";
import {
  Button,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner
} from "reactstrap";
import ColorPopup from "../components/color-popup/ColorPopup";
import {hexToRgb, lighterColor} from "./colorConversion";
import "./theme-editor.css";
import {Api} from "../api";
import {getAccountId} from "../account/accountUtils";

const ThemeEditor = ({ isOpen, done, cancel }) => {
  const [customTheme, setCustomTheme] = useState(null);
  const [logoUrl, setLogoUrl] = useState('');
  const [iconLogoUrl, setIconLogoUrl] = useState('');
  const [userPrefs, setUserPrefs] = useState();
  const [loading, setLoading] = useState(false);
  const [showAllowChangeThemeCheck, setShowAllowChangeThemeCheck] = useState(false);
  const [allowChangeTheme, setAllowChangeTheme] = useState(false);

  const labels = {
    "mainColor": "Principal",
    /*"backgroundColor": "Fondo",*/
    "accentColor": "Elementos",
    "menuTextColor": "Texto del menú",
    "sideTextColor": "Texto lateral",
    "color1": "color 1",
    "color2": "color 2",
    "color3": "color 3",
    "color4": "color 4",
    "color5": "color 4",
    "color6": "color 6",
    "color7": "color 7",
    "dashboard": "dashboardBackground",
    "titleDash": "dashboardTitle"
  };

  useEffect(() => {
    if(isOpen) {
      setLoading(true);
      Api.UserPreferences.get().then(prefs => {
        setLoading(false);

        const pref = (prefs && prefs.length > 0 && prefs[0]) || undefined;
        setUserPrefs(pref);
        setAllowChangeTheme(pref.allowChangeThemeInChild ? pref.allowChangeThemeInChild : false);

        let theme = pref && pref.customTheme;
        if (!theme) {
          let themeJson = localStorage.getItem("customTheme");
          theme = JSON.parse(themeJson);
        }

        if (theme) {
          const themeConfig = {
            mainColor: theme.mainColor,
            /*backgroundColor: "#ffffff",*/
            accentColor: theme.accentColor,
            menuTextColor: theme.menuTextColor,
            sideTextColor: theme.sideTextColor,
            color1: theme.color1 || "#84A5FB",
            color2: theme.color2 || "#A29FFB",
            color3: theme.color3 || "#FFD982",
            color4: theme.color4 || "#54d4fc",
            color5: theme.color5 || "#fc8474",
            color6: theme.color6 || "#C14698",
            color7: theme.color7 || "#5DEFD6",
            dashboard: theme.dashboard|| "#E2ECEC",
            titleDash: theme.titleDash || "#000000",
          };
          setCustomTheme(themeConfig);
          setLogoUrl(theme.logoUrl);
          setIconLogoUrl(theme.iconLogoUrl ? theme.iconLogoUrl : '');
        } else {
          setCustomTheme({
            mainColor: "#3D63C1",
            /*backgroundColor: "#ffffff",*/
            accentColor: "#09C8A9",
            menuTextColor: "#FFFFFF",
            sideTextColor: "#363636",
            color1: "#84A5FB",
            color2: "#A29FFB",
            color3: "#FFD982",
            color4: "#fc8474",
            color5: "#54d4fc",
            color6: "#C14698",
            color7: "#5DEFD6",
            dashboard: "#E2ECEC",
            titleDash: "#000000"
          });
        }
      });
      Api.Account.get(getAccountId()).then(res => {
        if (res.kind === "CORPORATE") {
          setShowAllowChangeThemeCheck(true);
        }
      })
    }
  }, [isOpen]);

  const resetTheme = () => {
    setCustomTheme({
      mainColor: "#3D63C1",
      /*backgroundColor: "#ffffff",*/
      accentColor: "#09C8A9",
      menuTextColor: "#FFFFFF",
      sideTextColor: "#363636",
      color1: "#84A5FB",
      color2: "#A29FFB",
      color3: "#FFD982",
      color4: "#fc8474",
      color5: "#54d4fc",
      color6: "#C14698",
      color7: "#5DEFD6",
      dashboard: "#E2ECEC",
      titleDash: "#000000"
    });
  }
  const setCorporateTheme = () => {
    if(userPrefs.corporateTheme) {
      let corporateTheme = [];
      Object.entries(customTheme).forEach(([key,value]) =>{corporateTheme[key] = userPrefs.corporateTheme[key]});
      setCustomTheme(corporateTheme);
      if(userPrefs.corporateTheme.iconLogoUrl) {
        setIconLogoUrl(userPrefs.corporateTheme.iconLogoUrl);
      }
      if(userPrefs.corporateTheme.logoUrl) {
        setLogoUrl(userPrefs.corporateTheme.logoUrl);
      }
    } else {resetTheme()};
  }

  const createTheme = () => {
    if (customTheme) {
      setLoading(true);

      const menuInactive = hexToRgb(customTheme.menuTextColor);
      menuInactive.a = 0.54;

      const theme = {
        ...customTheme,
        lightMainColor: lighterColor(customTheme.mainColor) || customTheme.mainColor,
        lightAccentColor: lighterColor(customTheme.accentColor) || customTheme.accentColor,
        menuTextInactiveColor: `rgba(${menuInactive.r},${menuInactive.g},${menuInactive.b},${menuInactive.a})`,
        logoUrl,
        iconLogoUrl
      };

      const prefs = { ...(userPrefs || {}), customTheme: theme };
      prefs.allowChangeThemeInChild = allowChangeTheme;
      if (prefs.action) {
        delete prefs.action;
      }
      // localStorage.setItem("customTheme", JSON.stringify(theme));
      // done();
      Api.UserPreferences.save(prefs).then(() => {
        setLoading(false);
        done();
      });
    }
  };

  return (
    <Modal isOpen={isOpen} size="md">
      <ModalHeader>
        <div className="title">
          Customización de tema
        </div>
        <div className="close-button" onClick={cancel} >
          <i className="fas fa-times" title="Cerrar"/>
        </div>

      </ModalHeader>
      <div className="modal-divider"></div>
      <ModalBody>
        <Container>
          <FormGroup row>
          {customTheme && Object.keys(customTheme).map((key, index) => (
            <Col xs={6} key={index}>
            <FormGroup row>
              <Col xs={9}>{labels[key]}</Col>
              <Col xs={3}>
                <ColorPopup freeColor={true} color={customTheme[key] || "#000000"}
                            portalElement={document.getElementById('general-modal-portal')}
                            onChangeComplete={color => {
                              const t = {...customTheme};
                              t[key] = color.hex;
                              setCustomTheme(t);
                            }}/>
              </Col>
            </FormGroup>
            </Col>
          ))}
            <Col xs={6}></Col>
            <Col xs={6}>
            <FormGroup row style={{color:"var(--mainColor)",cursor:"pointer"}} >
              <Col xs={8} onClick={()=> {resetTheme()}}> Resetear colores</Col>
            </FormGroup>
            </Col>
            {/*<Col xs={8} onClick={()=> {resetTheme()}}> Resetear colores</Col>*/}
            {
              <Col xs={6}>
                <FormGroup row style={{color:"var(--mainColor)",cursor:"pointer"}}>
                  <Col xs={8} onClick={()=> {setCorporateTheme()}}>Theme Corporativo</Col>
                </FormGroup>
              </Col>
            }
          </FormGroup>
          <FormGroup row>
            <Label xs={12}>URL Logo (210x32)</Label>
            <Col xs={12}>
              <Input value={logoUrl} onChange={e => setLogoUrl(e.target.value)}/>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label xs={12}>URL Logo(icono 42x32)</Label>
            <Col xs={12}>
              <Input value={iconLogoUrl} onChange={e => setIconLogoUrl(e.target.value)}/>
            </Col>
          </FormGroup>
          { showAllowChangeThemeCheck &&
            <FormGroup check>
              <Label check>
                <Input type="checkbox" checked={allowChangeTheme} onChange={(e)=>setAllowChangeTheme(e.target.checked)}/>{' '}
                Permitir el cambio de tema en cuentas hijas
              </Label>
            </FormGroup>
          }
          {customTheme &&
            <Row>
              <Col xs={12} className="miniapp">
                <nav style={{ backgroundColor: `${customTheme.mainColor}` }}>
                  <div className="minibutton" style={{ backgroundColor: `${customTheme.accentColor}` }}/>
                </nav>
                <main style={{ backgroundColor: `${customTheme.backgroundColor}` }}>
                  <div className="minipanel">
                    <div className="minititle" style={{ color: `${customTheme.accentColor}` }}>
                      Titulo
                    </div>
                  </div>
                </main>
              </Col>
            </Row>
          }
        </Container>
      </ModalBody>
      <ModalFooter>
        {loading && <Spinner color="primary" size="sm" style={{ marginRight: 10 }} />}
        <Button color="primary" onClick={createTheme}>Aplicar</Button>
        <Button color="secondary" onClick={cancel}>Cancelar</Button>
      </ModalFooter>
    </Modal>
  );
};

export default ThemeEditor;
