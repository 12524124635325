import React, {useEffect, memo} from "react"
import {LayersControl, useLeaflet} from "react-leaflet";
import {MapLayerType, MapType} from "../../widgets/types";
import nanoid from "nanoid";
import { LatLngBounds } from "leaflet";
import {getIcon} from "./components/markerIcons"
import PouchDBTileLayer from "react-leaflet-pouchdb-tilelayer";
import MapboxLayer from "./components/MapboxLayer";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";
import hash from "object-hash"
import {getGoogleMapsKey, getMapboxAccessToken} from "../../keys";

const {BaseLayer} = LayersControl;
export const BOUNDS_STAGE = Object.freeze({
  INITIAL:'INITIAL',
  MANUAL:'MANUAL',
  EXTERNAL:'EXTERNAL'
})

export const transformGeoJson = async (layer = {}, result) => {
  let arrGeoJson = [];
  const {detailValuesToShow} = layer
  // eslint-disable-next-line default-case
  switch (layer.layerType) {
    case MapLayerType.Marker:

      result && result.forEach(p => p._internal_map_id = nanoid());
      if(result){
        let layerData =  result.filter(d => d[layer.key || "point"] !== undefined);
        for(let ele of layerData){
          const {_internal_map_id} = ele;

          const dataAttributes = Object.keys(ele).filter(key => typeof ele[key] !== 'object' && key !== 'latitude' && key !== 'longitude' && key !== 'icon' && key !== '_internal_map_id');

          const { popupData: popup, rawData } = extractPopupAndRawData(detailValuesToShow, dataAttributes, ele);

          const {icon,size} = await getIcon(ele);
          arrGeoJson.push({
            "type": "FeatureCollection",
            "id": _internal_map_id,
            "features": [
              {
                "type": "Feature",
                "label":ele.label || '',
                "layer":layer,
                properties: {
                  rawData,
                  popup,
                  size:size,
                  icon: icon,
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    ele[(layer.key || "point")].coordinates[0],
                    ele[(layer.key || "point")].coordinates[1]
                  ]
                }
              }
            ]
          })
        }
      }
      return new Promise(resolve => resolve(arrGeoJson)) || result;

    case MapLayerType.Geometry:
      return new Promise(resolve => {
        ((result && result.filter(d => d[layer.key || "geometry"] !== undefined)) || [])
          .forEach(ele => {
            let features = [];
            const {_internal_map_id} = ele;

            const dataAttributes = Object.keys(ele).filter(key => typeof ele[key] !== 'object'
              && key !== (layer.strokeColor || 'strokeColor')
              &&  key !== (layer.fillColor || 'fillColor')
              &&  key !== (layer.strokeWidth || 'strokeWidth')
            );

            const { popupData: popup, rawData } = extractPopupAndRawData(detailValuesToShow, dataAttributes, ele);

            const geometry = ele[layer.key || 'geometry'];

            if (geometry.features) {
              geometry.features.forEach(feature => {
                if (feature.geometry.type === "MultiPoint") {
                  feature.geometry.coordinates.forEach(coordinate => {
                    features.push(
                      {
                        "id": _internal_map_id,
                        "type": "Feature",
                        "title": ele[layer.title || 'title'] || "",
                        "properties": {
                          rawData,
                          popup,
                          ...feature.properties
                        },
                        "geometry": {
                          "type": "Point",
                          "coordinates": [coordinate[0], coordinate[1]]
                        }
                      }
                    )
                  })
                } else {
                  feature.properties = {
                    rawData,
                    popup,
                    "color": ele[(layer.strokeColor || 'strokeColor')] || '#007bff',
                    "opacity": ele[(layer.opacity || 'opacity')] || 0.5,
                    "fillColor": ele[(layer.fillColor || 'fillColor')] || '#17a2b8',
                    "fillOpacity": ele[(layer.fillOpacity || 'fillOpacity')] || 0.25,
                    "strokeWidth": ele[(layer.strokeWidth || 'strokeWidth')] || 1,
                    "weight": ele[(layer.strokeWidth || 'strokeWidth')] || 1,
                    ...feature.properties
                  };
                  features.push({...feature, id: _internal_map_id, "label": ele.label || '', "layer": layer})
                }
              });

              geometry.features = features;
              arrGeoJson.push({...geometry});
            } else {
              const feature = {
                type: 'Feature',
                properties: {
                  rawData,
                  popup,
                  "color": ele[(layer.strokeColor || 'strokeColor')] || '#007bff',
                  "opacity": ele[(layer.opacity || 'opacity')] || 0.5,
                  "fillColor": ele[(layer.fillColor || 'fillColor')] || '#17a2b8',
                  "fillOpacity": ele[(layer.fillOpacity || 'fillOpacity')] || 0.25,
                  "strokeWidth": ele[(layer.strokeWidth || 'strokeWidth')] || 1,
                  "weight": ele[(layer.strokeWidth || 'strokeWidth')] || 1,
                },
                geometry,
                id: _internal_map_id, "label": ele.label || '', "layer": layer
              };
              arrGeoJson.push(feature);
            }
          });
        resolve(arrGeoJson);
      });
  }
}

const extractPopupAndRawData = (popupKeys, rawKeys, element) => {
  let popup = {};

  let details;
  if (popupKeys && popupKeys.length > 0) {
    details = popupKeys.split(',');
  } else {
    details = rawKeys;
  }

  details.forEach(detail => {
    if(detail.indexOf(':') !== -1){
      let key = detail.substr(0, detail.indexOf(':'));
      let title = detail.substr(detail.indexOf(':') + 1);
      popup = {...popup, [title]: element[key]}
    }else {
      popup = {...popup, [detail]: element[detail]}
    }
  });

  const rawData = rawKeys.reduce((d, key) => {
    d[key] = element[key];
    return d;
  }, {});

  return {
    popupData: popup,
    rawData
  };
};

export const calculateCenter = (geometry, maps) => {
  if (geometry.type === 'Polygon') {
    const points = geometry.coordinates[0];
    const bounds = points.reduce((b, coords) => b.extend({
      lng: coords[0],
      lat: coords[1],
    }), new maps.LatLngBounds());
    const center = bounds.getCenter();
    if (center.lat() && center.lng()) {
      return {lat: center.lat(), lng: center.lng()};
    }
  }
};

export const getPointsFromPoint = (geometry) =>{
  return [geometry.coordinates];
};

export const getPointsFromMultiPointLineString = (geometry) =>{
  return  geometry.coordinates;
};

export const getPointsFromMultiLineStringPolygon = (geometry) =>{
  let result = [];
  geometry.coordinates.forEach(points=>{
    points.forEach(point=>{
      result.push(point);
    })
  });
  return result;
};

export const getPointsFromMultiPolygon = (geometry) =>{
  let result = [];
  geometry.coordinates.forEach(coordinate=>{
    coordinate.forEach(points=>{
      points.forEach(point=>{
        result.push(point);
      })
    })
  });
  return result;
};

export const orderLayerByPriority = (l1,l2,order = 'asc') => {
  if(order === 'asc'){
    if(l1.layerPriority && !l2.layerPriority)
      return -1
    if(!l1.layerPriority && l2.layerPriority)
      return 1
    if(!l1.layerPriority && !l2.layerPriority)
      return 0
    return parseInt(l1.layerPriority) - parseInt(l2.layerPriority)
  }else{
    if(!l1.layerPriority && l2.layerPriority)
      return -1
    if(l1.layerPriority && !l2.layerPriority)
      return 1
    if(!l1.layerPriority && !l2.layerPriority)
      return 0
    return parseInt(l2.layerPriority) - parseInt(l1.layerPriority)
  }

}

const getPointsFromGeometryCollection = (geometry) => {
  let points = [];
  geometry.forEach(geo => {
    switch (geo.type) {
      case "Point":
        points = points.concat(getPointsFromPoint(geo));break;
      case "MultiPoint":
        points = points.concat(getPointsFromMultiPointLineString(geo));break;
      case "LineString":
        points = points.concat(getPointsFromMultiPointLineString(geo));break;
      case "MultiLineString":
        points = points.concat(getPointsFromMultiLineStringPolygon(geo));break;
      case "Polygon":
        points = points.concat(getPointsFromMultiLineStringPolygon(geo));break;
      case "MultiPolygon":
        points = points.concat(getPointsFromMultiPolygon(geo));break;
      default:break;
    }
    return points
  })
};

export const calculateBounds = (layersData,layersVisibility,layers) => {

  const bounds = Object.keys(layersData).reduce((b, layerIndex) => {
    const layer = layers ? layers[layerIndex] : null;

    if (layer && layer.updatesBounds && layersVisibility[layerIndex]) {
      const data = layersData[layerIndex];
      let points = [];
      data && data.forEach(ele=>{
        ele.features.forEach(feature=>{
          switch (feature.geometry.type) {
            case "Point":
              points = points.concat(getPointsFromPoint(feature.geometry));break;
            case "MultiPoint":
              points = points.concat(getPointsFromMultiPointLineString(feature.geometry));break;
            case "LineString":
              points = points.concat(getPointsFromMultiPointLineString(feature.geometry));break;
            case "MultiLineString":
              points = points.concat(getPointsFromMultiLineStringPolygon(feature.geometry));break;
            case "Polygon":
              points = points.concat(getPointsFromMultiLineStringPolygon(feature.geometry));break;
            case "MultiPolygon":
              points = points.concat(getPointsFromMultiPolygon(feature.geometry));break;
            case "GeometryCollection":
              points = points.concat(getPointsFromGeometryCollection(feature.geometry));break;
            default:break;
          }
        })
      });

      points.forEach(point => {
        b.extend({lng:point[0],lat:point[1]})
      })
    }
    return b;
  },new LatLngBounds());
  return bounds
}

export const MyLeafletComponent = ({initialHandle}) => {
  //visible, update bound
  const {map} = useLeaflet();

  useEffect(() => {
    initialHandle(map)
  }, [initialHandle]);

  return  <div/>;
};

export const styles = (feature) =>{
  return {
    fillColor: (feature.properties && feature.properties.fillColor) || '#007bff',
    fillOpacity: (feature.properties && feature.properties.fillOpacity) || 0.5,
    color: (feature.properties && feature.properties.color) || '#007bff',
    opacity: (feature.properties && feature.properties.opacity) || 0.5,
    weight: (feature.properties && feature.properties.weight) || 1,
    strokeWidth: (feature.properties && feature.properties.strokeWidth) || 1,
  }
};

const getBaseLayer = (cartografhy = null) =>{
  if(!cartografhy)
    return <PouchDBTileLayer
      useWorker={true}
      profiling
      useCache
      crossOrigin
      cacheNextZoomLevel
      cacheEdgeTile={0}
      attribution='&copy; <a href="http://openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
    />;

  switch(cartografhy.type){
    case MapType.OSM:
      return <PouchDBTileLayer
        useWorker={true}
        profiling
        useCache
        crossOrigin
        cacheNextZoomLevel
        cacheEdgeTile={0}
        attribution='&copy; <a href="http://openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />;
    case MapType.MAPBOX:
      const token = getMapboxAccessToken();
      if(token.length > 0){
        let selectedTheme = localStorage.getItem("selectedTheme");
        return <MapboxLayer
          accessToken={token}
          style={selectedTheme === 'dark'?'mapbox://styles/sitrack/ckjbrrufh7vr619pkp7nnxohu':'mapbox://styles/sitrack/ckjaij3qb3n8l19ph4d12xtg0'}
        />;
      }
      return <div/>
    case MapType.GOOGLE_TRAFFIC:
      return <ReactLeafletGoogleLayer  googleMapsLoaderConf={{KEY: getGoogleMapsKey()}} googleMapsAddLayers={[{name:'TrafficLayer'}]} type={'roadmap'} />
    default:
      return <ReactLeafletGoogleLayer key={nanoid().toString()} googleMapsLoaderConf={{KEY: getGoogleMapsKey()}} type={cartografhy.type} />
  }
}

const CustomLayersControl = ({cartographySelected,setCartographySelected,cartographies,layerControlPosition}) => {
  useEffect(()=>{
    setCartographySelected(cartographySelected)
  },[cartographySelected,setCartographySelected])

  if(!cartographies || cartographies.length === 0){
    return  getBaseLayer();
  }
  if(cartographies.length === 1){
    return  getBaseLayer(cartographies[0]);
  }
  let ctSelected = cartographySelected || cartographies[0]
  return (<LayersControl  position={layerControlPosition}>
    {
      cartographies.map((cartography) => (
        <BaseLayer key={nanoid().toString()}  name={cartography.name} checked={ctSelected.name === cartography.name}>
          {getBaseLayer(cartography)}
        </BaseLayer>
      ))
    }
  </LayersControl> )
}

export const LayersControlWrapped = memo(CustomLayersControl,(prev,next)=>{
  return (prev.cartographySelected && next.cartographySelected && prev.cartographySelected.name === next.cartographySelected.name && hash(prev.cartographies) === hash(next.cartographies) )
})

export const getLayerDataHash = (data=[]) => {
  return hash(data,{ excludeKeys: key => key === 'id'});
};

export const getLayerIndexById = ( layers, layerId ) => {
  return layers.findIndex(l => l.layerId === layerId)
}

export const useScript = (url,cb) => {

  useEffect(() => {
    const script = document.createElement('script');
    script.onload= cb;

    script.setAttribute('src', url);

    script.setAttribute('async' , 'true');
    document.head.appendChild(script);
    return () => {
      document.head.removeChild(script);
    }
  }, [url,cb]);
};

export const useLink = (href,cb) => {

  useEffect(() => {
    const link = document.createElement('link');
    link.onload= cb;

    link.setAttribute("rel","stylesheet");
    link.setAttribute('href', href);

    document.head.appendChild(link);
    return () => {
      document.head.removeChild(link);
    }
  }, [href,cb]);
};
