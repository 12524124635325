import {call, query} from './calls';

export const Thing = {
    search: (q) => query(`SELECT * FROM Thing ${q}`),
};
export const CompleteThing = {
    create: (thing) => call("/CompleteThing", "POST", thing),
    update: (thing) => call(`/CompleteThing/${thing.id}`, "PUT", thing),
    delete: (thingId) => call(`/CompleteThing/${thingId}`, "DELETE"),
};

export const ParametersTemplates = {
    create: (template) => call("/ParametersTemplate", "POST", template),
    search: (q) => query(`SELECT * FROM ParametersTemplate where name like '${q}' ORDER BY name`),
    delete: (id) => call(`/ParametersTemplate/${id}`, 'DELETE'),
    update: (id,template) => call(`/ParametersTemplate/${id}`, 'PUT', template),
    searchId: (id) => query(`SELECT * FROM ParametersTemplate where id=${id}`),
    searchByPresentationLayer: (q) => query(`SELECT name as label, parameters as value FROM ParametersTemplate where presentationLayer like '${q}'`)
};