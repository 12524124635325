// eslint-disable-next-line no-unused-vars
import React, {useCallback, useState} from "react";
import {BASE_URL} from "../../api/config";
import {Api} from "../../api";

const sessionToken = localStorage.getItem("token")
const getUrl = name => `${BASE_URL}/${name}`;

/**
 * Hook para subir un archivo al bucket
 * @param fileCallback func optional: Función que será llamada luego de subir el archivo al bucket temporal
 * y luego de ser guardado en el bucket, recibe tres parámetros:
 * fieldName,event('upload','save','error'),value(name,fileId)
 * @return {isUploading,isSaving,error,success,fileId,uploadFile,saveFile}
 */
const useFileChooser = (fileCallback= null) => {
  /** Indica el estado de la subida del archivo */
  const [isUploading,setIsUploading] = useState(false);
  /** Indica el estado del salvado del archivo */
  const [isSaving,setIsSaving] = useState(false);
  /**
   * Sube un archivo al bucket temporal
   * @param file {file}: archivo a subir
   * @param fieldName string: Nombre del campo con el cual se identifica en el formulario.
   */
  const uploadFile = useCallback((file, fieldName) => {
    setIsUploading(true)
    fetch(getUrl('storage'), {
      method: "POST",
      body: JSON.stringify({
        completeName: file.name,
        size: file.size
      }),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${sessionToken}`,
      }
    }).then(response => {
      if (response.ok)
        return response.json()
      else
        fileCallback && fileCallback(fieldName, 'error', response.error || response.status);
    }).then(response => {
      if (!response) {
        setIsUploading(false);
        return;
      }

      const uploadedFileId = response.fileId; // id to persist later

      const formData = new FormData()
      formData.append("file", file, file.name);
      fetch(response.url, {
        method: 'POST',
        body: formData,
      }).then(response => {
        if (response.status === 204) {
          fileCallback && fileCallback(fieldName, 'upload', { name: file.name, fileId: uploadedFileId })
        }
        else {
          fileCallback && fileCallback(fieldName, 'error', response.error || response.status)
        }
      }).finally(() => {
        setIsUploading(false)
      })
    })

  }, [fileCallback]);

  /**
   * Mueve un archivo del bucket temporal al bucket permanente
   * @param fileName {string}: nombre del archivo a mover
   * @param corporate {boolean}: indica si el archivo pertenece al corporate o si es para uso personal
   * @param fieldName {string}: Nombre del campo con el cual se identifica en el formulario.
   * @param fileId {string}: id obtenido de upload
   * @param folderId {string}: carpeta donde crear el link
   */
  const saveFile = useCallback((fileName, corporate = true, fieldName, fileId, folderId, makePublic = false) => {
    setIsSaving(true)
    fetch(getUrl('edna/File'),{
      method:'POST',
      body:JSON.stringify({
        completeName:fileName,
        corporate,
        id: fileId,
        fileId,
        parent: folderId
      }),
      headers:{
        'Authorization': `Bearer ${sessionToken}`,
        'Content-Type': 'application/json'
      }
    }).then(response =>{
      if(response.ok)
        return response.json()
      else {
        fileCallback && fileCallback(fieldName,'error',response.error || response.status)
      }
    }).then(result => {
      if (result && result.id && makePublic) {
        return Api.storage.makePublic(result.id).then(response => response.ok && response.text()).then(url => {
          if (url) {
            return {
              ...result,
              url: url.replaceAll('"', '')
            };
          }
          return result;
        });
      }
      return result;
    }).then(result =>{
      if(result && result.id){
        fileCallback && fileCallback(fieldName,'save',{ name: fileName, fileId: result.fileId, url: result.url });
      }else {
        fileCallback && fileCallback(fieldName,'error','not  file id')
      }
    }).finally(()=>{
      setIsSaving(false)
    })
  }, [fileCallback]);

  return {isUploading,isSaving,uploadFile,saveFile}
}

export default useFileChooser;
