const colors = [
  ["#ff8373", "#ffa89d", "#ffcdc7", "#ffe6e3"],
  ["#2ab0ff", "#6ac8ff", "#aadfff", "#d4efff"],
  ["#ffda83", "#ffe5a8", "#fff0cd", "#fff8e6"],
  ["#a3a0fb", "#bfbdfc", "#dad9fd", "#edecfe"],
  ["#5defd6", "#8ef4e2", "#bef9ef", "#dffcf7"],
  ["#990066", "#b84d94", "#d699c2", "#ebcce0"],
  ["#7342c1", "#9d7bd4", "#c7b3e6", "#e3d9f3"],
  ["#cc0000", "#db4d4d", "#eb9999", "#f5cccc"],
  ["#ff6633", "#ff9470", "#ffc2ad", "#ffe0d6"],
  ["#66cc99", "#94dbb8", "#c2ebd6", "#e0f5eb"],
  ["#444444", "#888888", "#bbbbbb", "#eeeeee"],
  ["#000000", "#FFFFFF", "transparent"]
];

export default colors;
