import React, {useCallback, useContext, useEffect, useState} from 'react';
import './HelpCenter.css';
import {Button, FormGroup, Input, Label} from "reactstrap";
import useLocalStorage from "../layout/newLayout/useLocalStorage";
import DetailCard from "../layout/newLayout/components/DetailCard";
import {Link} from "react-router-dom";
import logo from "../img/sitrack.svg";
import {Api} from "../api";
import {getAccountId} from "../account/accountUtils";
import {DEV_ACCOUNT_ID} from "../api/config";
import { userPreferencesContext } from '../layout/newLayout/UserPreferencesProvider';
import { Trans } from '@lingui/macro';

const HelpCenter = () => {
  const [minimized,setMinimized] = useState(true);
  const [checked,setChecked] = useState(false);
  const [isDevUser,setIsDevUser] = useLocalStorage('isDevUser',false);
  const preferences = useContext(userPreferencesContext);

  useEffect(() => {
    Api.Account.getParents(getAccountId()).then(res => {
      let dev = false;
      res[0].parents.forEach((parentId) => {
        if (parentId === DEV_ACCOUNT_ID) {
          dev = true;
        } else {
          dev = dev ? true : false;
        }
      });
      setIsDevUser(dev);
    }).catch(e => console.log("Error al validar devUser: ",e));
  },[]);

  useEffect(() => {
    setChecked(preferences && !preferences.preferences.showHelpCenter);
  },[preferences]);

  const header = (<div className="help-center-header">
    <div className="logo"><img alt="logo" src={logo}/></div>
    <h5><Trans>How can we help you?</Trans></h5>
    <div className="search-input-container"><i className="fas fa-search"></i> <input className="search-input"/></div>
  </div>);

  const toggleButton = useCallback(() => {
    setMinimized(!minimized);
    preferences && preferences.savePreferences({showHelpCenter: !checked});
  }, [minimized, preferences, checked]);

  return (
    <div className={`help-center ${minimized ? 'minimized' : 'maximized'} ${preferences && !preferences.preferences.showHelpCenter && 'closed'}`}>
      <Button className="help-center-button" onClick={()=>{toggleButton()}}>
        <Trans>Need help</Trans> <i className="fas fa-question"/>
      </Button>
      <div className="help-center-card">
        <DetailCard headerChildren={header}>
          {isDevUser && <dl>
            <dt><Trans>Developer access</Trans></dt>
            <dd><a href="https://docsio.sitrack.io" rel="noopener noreferrer" target="_blank">- SitrackIO <Trans>Help Center</Trans></a></dd>
            <dd><a href="https://docsio.sitrack.io/#/organizacion-y-cultura-de-trabajo" rel="noopener noreferrer" target="_blank">- <Trans>Work culture</Trans></a></dd>
            <dd><a href="https://docsio.sitrack.io/#/descripcion-de-servicios" rel="noopener noreferrer" target="_blank">- <Trans>Description of services</Trans></a></dd>
          </dl>}
          <dl>
            <dt><Trans>Frequent questions</Trans></dt>
            <dd>- <Trans>How to create my account?</Trans></dd>
            <dd>- <Trans>Customize Interface: Corporate colors and logo</Trans></dd>
            <dd>- <Trans>Keyboard shortcuts</Trans></dd>
            <Link to="#"><Trans>see more</Trans></Link>
          </dl>
          <dl>
            <dt><Trans>Keyboard shortcuts</Trans></dt>
            <Link to="#"><Trans>see more</Trans></Link>
          </dl>
          <dl>
            <dt><Trans>Recommended</Trans></dt>
            <dd>- <Trans>video tutorial: How to create a new dashboard</Trans></dd>
            <dd>- <Trans>video tutorial: Share dashboards and assign permissions</Trans></dd>
            <Link to="#"><Trans>see more</Trans></Link>
          </dl>
          <FormGroup check>
            <Label check>
              <Input type="checkbox" checked={checked} onChange={(e)=>setChecked(e.target.checked)}/>{' '}
              <Trans>Don't show help panel again</Trans>
            </Label>
          </FormGroup>
        </DetailCard>
      </div>
    </div>
  );

}
export default HelpCenter;
