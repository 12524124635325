import React, {useCallback, useEffect, useState} from "react";
import {Button} from "reactstrap";
import { SortIndicator, Column, Table as VirtualTable } from "react-virtualized";
import AutoSizer from "react-virtualized-auto-sizer";
import "react-virtualized/styles.css";
import "./Table.scss";

const getRowClassName = ({ index }) => index >= 0 && index % 2 === 0 ? 'even' : '';
/**
 *
 * @param columns array with objects as { title: 'Title', field: 'field', width: 50, fixedWidth: true }
 * @param data array with data
 * @param actions action with structure { icon: 'fa-edit', label: 'Edit', width: 40, showAsIcon: true, callback: (obj) => {} }
 * @param actionsDisabled whether the row actions should be disabled
 * @returns {JSX.Element}
 * @constructor
 */
const Table = ({ columns, data, actions, actionsDisabled }) => {
  const [columnWidths, setColumnWidths] = useState([]);
  const [tableColumns, setTableColumns] = useState([]);

  const [dataSort, setDataSort] = useState({sortDirection: 'DESC'});

  const sort = useCallback(({sortBy, sortDirection}) => {
    data.reverse();
    setDataSort({sortDirection, sortBy});
  }, [data]);

  useEffect(() => {
    const cols = [...columns, ...((actions || []).map(a => ({ ...a, action: true })))];
    setTableColumns(cols);
  }, [columns, actions]);
  useEffect(() => {
    const widthSum = tableColumns.reduce((sum, col) => sum + (!col.fixedWidth && col.width > 0 ? col.width : 0), 0.0);
    setColumnWidths(tableColumns.map(col => col.fixedWidth ? col.width : (col.width || 0) / (widthSum || 1)));
  }, [tableColumns]);

  const actionCellRenderer = useCallback(({ columnIndex, rowIndex }) => {
    const col = tableColumns[columnIndex];
    return (
      <Button color="link" onClick={() => col.callback(data[rowIndex], rowIndex)} disabled={actionsDisabled}>
        {col.showAsIcon && <i className={`fas ${col.icon || 'fa-edit'}`} title={col.label}/>}
        {!col.showAsIcon && col.label}
      </Button>
    );
  }, [tableColumns, data, actionsDisabled]);

  const headerColumnsRenderer = useCallback(({label}) => {
    return (
        <React.Fragment>
          <span title={label}>{label}</span>
          <SortIndicator sortDirection={dataSort.sortDirection} />
        </React.Fragment>
      );
  },[dataSort.sortDirection]);

  if (columnWidths.length === tableColumns.length && tableColumns.length > 0) {
    return (
      <AutoSizer>
        {({width, height}) => {
          const variableWidth = width - (tableColumns.reduce((sum, col) => sum + (col.fixedWidth ? col.width : 0), 0.0));
          const autoWidthColumns = tableColumns.reduce((count, col) => count + (col.width ? 0 : 1), 0);
          return (
            <div className="table-container table-component pl-0">
              {data && data.length > 0 &&
              <VirtualTable className="stk-table" estimatedRowSize={34} headerHeight={34} height={height}
                            rowGetter={({index}) => (data || [])[index]} rowClassName={getRowClassName}
                            rowHeight={34} rowCount={(data || []).length} width={width} 
                            sort={sort} sortDirection={dataSort.sortDirection} sortBy={dataSort && dataSort.sortBy}>
                {tableColumns.map((col, index) => {
                  const w = (index < columnWidths.length && columnWidths[index] !== 0) ?
                    (tableColumns[index].fixedWidth ? columnWidths[index] : variableWidth * columnWidths[index]) : variableWidth / autoWidthColumns;
                  if (!col.action) {
                    return (
                      <Column key={index} dataKey={col.field} label={col.title || ""} width={w} headerRenderer={headerColumnsRenderer} />
                    );
                  } else {
                    return (
                      <Column key={index} dataKey="action" width={w} cellRenderer={actionCellRenderer} disableSort />
                    )
                  }
                })}
              </VirtualTable>
              }
            </div>
          );
        }}
      </AutoSizer>
    );
  }
  return null;
};

export default Table;
