import { call, query } from "./calls";


export const Notification = {
    list: (start, limit) => query(`select id, notificationId, PublicFile.url as imageUrl, status, title, content, link, if(equals(status, 'read'), 'fas fa-envelope-open') as readIcon, if(equals(status, 'unread'), 'fas fa-envelope') as unreadIcon
      from NotificationReadStatus
      left join PublicFile on imageFileId = PublicFile.id
      where deletedDate = null order by creationDate desc start ${start} limit ${limit}`),
    pending: () =>  query(`select readDate from NotificationReadStatus order by readDate limit ,1`),
    getId: (id) => call(`/Notification/${id}`, "GET"),
    updateRead: (id) => call(`/NotificationReadStatus/${id}`, "PUT", {"_command": { command: "mark-as-read", payload: { id }}}),
    updateNotRead: (id) => call(`/NotificationReadStatus/${id}`, "PUT", {"_command": { command: "mark-as-unread", payload: { id }}}),
    delete: (id) => call(`/NotificationReadStatus/${id}`, "PUT", { "_command": { command: "mark-as-deleted", payload: { id }}})
}
