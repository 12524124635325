import {modelValue} from "./modelValue";
import DataTable from "../DataTable";
import arrayUiRenderer from "../../formdesigner/plugins/arrayUiRenderer";
import { link } from './link';

export default {
  // tag names
  a: link,
  // button,
  // custom component names
  'model-value': modelValue,
  'array-ui-renderer': arrayUiRenderer,
  // direct component reference with generic props mapping
  generic: {
    'data-table': DataTable
  }
}
