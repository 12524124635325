import React, { useCallback, useState } from "react";
import { SketchPicker, SwatchesPicker } from "react-color";
import colors from "../../colors";
import Popover from "react-tiny-popover";
import "./ColorPopup.scss";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, TabPane } from "reactstrap";
import { TabPaneCustom } from "../tab-pane/TabPaneCustom";
import { t, Trans } from "@lingui/macro";
import materialColors from "../../materialColors";

const colorTabs = [
  { value: 1, label: t`General` },
  { value: 2, label: t`Classic` },
  { value: 3, label: t`RGB` }
];

const ColorPopup = (props) => {
  const [display, setDisplay] = useState(false);
  const { onChangeComplete, color, styles, children } = props;
  const [selectedColor, setSelectedColor] = useState(color);
  const [lastColors, setLastColors] = useState([]);

  const handleOnChangeComplete = useCallback(() => {
    setDisplay(false);
    if (selectedColor.source === "hsv") {
      setLastColors((current) => [selectedColor.hex, ...current]);
    }
    onChangeComplete(selectedColor);
  }, [selectedColor, onChangeComplete]);

  return (
    <div style={{ display: "flex", height: "100%", alignItems: "center" }}>
      <Popover isOpen={display} containerStyle={{ zIndex: "2000" }}>
        <React.Fragment>
          <div style={{ backgroundColor: color, width: 20, height: 20, border: "1px solid #ccc", borderRadius: "0.25rem", ...styles }}
            onClick={() => setDisplay(!display)} role="button" />
          <Modal size="lg" isOpen={display}>
            <ModalHeader><Trans>Choose a color</Trans></ModalHeader>
            <ModalBody>
              <TabPaneCustom tabOptions={colorTabs}>
                <TabPane tabId={1}>
                  <SwatchesPicker className="custom-swatches-picker"
                    color={selectedColor.hex ? selectedColor.hex : selectedColor}
                    colors={materialColors}
                    onChangeComplete={(c) => setSelectedColor(c)}
                    width={'100%'} />
                </TabPane>
                <TabPane tabId={2}>
                  <SwatchesPicker className="custom-swatches-picker"
                    color={selectedColor.hex ? selectedColor.hex : selectedColor}
                    colors={colors}
                    onChangeComplete={(c) => setSelectedColor(c)}
                    width={'100%'} />
                </TabPane>
                <TabPane tabId={3}>
                  <SketchPicker className="custom-sketch-picker"
                    color={selectedColor.hex ? selectedColor.hex : selectedColor}
                    presetColors={lastColors}
                    disableAlpha={true}
                    onChangeComplete={(c) => setSelectedColor(c)}
                    width={'100%'} />
                </TabPane>
              </TabPaneCustom>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={handleOnChangeComplete}><Trans>Apply</Trans></Button>
              <Button color="secondary" onClick={() => setDisplay(false)}><Trans>Close</Trans></Button>
            </ModalFooter>
          </Modal>
          {children}
        </React.Fragment>
      </Popover>
    </div>
  );
}

export default ColorPopup;