import React, { useMemo, useReducer } from 'react';
import FormMap from '../FormMap';
import { extractPropsFromDataset } from './propsHelpers';

const mapView = (node, index, children, { mapLayers, setFieldValue, model, resolveValue }) => {
  const {attribs} = node;
  let propView = extractPropsFromDataset(attribs);
  propView.streetView = propView.streetView !== undefined;

  // extract its layer array from the object already generated by the reducer
  propView = { ...propView, id: attribs.id, layers: mapLayers[propView.name] };
  const values = useMemo(() => {
    let data = [];

    propView.layers.forEach(layer => {
      let modelVariable = layer.modelVariable;
      if (!modelVariable.startsWith('{')) modelVariable = '{' + modelVariable;
      if (modelVariable.indexOf('}') === -1) modelVariable = modelVariable + '}';
      let value = resolveValue(model, modelVariable);
      data.push(value || []);
    });

    return data;
  }, [model]);
  
  return <FormMap value={values} setFieldValue={setFieldValue} variableName={propView.name} {...propView} />
};

export const mapLayerExtractor = (node, {updateMapLayer, updateFields}) => {
  const {attribs, parent} = node;
  const dataProps = extractPropsFromDataset(attribs);
  dataProps.cluster = dataProps.cluster !== undefined;
  dataProps.readOnly = dataProps.readOnly !== undefined;
  dataProps.submitValue = dataProps.submitValue !== undefined;
  updateMapLayer({...dataProps, parentName: parent.parent.attribs["data-name"]});
  updateFields({name: parent.parent.attribs["data-name"], type: 'editable-map'});
}

/**
 * Groups layers in arrays by parentName
 * @param layers the current layers object with all mapView layers
 * @param layer the new layer to be added to its parent's array
 * @returns the updated object with the new layer added to its parent's array
 */
const mapLayerReducer = (layers, layer) => {
  const { parentName } = layer;
  const mapLayers = layers[parentName] || [];

  return {
    ...layers,
    [layer.parentName]: [ ...mapLayers, layer ]
  };
};

export const useMapLayer = () => {
  const [mapLayer, updateMapLayer] = useReducer(mapLayerReducer, {}, undefined);
  return [mapLayer, updateMapLayer];
};

export const mapLayer = () => {
  return null;
};

export default mapView;
