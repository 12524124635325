import React, { useEffect, useCallback } from "react";
import {Field} from "formik";
import {extractBooleanExpressionProps, getInvalidClassName} from "./inputHelpers";
import generatePropsFromAttributes from "../parser/utils/generatePropsFromAttributes";
import {extractEvents, extractPropsFromDataset, extractValuesEvents} from "./propsHelpers";
import FileInput from "../FileInput";
import FormikDateTime from "../../components/datetime/FormikDateTime";
import Input from "reactstrap/lib/Input";
import { ErrorMessageCustom } from '../ErrorMessageCustom';

export const input = (node, index, children, context) => {
  const { attribs } = node;
  const { errors, touched } = context;
  if (attribs.type !== 'radio')
    delete attribs.value;
  const props = { ...extractBooleanExpressionProps(attribs, context), ...generatePropsFromAttributes(attribs, index) };
  console.log(props);
  return (
    <React.Fragment key={attribs.name}>
      <Field {...props} className={getInvalidClassName(attribs.name, errors, props, touched)} />
      <ErrorMessageCustom name={attribs.name} touched={touched}/>
    </React.Fragment>
  )
};

export const inputCheckbox = (node, index, children, context) => {
  const { attribs } = node;
  const { setFieldValue, values } = context;
  const props = {
    ...extractBooleanExpressionProps(attribs, context),
    ...generatePropsFromAttributes(attribs, index)
  };
  const { name, checked } = props;
  const value = values[name];
  useEffect(() => {
    if (checked !== undefined) {
      setFieldValue(name, true)
    }
  }, [setFieldValue, checked])

  const handleInputCheckbox = useCallback((e) => {
    let check = e.target.checked;
    setFieldValue(name, check);
  }, [setFieldValue, name])

  return <Input {...props} type='checkbox' name={name} checked={value} onChange={handleInputCheckbox} />
}

export const inputFile = (node, index, children, context) => {
  const { attribs } = node;
  const { uploads, setFilesRequired, alertFileRequired } = context;
  const activateAlert = alertFileRequired ? alertFileRequired[attribs.name] : false;
  delete attribs.value;
  const props = extractBooleanExpressionProps(attribs, context);
  const datasetProps = extractPropsFromDataset(attribs);
  return <FileInput {...generatePropsFromAttributes(attribs, index)}
                {...props}
                {...datasetProps}
                uploadFileCallback={uploads.uploadFile}
                setFilesRequired={setFilesRequired}
                alertFileRequired={activateAlert} />;
};

export const textarea = (node, index, children, context) => {
  const { attribs } = node;
  delete attribs.value;
  const { errors, touched } = context;
  const props = { ...extractBooleanExpressionProps(attribs, context), ...generatePropsFromAttributes(attribs, index, errors) };
  return (
    <React.Fragment key={attribs.name}>
      <Field {...props} className={getInvalidClassName(attribs.name, errors, props, touched)} component="textarea" />
      <ErrorMessageCustom name={attribs.name} touched={touched}/>
    </React.Fragment>
  )
};

export const button = (node, index, children, context) => {
  const { attribs} = node;
  const { isSubmitting, uploads, values, onInternalEvent} = context;
  const props = extractBooleanExpressionProps(attribs, context);
  const events = extractEvents(attribs, onInternalEvent, values);

  props.disabled = props.disabled || isSubmitting || uploads.isUploading;
  return <button {...generatePropsFromAttributes(attribs, index)} {...events} {...props}>{children}</button>;
};

export const inputDateTimeLocal = (node, index, children, context) => {
  const { attribs } = node;
  const { errors, touched } = context;
  const props = { ...extractBooleanExpressionProps(attribs, context), ...generatePropsFromAttributes(attribs, index, errors) };
  return (
    <div className="input-dynamic-datetime" key={attribs.name}>
      <Field {...props} isValid={touched && touched[attribs.name] && !errors[attribs.name]} type="datetime" component={FormikDateTime}/>
      <ErrorMessageCustom name={attribs.name} touched={touched} />
    </div>
  )
}
export const inputDate = (node, index, children, context) => {
  const { attribs } = node;
  const { errors, touched } = context;
  const props = { ...extractBooleanExpressionProps(attribs, context), ...generatePropsFromAttributes(attribs, index, errors) };
  return (
    <div className="input-dynamic-datetime" key={attribs.name}>
      <Field {...props} isValid={touched && touched[attribs.name] && !errors[attribs.name]} type="date" component={FormikDateTime}/>
      <ErrorMessageCustom name={attribs.name} touched={touched} />
    </div>
  )
}
export const inputTime = (node, index, children, context) => {
  const { attribs } = node;
  const { errors, touched } = context;
  const props = { ...extractBooleanExpressionProps(attribs, context), ...generatePropsFromAttributes(attribs, index, errors) };
  return (
    <div className="input-dynamic-datetime" key={attribs.name}>
      <Field {...props} isValid={touched && touched[attribs.name] && !errors[attribs.name]} type="time" component={FormikDateTime}/>
      <ErrorMessageCustom name={attribs.name} touched={touched} />
    </div>
  )
}

export const inputBool = (node, index, children, context) => {
  const { attribs } = node;
  const { onInternalEvent } = context;
  if (attribs.type !== 'radio'){
    delete attribs.value;
  }
  const events = extractValuesEvents(attribs, onInternalEvent);
  const props = extractBooleanExpressionProps(attribs, context);
  return <Field {...events} {...generatePropsFromAttributes(attribs, index)} {...props} component={InputBool} />
}

const InputBool = ({ field, form: { handleChange } , ...props }) => {
  const {onInternalEvent, onCheckEvents} = props;
  const onChange = useCallback((e) => {
    handleChange(e);
    if(onCheckEvents) onInternalEvent(onCheckEvents, e.target.checked);
  }, [onInternalEvent, onCheckEvents, handleChange]);

  return <input {...props} {...field} onChange={onChange} />
};