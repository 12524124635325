import React from 'react';
import ReactDOM from 'react-dom';
import './styles/themes.scss'
import './custom.scss';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import Login, { LoginRoute } from "./login/Login";
import AccountProvider from "./account/AccountProvider";
import './layout/newLayout/components/dropdownElements/BaseDropdown.css';
import { RecoveryMail, RestorePassword } from './login/RecoveryAccount';
import RedirectIssue from './admin/issueTracker/issue/RedirectIssue';

ReactDOM.render(
  <AccountProvider>
      <BrowserRouter>
        <Switch>
          <Redirect to="/auth" from="/" exact={true}/>
          <Redirect from="/auth" to={{
            pathname: "/login",
            state: { from: window.location.pathname.substr('/auth'.length) }
          }} />
          <LoginRoute path="/login" component={Login}/>
          <Route 
            path="/issue/:id" 
            component={RedirectIssue} 
            exact={true} 
          />
          <Route path="/pwdreset" component={RecoveryMail} />
          <Route path="/reset/:token" component={RestorePassword} />
          <Route path="/" component={App}/>
        </Switch>
      </BrowserRouter>
  </AccountProvider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
