import React, { useCallback, useMemo, useState } from "react";
import HtmlEditor, { Toolbar, Item } from 'devextreme-react/html-editor';
import 'devextreme/ui/html_editor/converters/markdown';
import 'devextreme/dist/css/dx.light.css';
import { Button } from 'reactstrap';
import { t } from "@lingui/macro";
import { extractPropsFromDataset } from "./propsHelpers";

const headerValues = [false, 1, 2, 3, 4, 5];
const itemsEditor = ["bold", "italic", "separator", "orderedList", "bulletList", "separator", "alignLeft", "alignCenter", "alignRight", "alignJustify", "separator",
  "link", "image", "separator", "codeBlock", "blockquote", "separator", "undo", "redo", "insertTable", "tableProperties", "deleteTable"];

const textEditorLayer = (node, index, children, { setFieldValue, values, model }) => {
  const { attribs } = node;

  const props = extractPropsFromDataset(attribs);
  props.readOnly = props.readOnly !== undefined;

  const { name, placeholder, height = 200, readOnly } = props;
  const [preview, setPreview] = useState(false);

  const onChange = useCallback((text) => {
    setFieldValue(name, text)
  }, [setFieldValue, name]);

  const buttonPreview = useMemo(() => ({
    text: t`Preview`,
    stylingMode: "text",
    onClick: () => setPreview(current => !current)
  }), []);

  return (
    <div key={index}>
      {(!preview && !readOnly) &&
        <HtmlEditor
          placeholder={placeholder}
          height={height}
          valueType='html'
          onValueChange={onChange}
          value={values[name]}
        >
          <Toolbar multiline={true}>
            {itemsEditor.map((itemName, index) => <Item key={`itemsEditor${index}`} name={itemName} />)}
            <Item name='header' acceptedValues={headerValues} />
            <Item
              widget="dxButton"
              options={buttonPreview}

            />
          </Toolbar>
        </HtmlEditor>
      }
      {(preview && !readOnly) &&
        <Button size="sm" onClick={() => setPreview(current => !current)} color="primary" type="button">
          <i className="fa-solid fa-eye-slash mr-0"></i>
        </Button>
      }
      {(preview || readOnly) &&
        <div dangerouslySetInnerHTML={({ __html: values[name] })} />
      }
    </div>
  );
}

export default textEditorLayer;

