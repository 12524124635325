import {query, call} from './calls';

const fields = "id, name, creationDate, updateDate, creationAccount,updateAccount, " +
"if(isNotNull(updateDate), dateFormat(toDate(updateDate), 'dd/MM/yyyy'), '') as updateDate, "+
"if(isNotNull(creationDate), dateFormat(toDate(creationDate), 'dd/MM/yyyy'), '') as creationDate, " +
"(select * from AccountList where id in ($creationAccount, $updateAccount)) as accounts"

export const BranchOffice = {
    list: () => query(`SELECT ${fields} FROM BranchOffice`),
    getById: (id) => call(`/BranchOffice/${id}`),
    search: (q) => query(`SELECT ${fields} FROM BranchOffice WHERE name like '${q}'`),
    create: (values) => call("/BranchOffice", "POST", values),
    update: (id, values) => call(`/BranchOffice/${id}`, "PUT", values),
    remove: (id) => call(`/BranchOffice/${id}`, "DELETE")
};