import FormMap from "../FormMap";
import React from "react";

export const editableMap = (node, index, children, { setFieldValue }) => {
  const { attribs } = node;
  const variableName = attribs['data-model-variable'];
  if (!variableName) {
    return null;
  }
  delete attribs['data-model-variable'];
  return <FormMap setFieldValue={setFieldValue} variableName={variableName} />;
};
