import React from "react";
import StylishAsyncSelect from "../stylish-select/StylishAsyncSelect";

const PortaledAsyncSelect = (props) => {
  const newStyles = {...props.styles, menuPortal: base => ({ ...base, zIndex: 9999 }) }
  return (<StylishAsyncSelect
    {...props}
    menuPortalTarget={document.body}
    styles={{...newStyles}}
  />)
}
export default PortaledAsyncSelect
