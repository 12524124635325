import DataTable from "../DataTable";
import {actionContainer, dataMapping, dataQuery } from "./dataActions";
import {editableMap} from "./editableMap";
import {modelValue} from "./modelValue";
import {form} from "./form";
import {input, textarea, inputFile, button, inputDateTimeLocal, inputDate, inputTime, inputCheckbox} from "./inputs";
import {select} from "./select";
import { link}  from './link';
import ArrayEditorWrapper from "../wrappers/ArrayEditorWrapper";
import SelectionListWrapper from "../wrappers/SelectionListWrapper";
import zoneEditor from "./zoneEditor";
import {arrayUIRendererLayer} from "./arrayUIRendererLayer";
import {pdfViewer} from "./pdfViewer";
import { objectArrayEditorLayer } from "./objectArrayEditorLayer";
import textEditorLayer from './textEditorLayer';
import mapView, {mapLayer} from "./mapView";

/**
 * Layers configuration
 *
 * Each tag from the original document must be converted to React content by a function (layer).
 * Layers work on a single node and each child is passed to its corresponding layer.
 *
 * Layers are looked up by tag name.
 *
 * The simplest case is when the layer name matches the tag name, e.g. form.
 * When there is more than one possible layer to convert a node, the tag name can be mapped to an array of objects,
 * with a function named 'applies' and the layer function itself under 'converter'. The applies function receives a node
 * and is expected to return true if the converter can process the node. The first object to return true is used.
 *
 * When no layer is found with the tag name, but the 'data-custom-component' attribute is present in the node, this value
 * is used to look up a matching layer.
 *
 * When no custom component layer is found, the generic category is used.
 * Under the generic key, is an object that maps the data-custom-component attribute directly to a React component.
 * This component will receive every data- attribute as a prop (with camel case) and a 'model' property additionally
 * with the current model's content.
 * Generic components are the easiest to integrate as you don't need to write a layer to convert it.
 */
export default {
  // tag name with two possible layers
  input: [
    {
      applies: node => node.name === 'input' && node.attribs.type === 'file',
      converter: inputFile
    },
    {
      applies: node => node.name === 'input' && node.attribs.type === 'datetime-local',
      converter: inputDateTimeLocal
    },
    {
      applies: node => node.name === 'input' && node.attribs.type === 'date',
      converter: inputDate
    },
    {
      applies: node => node.name === 'input' && node.attribs.type === 'time',
      converter: inputTime
    },
    {
      applies: node => node.name === 'input' && node.attribs.type === 'checkbox',
      converter: inputCheckbox
    },
    {
      applies: node => node.name === 'input' && node.attribs.type !== 'file',
      converter: input
    }
  ],
  // tag names
  form,
  textarea,
  button,
  select,
  a: link,
  // custom component names
  'pdf-viewer' : pdfViewer,
  'model-value': modelValue,
  'editable-map': editableMap,
  'action-container': actionContainer,
  'data-query': dataQuery,
  'data-mapping': dataMapping,
  'zone-editor': zoneEditor,
  'array-ui-renderer': arrayUIRendererLayer,
  'object-array-editor': objectArrayEditorLayer,
  'text-editor': textEditorLayer,
  'map-view': mapView,
  'map-layer': mapLayer,
  // direct component reference with generic props mapping
  generic: {
    'selection-list': SelectionListWrapper,
    'array-editor': ArrayEditorWrapper,
    'data-table': DataTable
  }
};
