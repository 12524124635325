import { ConditionEvalSafe, replaceTextVariables } from './conditions';
import { Api } from '../api/index';


export const executeActions = ({name, extendedValues, setFieldValue, mappings, queries, onEvent, onKeyUpdated}) => {
  const condition = new ConditionEvalSafe(extendedValues);
  let execute = false;

  //executing mappings
  mappings.forEach(m => {
    if (m.onEvents && m.onEvents.split(",").map(e => e.trim()).includes(name)) {
      if (!m.condition || condition.run(m.condition)) {
        execute = true;
        const result = condition.run(m.expression);
        if (m.updatesFormValues) {
          if (m.individualKeys) {
            if (typeof result === 'object') Object.keys(result).forEach(k => setFieldValue(k, result[k]));
          } else setFieldValue(m.key, result);
        } else {
          if (m.individualKeys) {
            if (typeof result === 'object') Object.keys(result).forEach(k => onKeyUpdated(k, result[k]));
          } else onKeyUpdated(m.key, result);
        }
        if (m.propagate) onEvent && onEvent(extendedValues._eventName, extendedValues._eventData);
      }
    }
  });

  //executing queries
  queries.forEach(q => {
    if (q.onEvents && q.onEvents.split(",").map(e => e.trim()).includes(name)) {
      if (!q.condition || condition.run(q.condition)) {
        execute = true;
        const query = extendedValues ? replaceTextVariables(q.queryText, extendedValues) : q.queryText;
        Api.query(query).then(result => {
          if (q.updatesFormValues) {
            if (setFieldValue)
              setFieldValue(q.name, result);
          } else {
            onKeyUpdated(q.name, result);
          }
        });
      }
    }
  });
  
  return execute;
};
