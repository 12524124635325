import React, {useCallback, useEffect, useState} from "react";
import SelectionList from "../../components/selection-list/SelectionList";

const SelectionListWrapper = ({ values, setFieldValue, model, modelVariable, valueKey, labelKey, name, placeholder, disabled, onSelectEvents, onInternalEvent}) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (!modelVariable || modelVariable.trim().length === 0) return;
    let mv = modelVariable.trim();
    if (mv.startsWith('{')) {
      mv = mv.substr(1);
    }
    if (mv.endsWith('}')) {
      mv = mv.substring(0, mv.length - 1);
    }
    const value = mv.split('.').reduce((a, b) => a[b], model);
    if (!value) {
      setOptions([]);
    } else if (!Array.isArray(value)) {
      setOptions([value]);
    } else {
      setOptions(value);
    }
  }, [model, modelVariable]);

  const onChange = useCallback((value) => {
    setFieldValue(name, value)
    if(onSelectEvents) onInternalEvent(onSelectEvents, value);
  }, [setFieldValue, name, onSelectEvents, onInternalEvent]);

  const value = values[name];
  const valueProp = Array.isArray(value) ? value : (value && [value]) || [];

  return (
    <SelectionList value={valueProp} options={options}
                   placeholder={placeholder}
                   labelKey={labelKey} valueKey={valueKey} disabled={disabled}
                   onChange={onChange} />
  );
};

export default SelectionListWrapper;
