import React, {useCallback, useState} from "react";
import {t} from "@lingui/macro";
import {Api} from "../../api";
import {AlertNotify} from "../alert-notify/AlertNotify";

const FileLink = ({fileId,children,...rest})=>{

  const [fileError, setFileError] = useState(false)

  const onClickHandle = useCallback(evt => {
    evt.preventDefault()
    Api.PublicFile.getPublicFile(fileId).then(res => {
      if(res && res.length > 0){
        let downloadLink = document.createElement('a');
        downloadLink.download = fileId;
        downloadLink.href = res[0].url;
        downloadLink.target = '_blank';
        downloadLink.onclick = function (event) {
          document.body.removeChild(event.target);
        };
        downloadLink.style.visibility = 'hidden';
        document.body.appendChild(downloadLink);
        downloadLink.click();
        setFileError(false)
      }else{
        setFileError(true)
      }
    })
  },[fileId])

  return <>
    {
      fileError && <AlertNotify open={fileError} color='danger' message={t`File Id not found!!!`} />
    }
    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
    <a {...rest} href="#" onClick={onClickHandle}>{children}</a>
  </>
}

export default FileLink
