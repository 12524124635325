import React, {useCallback, useEffect, useState} from "react";
import "./TopBar.css";
import ThemeEditor from "../../styles/ThemeEditor";
import DropdownMobile from "./components/dropdownElements/DropdownMobile";
import OverflowMenuDropdown from "./components/dropdownElements/OverflowMenuDropdown";
import UserAccountModal from "./userAccount/UserAccountModal";
import UserPreferencesModal from "./UserPreferencesModal";
import nanoid from "nanoid";
import {useHistory} from "react-router";

const TopBar = ({navigationList, switchTheme, actionItems = undefined}) => {

  const history = useHistory();
  const [title, setTitle] = useState();
  const [actionItemsList, setActionItemsList] = useState(undefined);
  const [leftDropdownMenu, setLeftDropdownMenu] = useState(true);
  const [themeEditor, setThemeEditor] = useState(false);
  const openEditor = () => setThemeEditor(true);
  const onThemeEditorDone = () => {
    switchTheme("custom");
    setThemeEditor(false);
  };
  const closeEditor = () => setThemeEditor(false);

  const [isOpenUserAccount, setIsOpenUserAccount] = useState(false);
  const openUserAccount = useCallback(() => setIsOpenUserAccount(true), []);
  const closeUserAccount = useCallback(() => setIsOpenUserAccount(false), []);

  const [isOpenUserPreferences,setIsOpenUserPreferences] = useState(false);
  const openUserPreferences = useCallback(() => setIsOpenUserPreferences(true), []);
  const closeUserPreferences = useCallback(() => setIsOpenUserPreferences(false), []);

  let uriStorage = localStorage.getItem("avatar");
  let emailStorage = localStorage.getItem("email");
  let nameStorage = localStorage.getItem("name");
  const srcAvatar = uriStorage !== undefined ? uriStorage : "";
  const email = emailStorage !== undefined ? emailStorage : "";
  const name = nameStorage !== undefined ? nameStorage : "";

  let hash = name.split('').reduce((prevHash, currVal) => (((prevHash << 5) - prevHash) + currVal.charCodeAt(0))|0, 0);
  let rgb = '#' + hash.toString().substring(0,6);

  useEffect(() => {
    setLeftDropdownMenu(false);
    navigationList.forEach((value)=>{
      if (window.location.pathname.startsWith(value.link)) {
        setTitle(value.title.toUpperCase());
        setActionItemsList(value.custom ? actionItems : undefined);
        setLeftDropdownMenu(value.leftDropdown ? true : false);
      }
    });
  },[window.location.pathname]);

  useEffect(() => {
    setActionItemsList(actionItems);
  },[actionItems]);

  useEffect(() => {
    navigationList.forEach((value)=>{
      if (window.location.pathname.startsWith(value.link)) {
        setTitle(value.title.toUpperCase());
        setActionItemsList(value.custom ? actionItems : undefined);
      }
    });
  },[]);

  return (
    <div className="top-bar" style={{}}>
      <div className="container-left">
        <div className="navigation-icon">
          { leftDropdownMenu ?
              <DropdownMobile/>
              : (<i className="fas fa-arrow-left" onClick={()=>{history.goBack()}} />)
          }
        </div>
        <h6 className="title">{title || "SITRACK IO"}</h6>
      </div>
      <div className="container-right">
        { actionItemsList &&
        actionItemsList.filter(e => e.type === 'action-item').map((actionItem)=> {
          if (actionItem.isComponent) {
            return actionItem.component();
          }
          return actionItem.display() && (
            <div key={nanoid(5)}
                 className={`action-items ${actionItem.active ? (actionItem.active() && 'active') : ''}`}
                 onClick={(e)=>{actionItem.action(e,(r)=>{setActionItemsList([...actionItems])});}}>
              <i className={actionItem.iconClass} title={actionItem.title}/>
            </div>);
        })
        }
        { actionItemsList &&
          <div id="overflow-menu" className="overflow-menu">
            <OverflowMenuDropdown userData={{name: name, srcAvatar: srcAvatar, email: email, rgb: rgb}}
                                switchTheme={switchTheme} openEditor={openEditor}
                                onUserAccount={openUserAccount} onUserPreferences={openUserPreferences}
                                actionItems={actionItemsList.filter((f) => (f.type === 'overflowMenu'))}/>
          </div>
        }
        {!actionItemsList &&
          (<>
              <div className="action-items"><i className="far fa-bell"></i></div>
              <div className="action-items"><i className="fa fa-search"></i></div>
              <div id="overflow-menu" className="overflow-menu">
                <OverflowMenuDropdown userData={{name: name, srcAvatar: srcAvatar, email: email, rgb: rgb}}
                                      switchTheme={switchTheme} openEditor={openEditor}
                                      onUserAccount={openUserAccount} onUserPreferences={openUserPreferences}/>
              </div>
          </>)
        }
      </div>
      <ThemeEditor isOpen={themeEditor} done={onThemeEditorDone} cancel={closeEditor} />
      <UserPreferencesModal isOpen={isOpenUserPreferences} onDone={closeUserPreferences} />
      <UserAccountModal isOpen={isOpenUserAccount} onDone={closeUserAccount} />
    </div>
  );
};

export default TopBar;
