import React, { useState } from "react";
import AccountContext, {Account} from "./account.context";

const AccountProvider: React.FunctionComponent = ({ children }) => {
  const updateAccount = (account: Account) => {
    setAccount(prevState => ({ ...prevState, ...account }));
  };

  const initialAccount: Account = {
    loggedIn: false,
    updateAccount
  };

  const [account, setAccount] = useState(initialAccount);

  return (
    <AccountContext.Provider value={account}>
      {children}
    </AccountContext.Provider>
  );
};

export default AccountProvider;
