import React, {useCallback, useState} from "react";
import {Button, Input, InputGroup} from "reactstrap";
import "./ArrayEditorItem.scss";

const ArrayEditorItem = ({ value, index, totalCount, disabled, valueType, onMoveUp, onMoveDown, onDelete, onEdit, editorComponent, rendererComponent }) => {
  const [editingValue, setEditingValue] = useState('');
  const [editing, setEditing] = useState(false);
  const editingValueChange = useCallback(e => {
    const v = e.target.value;
    setEditingValue(valueType === 'number' ? Number(v) : v);
  }, [valueType]);

  const startEdit = useCallback(() => {
    setEditingValue(value);
    setEditing(true);
  }, [value]);
  const cancelEdit = useCallback(() => setEditing(false), []);
  const finishEdit = useCallback(() => {
    onEdit(index, editingValue);
    setEditing(false);
  }, [onEdit, index, editingValue]);

  const moveUp = useCallback(() => onMoveUp(index), [index, onMoveUp]);
  const moveDown = useCallback(() => onMoveDown(index), [index, onMoveDown]);
  const deleteCallback = useCallback(() => onDelete(index), [index, onDelete]);

  const onKeyDown = useCallback(e => {
    if (e.keyCode === 13) {
      finishEdit();
      e.preventDefault();
      return false;
    }
    if (e.keyCode === 27) {
      cancelEdit();
      e.preventDefault();
      return false;
    }
  }, [finishEdit, cancelEdit]);

  const editorComponentProps = {
    value: editingValue,
    onChange: setEditingValue,
    onKeyDown: onKeyDown
  };

  const EditorComponent = editorComponent;
  const RendererComponent = rendererComponent;

  return (
    <div className={`array-editor-item ${editing && !disabled ? 'editing' : ''}`}>
      <div className="array-editor-value">
        {(!editing || disabled) && RendererComponent && <RendererComponent value={value} />}
        {(!editing || disabled) && !RendererComponent && value}
        {editing && !disabled &&
        <InputGroup>
          {EditorComponent && <EditorComponent {...editorComponentProps} />}
          {!EditorComponent && <Input {...editorComponentProps} bsSize="sm" type={valueType || 'text'} onChange={editingValueChange} />}
          <Button color="link" onClick={finishEdit} size="sm"><i className="fas fa-check"/></Button>
          <Button color="link" onClick={cancelEdit} size="sm"><i className="fas fa-times"/></Button>
        </InputGroup>
        }
      </div>
      {!editing && !disabled &&
      <div className="array-editor-item-actions">
        <Button onClick={startEdit} color="link" size="sm"><i className="fas fa-edit"/></Button>
        <Button onClick={moveDown} color="link" size="sm"
                style={{visibility: index < totalCount - 1 ? 'visible' : 'hidden'}}>
          <i className="fas fa-caret-down"/>
        </Button>
        <Button onClick={moveUp} color="link" size="sm" style={{visibility: index > 0 ? 'visible' : 'hidden'}}>
          <i className="fas fa-caret-up"/>
        </Button>
        <Button onClick={deleteCallback} color="link" size="sm"><i className="fas fa-times"/></Button>
      </div>
      }
    </div>
  );
};

export default ArrayEditorItem;
