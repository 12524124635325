import React, {useEffect, useState, useCallback} from 'react';
import { createPortal } from 'react-dom';
import {Alert} from 'reactstrap';
import './alertNotify.css';

const postitions = {
  topLeft: {top: '5px', left: 0},
  topRight: {top: '5px', right: 0},
  topCenter: {top: '5px', left: '50px', right: '50px'},
  bottomLeft: {bottom: '5px', left: 0},
  bottomRight: {bottom: '5px', right: 0},
  bottomCenter: {bottom: '5px', left: '50%', right: '50%'},
}

export const AlertNotify = ({open=false, message, color="info", position="topLeft", duration=2000, onFinished}) => {
  const [isOpen, setIsOpen] = useState(open);
  useEffect(() => setIsOpen(open), [open]);
  
  useEffect(() => {
    if(isOpen){
      setTimeout(() => {
        setIsOpen(false);
        onFinished && onFinished();
      }, duration);
    }
  }, [isOpen, onFinished]);
  
  const onClose = useCallback(() => setIsOpen(), []);

  return createPortal(
    <div className="containerAlert" style={{...postitions[position]}} >
      <Alert color={color} isOpen={isOpen} toggle={onClose}>{message}</Alert>
    </div>, document.getElementById('portals-notify')
  )

}