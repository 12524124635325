
import React, { useCallback } from "react";
import { extractPropsFromDataset } from "./propsHelpers";
import ObjectArrayEditor from '../../components/object-array-editor/ObjectArrayEditor';
import { getFileKeyPrefix } from '../FileUploadContext';

export const objectArrayEditorLayer = (node, index, children, { setFieldValue, fileKeyPrefix, values,artifactReferences }) => {
  const { attribs } = node;
  const props = extractPropsFromDataset(attribs);
  if(artifactReferences && props.formId && artifactReferences[props.formId]) {
    const formId = artifactReferences[props.formId].artifactId
    if(formId)
      props.formId = formId
  }
  const onChange = useCallback(value => setFieldValue(props.name, value), [setFieldValue, props.name]);
  const value = values[props.name];
  const h = parseInt(props.height);
  return (
    <ObjectArrayEditor {...props} value={value}
      onChange={onChange} height={h}
      generateIds={props.generateIds !== undefined}
      fileKeyPrefix={getFileKeyPrefix(fileKeyPrefix, props.name)}
    />
  );
}
