import React from "react";
import Select from "react-select";

const StylishSelect = (props) => {
    const newStyles = {
        ...props.styles,
        menu: provided => ({ ...provided, marginTop: "2px", zIndex: 2 }),
        control: provided => ({ ...provided, boxShadow: "none" })
    };

    return (
        <Select
            {...props}
            styles={{ ...newStyles }}
            theme={(theme) => ({
                ...theme,
                colors: {
                    primary: "var(--lightAccentColor)",
                    primary25: "var(--selectHighlightColor)",
                    neutral0: "var(--lightMainColor)",
                    neutral20: "var(--fieldBorder)",
                    neutral30: "var(--fieldBorder)",
                    neutral80: "var(--primaryText)"
                }
            })}
        />
    );
}
export default StylishSelect