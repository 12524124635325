import React, {useEffect, useState, useCallback} from 'react';
import ModalHeader from "reactstrap/lib/ModalHeader";
import {Button, Col, Container, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, Spinner} from "reactstrap";
import {Api} from "../../../api";
import MiniNavigator from "./MiniNavigator";
import ColorPopup from "../../../components/color-popup/ColorPopup";
import {assign} from "min-dash";

const DEFAULT_ICON_COLOR = 'var(--mainNavActiveTextColor)';

const CreateShortcutModal = ({ isOpen, onDone, item, edit = false, onClose}) => {
  const [linkName,setLinkName] = useState('');
  const [description,setDescription] = useState('');
  const [icon,setIcon] = useState('');
  const [iconColor,setIconColor] = useState(DEFAULT_ICON_COLOR);
  const [selectedItem,setSelectedItem] = useState({});
  const [waitingConfirmation, setWaitingConfirmation] = useState(false);
  const [menuFolder, setMenuFolder] = useState(false);

  const defaultIconByResource = {
    'Dashboard':"chart-area",
    'ProcessLayout':"network-wired",
  };

  const getDefaultIconByResource = (type) => {
    return defaultIconByResource[type] ? defaultIconByResource[type].icon : 'link'
  }

  useEffect(() => {
    if (edit) {
      setLinkName(item.name);
      setDescription(item.description);
      setIcon(item.icon);
      setIconColor((item.optionalParams && item.optionalParams.iconColor) ? item.optionalParams.iconColor : DEFAULT_ICON_COLOR);
      setWaitingConfirmation(false);
    } else {
      setLinkName("");setDescription("");setWaitingConfirmation(false);
    }
    getMenuFolder();
    if (item) {
      setSelectedItem(item);
    }
  },[isOpen]);

  const getMenuFolder = () => {
    if (localStorage.getItem("menuFolder")) {
      let folder = JSON.parse(localStorage.getItem("menuFolder"));
      setMenuFolder(folder);
    } else {
      Api.Folder.getHome().then(res => {
        if (!selectedItem) { setSelectedItem(res[0])};
        Api.Folder.list(res[0].children.Folder).then(folders => {
          folders.forEach((folder) => {
            if (folder.name === 'Menu') {
              setMenuFolder(folder);
              localStorage.setItem("menuFolder",JSON.stringify(folder));
            }});
        });
      }).catch(e => alert("Error al buscar la carpeta Home: " + e));
    }
  }

  const createShortcut = useCallback(()=> {
    if (edit) {
      let optionalParams = item.optionalParams ? item.optionalParams : {};
      optionalParams.iconColor = iconColor;
      let updateLink = {
        id: item.id,
        name: linkName ? linkName : selectedItem.name,
        description: description,
        icon: icon ? icon : (getDefaultIconByResource[item.resourceNameLinked]),
        optionalParams: optionalParams
      }
      if (item.sharingId) {
        assign({sharingId:item.sharingId}, updateLink);
      }
      Api.Link.update(updateLink).then(res => { setWaitingConfirmation(false);getMenuFolder();onDone(); })
        .catch(e => {alert("No se pudo actualizar: ", e);setWaitingConfirmation(false);});
    } else {
      if (selectedItem.id) {
        setWaitingConfirmation(true);
        let newLink = {
          parent: menuFolder.id,
          name: linkName ? linkName : selectedItem.name,
          description: description,
          resourceNameLinked: selectedItem.resourceNameLinked ? selectedItem.resourceNameLinked : 'Folder',
          resourceIdLinked: selectedItem.resourceIdLinked ? selectedItem.resourceIdLinked : selectedItem.id,
          type: 'shortcut',
          icon: icon ? icon : 'link',
          optionalParams: {iconColor: iconColor}
        }
        if (item && item.sharingId) {
          assign({sharingId:item.sharingId}, newLink);
        }
        Api.Link.create(newLink).then((res)=>{
          if (res) {
            setWaitingConfirmation(false);
            onDone(res);
          }
        });
      } else {
        alert('Debe seleccionar un ítem');
      }
    }
  }, [selectedItem, description, edit, icon, linkName, iconColor, onDone, item ]);

  const onAction = useCallback((id,action,item) => {
    switch(action) {
      case 'selected':{
        setSelectedItem(item);
        setLinkName(item.name);
        break;
      }
      default:
    }
  }, []);

  const cancel = useCallback(()=> {
    setWaitingConfirmation(false);
    onClose();
  }, [onClose]);

  return(
    <Modal isOpen={isOpen} size="md">
      <ModalHeader> {edit ? "Editar Acceso Directo" : "Nuevo Acceso Directo"}
        <div className="close-button" onClick={cancel}>
          <i className="fas fa-times" title="Cerrar"/>
        </div>
      </ModalHeader>
      <div className="modal-divider"></div>
      <ModalBody>
        <Container>
          <FormGroup row>
            {!edit && <Label xs={12}>Crea un nuevo acceso directo</Label>}
            {edit && <Label xs={12}>Editar acceso directo</Label>}
            <Col xs={12}>
              <Input placeholder="Ingrese un nombre" value={linkName} onChange={e => setLinkName(e.target.value)}/>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col xs={12}>
              <Input placeholder="Ingrese un icono" value={icon} onChange={e => setIcon(e.target.value)}/>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col xs={4}>Color del icono</Col>
            <Col xs={4}>
              <ColorPopup freeColor={true} color={iconColor || "#000000"} onChangeComplete={color => {
                setIconColor(color.hex ? color.hex : color);
              }}/>
            </Col>
          </FormGroup>
          {!edit && <FormGroup row>
            <Label xs={12}>Crear acceso directo de: {selectedItem.name ? selectedItem.name : ""} </Label>
            { !item && <MiniNavigator message="" onAction={onAction} isShowLinks={true} />}
          </FormGroup>}
        </Container>
      </ModalBody>
      <ModalFooter>
        {waitingConfirmation && <Spinner color="primary" size="sm" style={{ marginRight: 10 }} />}
        <Button color="primary" disabled={waitingConfirmation} onClick={createShortcut}>{edit ? "Guardar" : "Crear"}</Button>
        <Button color="secondary" onClick={cancel}>Cancelar</Button>
      </ModalFooter>
    </Modal>);
}

export default CreateShortcutModal;
