import React, {useCallback,useEffect} from "react";
import FileChooser from "../components/FileChooser/FileChooser";

const FileInput = ({ name, required, disabled, acceptFileType, maxFileSize, uploadFileCallback, setFilesRequired, alertFileRequired }) => {
  
  const onSelectFiles = useCallback(files => {
    if (files.length > 0) {
      uploadFileCallback(files[0], name);
    }
  }, [uploadFileCallback, name]);

  useEffect(()=>{
    required && setFilesRequired(current => {
      current.push(name);
      return current;
    });
  }, [required, setFilesRequired, name]);
  
  return (
    <FileChooser onChange={onSelectFiles} displayFileList acceptFileType={acceptFileType}
                 maxFileSize={maxFileSize} disabled={disabled} small 
                 alertFileRequired={alertFileRequired} 
    />
  );
};

export default FileInput;
