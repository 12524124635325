import React, {useCallback, useContext ,useEffect, useState} from 'react';
import {Button, Container, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, Spinner} from "reactstrap";
import ModalHeader from "reactstrap/lib/ModalHeader";
import { userPreferencesContext } from './UserPreferencesProvider';
import { t, Trans } from '@lingui/macro';

const UserPreferencesModal = ({ isOpen,onDone }) => {
  const [waitingConfirmation,setWaitingConfirmation] = useState(false);
  const [isCheckHelpCenter,setIsCheckHelpCenter] = useState(true);
  const [isCheckFilterCache, setIsCheckFilterCache] = useState(false);
  const [isCheckMenuStateCache, setIsCheckMenuStateCache] = useState(false);
  const preferences = useContext(userPreferencesContext);

  useEffect(() => {
    setIsCheckHelpCenter(preferences && preferences.preferences.showHelpCenter);
    setIsCheckFilterCache(preferences && preferences.preferences.filterCache);
    setIsCheckMenuStateCache(preferences && preferences.preferences.menuStateCacheEnabled)
  },[preferences]);

  const save = useCallback(() => {
    let userPreferences = { 
      showHelpCenter: isCheckHelpCenter, 
      filterCache: isCheckFilterCache,
      menuStateCacheEnabled: isCheckMenuStateCache
    };
    preferences && preferences.savePreferences(userPreferences);
    onDone();
  }, [isCheckFilterCache, isCheckHelpCenter, isCheckMenuStateCache, preferences]);

  const done = () => {
    onDone();
  }

  return (<Modal isOpen={isOpen} size="md">
    <ModalHeader> <Trans>User Preferences</Trans>
      <div className="close-button" onClick={onDone}>
        <i className="fas fa-times" title={t`Close`}/>
      </div>
    </ModalHeader>
    <div className="modal-divider"></div>
    <ModalBody>
      <Container>
        <FormGroup row>
        </FormGroup>
        <FormGroup check>
          <Label check>
            <Input type="checkbox" checked={isCheckHelpCenter} onChange={(e)=>setIsCheckHelpCenter(e.target.checked)}/>{' '}
            <Trans>Show help panel</Trans>
          </Label>
        </FormGroup>
        <FormGroup check>
          <Label check>
            <Input type="checkbox" checked={isCheckFilterCache} onChange={(e)=>setIsCheckFilterCache(e.target.checked)}/>{' '}
            <Trans>Enable cache in filters</Trans>
          </Label>
        </FormGroup>
        <FormGroup check>
          <Label check>
            <Input type="checkbox" checked={isCheckMenuStateCache} onChange={(e)=>setIsCheckMenuStateCache(e.target.checked)}/>{' '}
            <Trans>Remember sidebar status</Trans>
          </Label>
        </FormGroup>
      </Container>
    </ModalBody>
    <ModalFooter>
      {waitingConfirmation && <Spinner color="primary" size="sm" style={{ marginRight: 10 }} />}
      <Button color="primary" disabled={waitingConfirmation} onClick={save}><Trans>Save</Trans></Button>
      <Button color="secondary" onClick={()=>{setWaitingConfirmation(false);done()}}><Trans>Cancel</Trans></Button>
    </ModalFooter>
  </Modal>);
}
export default UserPreferencesModal;
