import React, { useCallback, useEffect, useMemo, useState} from "react";
import moment from "moment";
import { DateBox } from 'devextreme-react/date-box';
import { externalFormats, internalFormats } from "./dateUtils";
import 'devextreme/dist/css/dx.light.css';
import "./FormikDateTime.css";

const FormikDateTime = ({ field, form, type, format, placeholder, disabled, required, readOnly, isValid = true }) => {
  const [valueDate, setValueDate] = useState();

  useEffect(() => {
    let value;
    if(field && field.value){
      value = moment(field.value);
      if(type === 'time') value = moment(field.value, 'HH:mm');
    }
    setValueDate(value);
  }, [field.value, type]);

  const onFieldChange = useCallback((value) => {
    if(value) value = moment(value).format(internalFormats[type]);
    form.setFieldValue(field.name, value);
  }, [form.setFieldValue, field.name, type]);

  const inputProps = useMemo(() => ({
    id: field.name,
    name: field.name,
    placeholder: placeholder,
    'class': "form-control form-control-sm",
    autocomplete: "off",
    required: required || null
  }), [placeholder, field.name, required]);

  return (
    <DateBox
      onValueChange={onFieldChange}
      value={valueDate}
      readOnly={readOnly}
      showClearButton={true}
      type={type}
      displayFormat={format ? format : externalFormats[type]}
      inputAttr={inputProps}
      disabled={disabled}
      useMaskBehavior={true}
      openOnFieldClick={true}
      isValid={isValid}
      className="custom-date-box"
    />
  );
};

export default FormikDateTime;
